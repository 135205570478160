import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { DirectoryListComponent } from './directory-list/directory-list.component';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';
import { SharedModule } from '../shared/shared.module';
import { UploadModalComponent } from './directory-list/upload-modal.component';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ProviderEditModalComponent } from './provider-edit-modal/provider-edit-modal.component';
import { LocationEditModalComponent } from './location-edit-modal/location-edit-modal.component';
import { ProviderInfoComponent } from './provider-info/provider-info.component';
import { NpiPipe } from '../shared/pipes/npi.pipe';
import { ProviderNamePipe } from '../shared/pipes/providerName.pipe';
import { LocationInfoComponent } from './location-info/location-info.component';
import { LanguagesComponent } from './languages/languages.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DnErrorDisplayModule } from '../dn-error-display/dn-error-display.module';
import {
  ConfirmDirectoryInfoSuccessComponent
} from "./confirm-directory-info-success/confirm-directory-info-success.component";
import { ConfirmResetInfoModalComponent } from "./confirm-reset-info-modal/confirm-reset-info-modal.component";
import { ValidateAffiliationModalComponent } from './validate-affiliation-modal/validate-affiliation-modal.component';
import { ConfirmSubmitWarningModalComponent } from './confirm-submit-warning-modal/confirm-submit-warning-modal.component';
import { AttestAndSubmitModalComponent } from './attest-and-submit-modal/attest-and-submit-modal.component';
import { DisclaimerPageComponent } from './disclaimer-page/disclaimer-page.component';
import { RouterModule } from "@angular/router";
import { RequestDirectoryLinkComponent } from './request-directory-link/request-directory-link.component';
import { LinkExpiryComponent } from './link-expiry/link-expiry.component';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { DirectorySearchComponent } from './directory-search/directory-search.component';
import {JoyrideModule} from "ngx-joyride";
import { LocationManagerModalComponent } from './location-manager-modal/location-manager-modal.component';
import { DirectoryBaseComponent } from './directory-base/directory-base.component';
import { PracticeEmailEditModal } from "./practice-email-edit-modal/practice-email-edit-modal.component";
import { ProviderEmailEditModel } from './provider-email-edit-modal/provider-email-edit-modal.component';

@NgModule({
  declarations: [
    DirectoryListComponent,
    UploadModalComponent,
    ProviderEditModalComponent,
    LocationEditModalComponent,
    ProviderInfoComponent,
    ProviderNamePipe,
    NpiPipe,
    LocationInfoComponent,
    LanguagesComponent,
    AttestAndSubmitModalComponent,
    ConfirmDirectoryInfoSuccessComponent,
    ConfirmResetInfoModalComponent,
    ValidateAffiliationModalComponent,
    ConfirmSubmitWarningModalComponent,
    DisclaimerPageComponent,
    RequestDirectoryLinkComponent,
    LinkExpiryComponent,
    AlertModalComponent,
    DirectorySearchComponent,
    LocationManagerModalComponent,
    DirectoryBaseComponent,
    PracticeEmailEditModal,
    ProviderEmailEditModel
  ],
  exports: [
    DirectoryListComponent,
    RequestDirectoryLinkComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    HttpClientModule,
    TableModule,
    DropdownModule,
    ButtonModule,
    InputTextModule,
    FormsModule,
    TooltipModule,
    CheckboxModule,
    ToggleButtonModule,
    MultiSelectModule,
    BrowserAnimationsModule,
    DnErrorDisplayModule,
    RouterModule,
    JoyrideModule
  ]
})
export class DirectoryModule { }
