import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ElementRef, Renderer2, ViewChild} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import each from "lodash-es/each";
 
import { Affiliation, EMAIL_ROLES, Location } from '../directory.interface';
import { DirectoryService } from "../services/directory.service";

@Component({
    selector: 'location-info',
    templateUrl: './location-info.component.html' ,
    styleUrls:[  ]  
    
})

export class LocationInfoComponent implements OnInit, AfterViewInit{

    @Input() locationInfo !: any;
    @Input() affiliation !: Affiliation;
    @Input() isAlsoPracticeManager = false;
    @Output() addOfficeManagerRequest: EventEmitter<Affiliation> = new EventEmitter<Affiliation>();
    @Output() onShowOfficeManagerIcon: EventEmitter<{}> = new EventEmitter<{}>();
    @ViewChild('.manager-alert') anchorElem !: ElementRef;

    isProviderOnly : boolean = false;
    alertProviderText !: string;
    initializingText: boolean = false;
    managerName: string | undefined  = undefined;
    allowOfficeManagerAdd = false;

    constructor(private directoryService: DirectoryService,
                private translate: TranslateService,
                private elRef:ElementRef,
                private renderer: Renderer2) {}
    asIsOrder(a: any, b: any) {
      return 1;
    }
   
  
    ngOnInit() {            
        this.isProviderOnly = this.affiliation.emailRoles ? this.affiliation.emailRoles.length == 1 && this.affiliation.emailRoles.includes(EMAIL_ROLES.provider) : false;
        if (this.isProviderOnly) {
            this.setProviderAlertText();
        }
    } 

    ngAfterViewInit(): void {         
    } 

    get showWorkHoursText(): boolean {
      if (this.locationInfo.officeHours) {
        return ! (Array.isArray(this.locationInfo.officeHours));
      }
      return false;
    }
    getLanguages() {    
      let officeLanguages : string[] = this.locationInfo.officeLanguages ? this.locationInfo.officeLanguages.join(", ") : "";
      let otherLang = this.locationInfo.otherLanguages ? this.locationInfo.otherLanguages : "";
      return officeLanguages +  ( officeLanguages && otherLang ? ", " : "") + otherLang;
    }

    openLocationManagerModal() {
      this.addOfficeManagerRequest.emit(this.affiliation)
    }

    private setProviderAlertText() {
      this.initializingText = true;
      this.directoryService.getLocationManagerDetails(this.locationInfo.locationId).subscribe(manager => {
        this.managerName = undefined;
        this.initializingText = false; 
        if (manager) {
          this.locationInfo.officeEmail = manager.officeEmail;
          this.locationInfo.officePhone = manager.officePhone;
          this.locationInfo.contactPersonName = manager.lastName;
          this.locationInfo.contactType = manager.contactType;          
          if (this.locationInfo.contactPersonName &&  this.locationInfo.contactType?.toUpperCase() == EMAIL_ROLES.offceManager) {
            this.managerName = manager.lastName;
          }    
        }
        this.allowOfficeManagerAdd = !this.managerName;
        if (this.isAlsoPracticeManager) {
          this.onShowOfficeManagerIcon.emit({locationId: this.locationInfo.locationId,
                                             practiceId: this.locationInfo.practiceId,
                                             providerId: this.affiliation.providerId,
                                             show: this.allowOfficeManagerAdd});
        }
      }) ; 
    }
}
