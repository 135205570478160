import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'alert-modal',
  templateUrl: './alert-modal.component.html',
  styles: [
  ]
})
export class AlertModalComponent implements OnInit {
  isFilteredAlert = false;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  ok()
  {
    this.activeModal.close(false);
  }
}
