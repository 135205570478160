import { Pipe, PipeTransform } from '@angular/core';
import findIndex from 'lodash-es/findIndex';

@Pipe({
  name: 'boardCertification'
})
export class BoardCertificationPipe implements PipeTransform {

  transform(boardName?: string, boardStatus?: string, boardNames?: string[]): string {
    boardName = this.getValidBoardName(boardName, boardNames);
    if (boardStatus && boardStatus.toUpperCase() === 'CERTIFIED' && boardName) {
      return 'Board Name: ' + boardName;
    } else {
      return boardStatus ? 'Board Status: ' + boardStatus : "";
    }
  }

  private getValidBoardName(boardName?: string, boardNames?: string[]) {
    if (boardName) {
      return findIndex(boardNames,(b) => {return b.toLowerCase() === boardName?.toLowerCase()}) > -1 ? boardName : "";
    }
    return boardName;
  }
}
