<div class="modal-header ">
  <div class="align-top">
    <h4 class="modal-title"><i class="fa-solid fa-triangle-exclamation fa-md text-warning"></i>
      {{'headers.validation' | translate}}
    </h4>
  </div>

</div>
<div class="modal-body align-middle">
  <div class="card">
    <div class="card-body">
      <div class="form-group">
        <span *ngIf="!isSelectAll && (providerFields || affiliationFields || !requiredFields.location.includes('OneContactRequired'))">{{'content.validationPopupLabels.defaultContent' | translate}}</span>
        <span *ngIf="isSelectAll">{{'content.validationPopupLabels.selectAllContent' | translate}}</span>
        <span
          *ngIf="!isSelectAll && !providerFields && !affiliationFields && requiredFields.location.includes('OneContactRequired')">
          {{'content.validationPopupLabels.requireOnePieceofContactLocation' | translate}}
        </span>
      </div>
      <br>
      <div *ngIf="! isSelectAll">
        <div class="form-group mb-3" *ngIf="locationFields">
          <span
            *ngIf="!requiredFields.location.includes('OneContactRequired')"><b>{{'content.validationPopupLabels.locationLabel'
              | translate}}</b>{{locationFields}}</span>
          <div *ngIf="!providerFields && !affiliationFields && requiredFields.location.includes('OneContactRequired')">
            <span [innerHTML]="'content.validationPopupLabels.requireOneContactLocationLabel' | translate"></span>
          </div>
        </div>

        <div class="form-group mb-3" *ngIf="providerFields">
          <span><b>{{'content.validationPopupLabels.providerLabel' | translate}}</b></span>
          <span>{{providerFields}}</span>
        </div>     

        <div class="form-group mb-3" *ngIf="affiliationFields">
          <span><b>{{'content.validationPopupLabels.affiliationLabel' | translate}}</b></span>
          <span>{{affiliationFields}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="activeModal.close('Save click')">OK</button>
</div>