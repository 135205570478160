<div *ngIf="globalError">
    <div class='row'>&nbsp;</div>
    <div class='alert alert-danger alert-dismissible'>
      <div class="row">
        <div class="col-sm-12">
            <button type="button" style="padding-top:0px" class="btn-close btn-sm" (click)="dismiss()" data-bs-dismiss="alert" aria-label="Close">             
          </button>
        </div>
      </div>
      <div class='row'>
        <div class='col-md-1'>
          <div style='vertical-align: middle;text-align: right;'>
            <span class='pi pi-exclamation-triangle' style='font-size: 2.5em'>&nbsp;</span>
          </div>
        </div>
        <div class='col-md-11'>
          <p *ngIf='!authorizationError'>An error has occured.  Please refresh the page.  If the error continues, please contact support.</p>
          <p *ngIf='exceptionId'>Please refer to error code <strong>{{exceptionId}}</strong> when reporting this error.</p>
        </div>
      </div>
    </div>
</div>
  
