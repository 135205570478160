import { AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Input } from '@angular/core';
import each from 'lodash-es/each';
import find from 'lodash-es/find';
import { EventsService } from '../services/events.service';

/** Dynamically added elements - e.g. elements controlled by *ngIf - need id attribute. 
 * 
*/
@Directive({
  selector: "[highlighter]"
})
export class HighlighterDirective implements OnInit, OnDestroy {
  @Input() highlighter: string = '';
  private classes = ['text-success', 'fw-bold'];
  private observer !: MutationObserver;
  private initialized: boolean = false;
  private characterDataOldValue: Map<string, any> = new Map<string, any>();
  private _name = "";

  constructor(private element: ElementRef,
    private renderer: Renderer2,
    private eventService: EventsService) { }

  @Input() classList !: string[];
  ngOnInit(): void {
    this._name = this.highlighter;
    if (this.classList && Array.isArray(this.classList)) {
      this.classes = this.classList;
    }
    this.setIdsIfMissing();
    if (!this.observer) {
      this.initializeObserver();
    }
    this.eventService.onRowSizeChanged.subscribe(val => {
      this.ngOnDestroy();
    });
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  private setIdsIfMissing() {
    let parentId = this.hostElement.id || this.randomId;
    if (!this.hostElement.id) {
      this.renderer.setProperty(this.hostElement, "id", parentId);
    }
    this.setIds(this.hostElement.children, parentId);
  }

  private setIds(elements: HTMLCollection | Element[], parentId: string) {
    each(elements, (element) => {
      if (!element.id) {
        this.renderer.setProperty(element, "id", parentId.substr(0, 4) + this.randomId);
      }
    });
  }

  private get randomId() {
    return Math.random().toString(36).replace('0.', this._name ? this._name : '_H');
  }

  private initializeObserver() {
    this.observer = new MutationObserver(mutations => {
      if (this.initialized) {
        this.highlightMutated(mutations);
      } else {
        this.saveOldValue(mutations);
        this.initialized = true;
      }
    });
    this.observer.observe(this.element.nativeElement, {
      subtree: true,
      characterData: true,
      characterDataOldValue: true
    });
  }

  private saveOldValue(mutations: MutationRecord[]) {
    mutations.forEach(mutation => {
      if (mutation.type == 'characterData' &&
        mutation.target.parentElement?.id &&
        mutation.oldValue != "container" &&
        !this.characterDataOldValue.has(mutation.target.parentElement.id)) {
        this.characterDataOldValue.set(mutation.target.parentElement.id, mutation.target.nodeValue);
      }
    });
  }

  private get hostElement(): HTMLElement {
    return this.element.nativeElement;
  }

  private highlightMutated(mutations: MutationRecord[]) {
    mutations.forEach(mutation => {
      if (mutation.type === 'characterData') {
        let mutatedNode = find(this.hostElement.children, (child) => child.id == mutation.target.parentElement?.id);
        if (mutatedNode && mutatedNode.id) {
          let addClass = this.characterDataOldValue.has(mutatedNode.id) ?
            this.characterDataOldValue.get(mutatedNode.id) !== mutation.target.nodeValue :
            mutation.target.nodeValue != undefined;
          this.classes.forEach((classname) => {
            if (addClass) {
              this.renderer.addClass(mutation.target.parentElement, classname);
            } else {
              this.renderer.removeClass(mutation.target.parentElement, classname);
            }
          });
        }
      }
    });
  }


}
