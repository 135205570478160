<div *ngIf="surveyUrl && surveyUrl.length > 0" (onscroll)="onScroll($event)">
    <a href= "{{surveyUrl}}"  type="button" 
     class="surveyMonkeyBox feedbackWrapper btn-primary shadow" target="_blank"
     [ngClass]="showButton ? 'showButton' : 'hideButton'">
        <!-- Customer Feedback -->
    
        <i title="Customer Feedback" class="fa-solid fa-comment-dots fa-xl" >  </i>
        <span style="font-size: 0.9rem; padding-left: 5px;" >Feedback</span> 
    </a>
    
    <div>
        
    </div>
</div>