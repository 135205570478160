import { Directive, ElementRef, forwardRef, Inject, Optional, Renderer2 } from "@angular/core";
import { COMPOSITION_BUFFER_MODE, DefaultValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";


export const TEXT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TextValueAccessor),
  multi: true
};

@Directive({
  selector: 'input[type=textarea][ngModel],input[type=text][ngModel]',
  host: { '(input)': 'onChange($event.target.value)', '(blur)': 'onTouched()' },
  providers: [TEXT_VALUE_ACCESSOR]
})
export class TextValueAccessor extends DefaultValueAccessor {

  constructor(private renderer: Renderer2, private elementRef: ElementRef,
    @Optional() @Inject(COMPOSITION_BUFFER_MODE) private compositionMode: boolean) {

    super(renderer, elementRef, compositionMode);
  }

  override writeValue(value: string): void {
    super.writeValue(this.trimText(value));
  }

  override registerOnChange(fn: (_: string | null) => void): void {
    this.onChange = (value) => {
      fn(this.trimText(value));
    };
  }

  private trimText(value: string) {
    return value ? value.trim() : value;
  }
}