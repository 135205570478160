import { Pipe, PipeTransform } from '@angular/core';
import each from 'lodash-es/each';


@Pipe({
  name: 'phoneFormat'
})
export class PhoneFormatPipe implements PipeTransform {
  private static allButDigits = /\D+/g;  
  public static maxLength = 10;

  transform(value: string): string { 
    value = PhoneFormatPipe.parse(value);
    if (value) {
      if (value.length > PhoneFormatPipe.maxLength) {
        value = value.substring(0,PhoneFormatPipe.maxLength);
      }
      let values = value.match(/\d{1,3}/g);
      if (values) {        
        let viewValues:string[] = [];
        let inserted = 0;
        each(values, (val, index) =>{        
          if (index > 0 && index < 3) {
            this.insertSeperator(viewValues,index + inserted++);
          }
          viewValues.push(val);
        });
        return viewValues.join("");
      }
    } 
    return value;
  }

  private insertSeperator(values: any[] | null, index ?: number) {
    return values && index ? values.splice(index,0,"-") : values;
  }
  static parse(val: string):string {
    if (val) {
      val = val.replace(PhoneFormatPipe.allButDigits, '');
      if (val.length > PhoneFormatPipe.maxLength) {
        val = val.substring(0, PhoneFormatPipe.maxLength);
      }
    }
    return val;
  }

}
