import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import { PersistanceService } from '../../services/persistance.service';
import { ReferenceDataService } from '../../services/reference-data.service';
import { DirectoryService } from '../../../directory/services/directory.service';

@Component({
    selector: 'max-edit-warn',
    templateUrl: './max-edit-warn.component.html',
    styleUrls: ['./max-edit-warn.component.css'],
})

export class MaxEditWarnComponent implements OnInit {

  @Input() directoryStateKey!: string;
  @Output() maxEditReachedEvent = new EventEmitter<boolean>();
  MAXNUMBEROFEDITS = 0;

  public maxEditReached = false;
  constructor(
    private persistanceService: PersistanceService,
    private referenceDataService: ReferenceDataService,
    private directoryService: DirectoryService
  ) {
  }

  ngOnInit() {
    this.referenceDataService.getMaxNumberEdits().subscribe((resp) => {
      if (resp !== undefined) {
        this.MAXNUMBEROFEDITS = resp;
      }
      console.log("max edit number: " + this.MAXNUMBEROFEDITS);
      this.isMaxEditReached();
    });
  }

  isMaxEditReached() {
    let totalEdits = this.directoryService.getTotalNumberOfEdits(this.directoryStateKey);
    if (totalEdits >= this.MAXNUMBEROFEDITS) {
      console.log("Max edit reached!");
      this.maxEditReached = true;
      this.maxEditReachedEvent.emit(true);
    }
  }
}
