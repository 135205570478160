import { AfterViewInit, Directive, HostListener, Input } from '@angular/core';
import { AbstractControl, NgControl, NgModel, ValidationErrors, Validator } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DnValidators } from './dn-validators';

@Directive({
  selector: '[textMatch]' 
})
export class TextMatchDirective implements Validator, AfterViewInit {
  @Input() textMatch !: NgModel;
  @Input() sameText : boolean = true;
  private _subscription !: Subscription | undefined;
  constructor(private ngControl: NgControl) { }

  ngAfterViewInit(): void {    
      
      this.ngControl.control?.setValidators(c =>this.validate(c));
      this._subscription = this.textMatch?.valueChanges?.subscribe((val) => {  
        this.ngControl.control?.updateValueAndValidity();
      }); 
      
  }

  validate(control: AbstractControl): ValidationErrors | null { 
    if (this.sameText) {
     return DnValidators.textMatch(this.textMatch.value)(control); 
    }else{
      return DnValidators.textNotMatch(this.textMatch.value)(control);
    }   
  }
 
  @HostListener('window:beforeunload')  
  ngOnDestroy(): void {
      this._subscription?.unsubscribe();      
  }
}
