import { Component, EventEmitter, Input, OnInit, ViewChild } from "@angular/core";
import { MessageService } from 'primeng/api';
import { FileUploadService } from "./file-upload.service";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Output } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import filter from "lodash-es/filter";
import sortBy from "lodash-es/sortBy";
import findIndex from "lodash-es/findIndex";
import { FileUpload } from "primeng/fileupload";
import each from "lodash-es/each";
import find from "lodash-es/find";
import map from "lodash-es/map";
import { DocumentInfo, FileInfo } from "./file-upload.interface";
import { DnConfirmationComponent } from "../dn-confirmation/dn-confirmation.component";


@Component({
    selector: 'file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.css'],
    providers: [MessageService]

})

export class FileUploadComponent implements OnInit {
    @ViewChild('uploader', { static: false, read: FileUpload }) uploader !: FileUpload;
    @ViewChild('dnConfirm', {static: false, read: DnConfirmationComponent}) dnConfirmation !: DnConfirmationComponent;
    @Input() savedDocuments !: DocumentInfo[];
    @Input() token !: any;
    private uploadedFiles !: Map<string, FileInfo>;
    @Output() onFilesUploaded: BehaviorSubject<Object> = new BehaviorSubject({});
    @Output() onFileDeleted: BehaviorSubject<Object> = new BehaviorSubject({});

    documentMap: Map<string, FileInfo> = new Map<string, FileInfo>();
    numberOfNewFiles: number = 0;

    public documentDescription: string[] = ["Provider State License", "Specialty Certificate", "W-9", "Other"];
    uploading: boolean = false;
    filesToUpload: any[] = [];
    showIframe = false;
    blobUrl !: SafeResourceUrl;
    showErrors: boolean = false;
    systemUnavailable: boolean = false;
    fileLimit: number = 10;
    newFilenames: String[] = [];

    constructor(private uploadService: FileUploadService,
        private sanitizer: DomSanitizer) { }


    ngOnInit() {
        this.uploadedFiles = new Map<string, FileInfo>();
        each(this.savedDocuments, (doc: DocumentInfo) => {
            let fileInfo = {} as FileInfo;
            fileInfo.documentName = doc.name;
            fileInfo.documentGuid = doc.guid;
            fileInfo.documentDescription = doc.description;
            fileInfo.createDate = doc.createDate;
            this.uploadedFiles.set(doc.name, fileInfo);
        })
        if (this.uploadedFiles) {
            this.documentMap = this.uploadedFiles;
            this.sortDocumentList();
        }
    }

    getIndex(file: File) {
        return this.filesToUpload.indexOf(file);
    }

    onFileRemove(event: any) {
        this.reset();
        this.uploader.msgs = [];
    }

    onFileSelect(event: { files: any, currentFiles: FileInfo[] }) {
        event.currentFiles.forEach((file) => {
            file.documentDescription = "Other"
        });

        let duplicates = 0;
        let hasMessages = false;
        this.reset();
        let currentFilenames = map(event.currentFiles, (f) => { return f.name; });
        each(currentFilenames, (filename) => {
            if (filename && this.documentMap.has(filename)) {
                this.removeIfFound(filename, event.currentFiles);
                ++duplicates;
            }
        });
        if (duplicates) {
            this.uploader.msgs.push({ severity: 'warn', summary: "Duplicate.", detail: "One or more files already uploaded, hence ignored." });
        }
        if (event.currentFiles.length > this.fileLimit) {
            let excess = event.currentFiles.length - this.fileLimit;
            event.currentFiles.splice(this.fileLimit - 1, excess);
            this.uploader.msgs.push({
                severity: 'warn',
                summary: `Ignored ${excess} files. Maximum ${this.fileLimit} files can be uploaded at a time.`,
                detail: ""
            });
        }
    }

    isNewFile(key: String) {
        if (this.newFilenames.length > 0) {
            return findIndex(this.newFilenames, (f) => f === key) > -1;
        }
        return false;
    }

    uploadDocuments(event: { files: any }) {
        this.uploading = true;
        this.showIframe = false;
        this.newFilenames = [];
        this.sortDocumentList();
        this.filesToUpload = event.files;
        this.uploader.msgs = [];
        this.uploadService.uploadFiles(this.filesToUpload,this.token).subscribe(results => {
            this.uploading = false;
            let erroredFiles = filter(results, ['isError', true]);
            let newlyUploadedFiles = sortBy(filter(results, ['isError', false]), ['file']);
            if (newlyUploadedFiles.length > 0) {
                this.numberOfNewFiles = newlyUploadedFiles.length;
                let notifyList: DocumentInfo[] = [];
                newlyUploadedFiles.forEach((entry) => {
                    let file = event.files.filter((x: { name: any; }) => {
                        return x.name == entry.file;
                    })[0];
                    file.documentGuid = entry.id;
                    file.documentName = entry.file;
                    this.documentMap.set(entry.file, file);
                    file.createDate = new Date();
                    this.newFilenames.push(entry.file);
                    notifyList.push(this.getDocumentData(entry.file, file));
                    this.removeFromInputList(event, find(event.files, (f) => f.name === entry.file));
                    this.sortDocumentList();
                });
                this.notifyParent('uploaded', notifyList);
            }
            if (erroredFiles.length > 0) {
                this.showErrors = true;
                if (findIndex(erroredFiles, (f) => f.error.startsWith === 'System Unavailable')) {
                    this.systemUnavailable = true;
                };
            }
        });
    }

    private getDocumentData(filename: string, file: FileInfo) {
        let doc = {} as DocumentInfo;
        doc.name = filename;
        doc.description = file.documentDescription;
        doc.guid = file.documentGuid;
        doc.createDate = file.createDate;
        return doc;
    }

    getUploadedFile(e: any, filename: string) {
        e.preventDefault();
        let docgenId = this.documentMap.get(filename)?.documentGuid;
        if (docgenId) {
            this.uploadService.getFileFromDocgen(docgenId,this.token).subscribe(data => {
                if (data) {
                    const url = window.URL.createObjectURL(data);
                    window.open(url, "Document");
                    this.blobUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                    //this.showIframe = true;

                } else {
                    this.showIframe = false;
                    console.log("error occured while retrieving the file")
                }

            });
        }
    }

    removeFromInputList(event: any, file: File) {
        let index = this.uploader.files.indexOf(file);
        if (index > -1) {
            this.uploader.remove(event, index);
            --this.uploader.uploadedFileCount;
        }
    }

    removeIfFound(filename: string, files: File[]) {
        let index = findIndex(files, (f) => f.name === filename);
        if (index > -1) {
            files.splice(index, 1);
        }
    }

    removeUploadedFile(event: any, filename: string) {
        let docId = this.documentMap.get(filename);
        if (docId) {
            this.documentMap.delete(filename);
            this.notifyParent('deleted', filename);
            this.showIframe = false;
        }
    }

    private notifyParent(type: string, data: Object) {
        switch (type) {
            case 'uploaded':
                this.onFilesUploaded.next(data);
                break;
            case 'deleted':
                this.onFileDeleted.next(data);
                break;
        }
    }
    private sortDocumentList() {
        this.documentMap = new Map<string, FileInfo>([...this.documentMap].sort());
    }

    reset() {
        this.showIframe = false;
        this.showErrors = false;
        this.systemUnavailable = false;
        this.uploading = false;        
    }
}