import { AfterViewInit, Directive, OnInit } from '@angular/core';
import { AbstractControl, ValidationErrors, Validator } from '@angular/forms';
import { TaxIdFormatPipe } from '../pipes/taxId-format.pipe';
import DnAbstractTextFormatter from './dn-abstract-text-formatter';
import { DnValidators } from './dn-validators';
import {TaxIdMaskPipe} from "../pipes/taxId-mask.pipe";

@Directive({
  selector: '[taxId]'
})
export class TaxIdDirective extends DnAbstractTextFormatter implements AfterViewInit, Validator {
  protected separators: Map<number, string> = new Map([[3, '-']]);

  private taxIdPipe: TaxIdFormatPipe = new TaxIdFormatPipe();
  private taxIdMaskPipe: TaxIdMaskPipe = new TaxIdMaskPipe();

  validate(control: AbstractControl): ValidationErrors | null {
    return DnValidators.taxIdFormat()(control);
  }

  parse(val: string): string {
    return TaxIdFormatPipe.parse(val);
  }

  transform(val: string): string {
    return this.taxIdPipe.transform(val)
  }


  override initView(val: string) {
    let viewVal = this.taxIdMaskPipe.transform(val);
    this.ngControl.viewToModelUpdate(val);
    this.ngControl.valueAccessor?.writeValue(viewVal);
  }
}
