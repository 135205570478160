export const commonEnvironment = {
  production: false,
  errorMessages: {
    required: 'This is a required field',
    minlength: 'The value entered is too small',
    min: 'Please enter a valid number. The value entered is too small',
    max: 'The value entered is too large',
    maxlength: 'The value entered is too large',
    date: 'Please enter a valid date',
    uniqueUserId: 'This User Id is not available',
    email: 'This email address is invalid',
    emailTld: 'This email address is invalid',
    pattern: 'Please match the requested format. ',
    taxIdFormat: 'Please enter a valid Tax ID (9 digits)',
    npiFormat: 'Please enter a valid NPI (10 digits)',
    phoneFormat: 'Please enter a valid Phone Number (e.g. 123-456-7890)',
    zipcodeFormat: 'Please enter a valid 5-digit or 9-digit ZIP code',
    contactRequired: 'Please enter at least one of the following fields; Phone Number, Fax Number or Office Email Address',
    changeCompletion: 'There was a problem completing your request. Please contact us at 888-262-0931.'
  }
};