import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {
  DirectoryChangeRequest,
  DirectoryData,
  LocationManagerRequest,
  PracticeEmailChangeRequest, ProviderEmailChangeRequest
} from '../directory.interface';

@Injectable({
  providedIn: 'root'
})
export class DirectoryHttpService {

  private baseUrl: string = '/api/user/directory';
  private directoryBaseUrl: string = '/api/directory';
  private testBase: string = '/api/adm/directory';
  private versionBase: string = '/api/version'


  constructor(private http: HttpClient) { }

  public getTotalUnvalidatedAffiliations(token: String): Observable<number> {
    return this.http.get<number>(this.baseUrl + `/${token}/validations/dueCount`);
  }

  public getDirectory(token: String, params: HttpParams): Observable<DirectoryData> {
    return this.http.get<DirectoryData>(this.baseUrl + `/${token}`, { params })
  }

  public sendDirectoryValidations(token: String, changeRequest: DirectoryChangeRequest): Observable<any> {
    let params = {
      token: token,
      changeRequest: changeRequest
    }
    return this.http.post<{}>(this.baseUrl + `/validations/submit`, params);
  }

  public saveLocationManager(token: String, changeRequest: DirectoryChangeRequest): Observable<any> {
    let params = {
      token: token,
      changeRequest: changeRequest
    }
    return this.http.post<{}>(this.baseUrl + `/location/manager`, params);
  }

  public savePracticeEmailChangeRequest(token: String, emailChangeRequest: PracticeEmailChangeRequest): Observable<any> {
    return this.http.put<{}>(this.baseUrl + `/${token}/practice/email`, emailChangeRequest);
  }

  public saveProviderEmailChangeRequest(token: String, emailChangeRequest: ProviderEmailChangeRequest): Observable<any> {
    return this.http.put<{}>(this.baseUrl + `/${token}/provider/email`, emailChangeRequest);
  }

  public getLocationManagerDetails(token: String, locationId: string): Observable<any> {
    let params = {
      token: token,
      locationId: locationId
    }
    return this.http.post<any>(this.baseUrl + `/location/manager/info`, params);
  }

  public getLocationManagers(token: String): Observable<any> {    
    return this.http.get<any>(this.baseUrl + `/${token}/practice/location/managers`);
  }

  public getDirectoryValidationLink(token: String): Observable<any> {
    return this.http.get<any>(this.testBase + `/validation/${token}`);
  }

  public sendDirectoryValidationLink(email: string): Observable<any> {
    return this.http.post<any>(this.directoryBaseUrl + '/validation/link', { token: email });
  }

  public checkDirectoryValidationLinkExpired(token: string, params?: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + `/validation/link`, params);
  }

  public getGitVersion(): Observable<any> {
    return this.http.get<any>(this.versionBase);
  }

  public getDirValidationsDoc(token: string, confirmationNumber: string): Observable<HttpResponse<ArrayBuffer>> {
    let getUrl = this.baseUrl +  `/${token}/validations/${confirmationNumber}` ;
    return this.http.get(getUrl, {observe: 'response', responseType: 'arraybuffer'});
  }

  public recordDirectoryValidationLinkSelectActivity(params: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + `/validation/record-link-selection`,params);
  }

  public getEmailRoles(token: String): Observable<any> {
    let emailRoles =  this.http.get<any>(this.baseUrl + `/${token}` +'/emailRoles' )
    return emailRoles;
  }
}
