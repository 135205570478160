import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DnDisplayErrorsDirective } from './directives/dn-display-errors.directive';
import { DnErrorControlDirective } from './directives/dn-error-control.directive';
import { DnErrorTargetDirective } from './directives/dn-error-target.directive';
import { DnErrorTemplateComponent } from './templates/dn-error-template.component';

@NgModule({
  declarations: [
    DnErrorTemplateComponent,
    DnErrorControlDirective,
    DnDisplayErrorsDirective,
    DnErrorTargetDirective
  ],
  imports: [
    FormsModule,
    CommonModule 
  ],
  exports: [
    DnErrorControlDirective,
    DnDisplayErrorsDirective,
    DnErrorTargetDirective
  ]
})
export class DnErrorDisplayModule { }
