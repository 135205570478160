import { Directive, OnInit } from '@angular/core';
import { ElementRef, Input } from '@angular/core';

@Directive({
  selector: "[autofocus]"
})
export class AutoFocusDirective implements OnInit {
  constructor(private elementRef: ElementRef) { };
  @Input()
  set autofocus(value: any) {
    if (value == undefined || (value && value !== 'false')) {
      this.elementRef.nativeElement.focus();
    }
  }
  ngOnInit(): void {
  }
}
