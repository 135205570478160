
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cityStateZip'
})
export class CityStateZipPipe implements PipeTransform {
  transform(city ?: string, state ?: string, zip ?: string): string | undefined {
    let zipCode = zip && zip.length > 5 ? `${zip.substr(0,5)}-${zip.substr(5)}` : zip;
    let val = state && zipCode ? `${state} ${zipCode}` : (state || zipCode) ;
    return city && val ? `${city}, ${val}` : (city || val);      
  }
}
 