import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'taxIdMask'
})
export class TaxIdMaskPipe implements PipeTransform {

  transform(value?: string): string | undefined {
    return value ? this.splice(2, "-", value) : value;
  }

  private splice(index: number, character: string, input: string | undefined) {
    return input?.slice(0, index) + character + input?.slice(index);
  }
}
