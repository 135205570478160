import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'taxIdFormat'
})
export class TaxIdFormatPipe implements PipeTransform {
  private static regex = /[^0-9]/g;
  public static maxLength = 9;

  transform(value: string): string {
    if (value) {
      value = TaxIdFormatPipe.parse(value);
      if (value) {
        let viewValue = value.substring(0, 2);
        if (value.length > 2) {
          viewValue = value.substring(0, 2) + '-' + value.substring(2, TaxIdFormatPipe.maxLength);
        }
        return viewValue;
      }
    }
    return value;
  }

  static parse(val: string) {
    if (val) {
      val = val.replace(TaxIdFormatPipe.regex, '');
      if (val.length > TaxIdFormatPipe.maxLength) {
        val = val.substring(0, TaxIdFormatPipe.maxLength);
      }
    }
    return val;
  }
}
