import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'workHours'
})
export class WorkHoursPipe implements PipeTransform {
    defaultHrs = "08:00 AM - 05:00 PM";
    defaultDays = ['Mo','Tu','We','Th','Fr'];
    defaultClosedDays = ['Sa','Su'] ;
    defaultClosed = "CLOSED";
    allDays = ['Mo','Tu','We','Th','Fr','Sa','Su'];

  transform(workHours: any): Map<string, string[]> {
    let officeHoursMap : Map<string, string[]> = new Map<string, string[]>();
    if (workHours) {
         let closeDays:string[] = [ ...this.allDays ];
         if(workHours?.length) {
            workHours.forEach((wh: any,_index: any) => {
                let key = this.getOpenHoursAsKey(wh);
                let wDays = officeHoursMap.get(key);
                wDays = wDays ? wDays : [];
                let day = wh.WeekDayName?.substr(0,2);
                wDays.push(day);
                if(key){
                    if (!officeHoursMap.has(key)) {
                      officeHoursMap.set(key,wDays)
                    }
                    closeDays.splice(closeDays.indexOf(day,0), 1);
                }
            });
            if(closeDays?.length > 0) {
              officeHoursMap.set(this.defaultClosed,closeDays);
            }
        }else{
            officeHoursMap.set("Not Listed", []);
           // officeHoursMap.set(this.defaultClosed, []);
        }
    }
    return officeHoursMap;
  }

  private getOpenHoursAsKey(workHour: any): string {
    let key = workHour.Slot1OpenHour?.length ? workHour.Slot1OpenHour + "-" + workHour.Slot1CloseHour : "";
    return workHour.Slot2OpenHour?.length ? key + ", " + workHour.Slot2OpenHour + "-" + workHour.Slot2CloseHour : key;
  }

}
