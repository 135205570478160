import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'providerName'
})
export class ProviderNamePipe implements PipeTransform {

  transform(fnm ?: string, mnm ?: string, lnm ?: string, sfx ?: string, ttl ?: string): string {
    let name: string = fnm ? fnm : "";
    if (mnm && mnm.length > 0) { name = name + " " + mnm }
    if (lnm && lnm.length > 0) { name = name + " " + lnm; }
    if (sfx != null && sfx.length > 0) { name = name + " " + sfx; }
    if (ttl && ttl.length > 0) { name = name + ", " + ttl; }
    return name.replace(/\s\s+/g, ' ');
  }

}
