import { Component, OnInit, ViewChild } from '@angular/core';
import { DnConfirmationComponent } from '../../shared/components/dn-confirmation/dn-confirmation.component';
import { DirectoryHttpService } from '../../directory/services/directory-http.service';


@Component({
  selector: 'dnp-home',
  templateUrl: './dnp-home.component.html',
  styles: [
  ]
})
export class DnpHomeComponent implements OnInit {
  isCreating = false;
  @ViewChild('dnConfirm', {static: false, read: DnConfirmationComponent}) dnConfirmation !: DnConfirmationComponent;
  constructor(
    private directoryHttpService: DirectoryHttpService
    ) { }

  ngOnInit(): void {
  }

  emailDirectoryLink(emailRef: any) {
    this.isCreating = true;
    this.directoryHttpService.sendDirectoryValidationLink(emailRef.value).subscribe(resp => {
      this.isCreating = false;
      if (resp && resp.status) {
        this.dnConfirmation.show('Directory validation link has been sent your email box. Please click on the link in the email to begin directory validation.', 'success', 10000).subscribe();
      }
    });
  }
}
