import { Injectable, OnInit } from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {catchError, map, Observable, of, tap } from 'rxjs';
import {PersistanceService} from "../services/persistance.service";
import {DirectoryService} from "../../directory/services/directory.service";
import {DirectoryHttpService} from "../../directory/services/directory-http.service";

@Injectable({
  providedIn: 'root'
})
export class DisclaimerGuard implements CanActivate  {
  constructor(public persistanceService: PersistanceService, private router: Router,
              public directoryService: DirectoryService, public directoryHttpService: DirectoryHttpService,
              private activatedRoute: ActivatedRoute) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let token = route.paramMap.get("email");
    let isAdminAccessingLink = state.url.indexOf("/admin/directory") > -1;
    let params = {
      sendDirectoryValidationLink: state.url.indexOf("/admin/directory") == -1, 
      token: token
    }

    return this.directoryHttpService.checkDirectoryValidationLinkExpired(token!, params)
      .pipe(tap(resp => {
        this.recordLinkAccessActivity(isAdminAccessingLink, token, resp);
      }))
      .pipe(map(resp => {
        if(resp.isLinkExpired == true){
          return this.showLinkExpiredPage();
        }else {
          return this.showDisclaimer(token!,isAdminAccessingLink);
        }
      }), catchError(err => {
        console.log(err);
        this.router.navigate(["home"]);
        return of(false);
      }))

  }


  private recordLinkAccessActivity(isAdminAccessingLink: boolean, token: string | null, resp: any) {
   
    if (!isAdminAccessingLink &&  !this.persistanceService.getSessionItem("linkAccessActivity")) {      
      let params = {token: token, isLinkExpired: resp.isLinkExpired}
      this.directoryHttpService.recordDirectoryValidationLinkSelectActivity(params).subscribe({
        next: res => {},
        error: (err => {})
      });
      this.persistanceService.setSessionItem("linkAccessActivity",{"recorded":true})
    }
  }

  private showLinkExpiredPage(): boolean {
    this.router.navigate(["link-expired"]);
    return false;
  }

  private showDisclaimer(token: string, readOnly : boolean): boolean {
    setInterval(() => {
      this.directoryService.getGitVersion().subscribe((data) => {
      });
    }, 300000);

    let showDisclaimer = this.persistanceService.getSessionItem("showDisclaimer");
    if (showDisclaimer == undefined || showDisclaimer == null) {
      this.persistanceService.setSessionItem("showDisclaimer", true);
      showDisclaimer = true;
    }

    if (showDisclaimer) {          
      let params = readOnly ? {next: token, readOnly: true} : {next: token} ;
      this.router.navigate(["disclaimer"], {queryParams: params});
      return false;
    }

    this.persistanceService.setSessionItem("showDisclaimer", true);
    return true;
  }
}
