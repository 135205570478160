import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { isEqual } from "lodash-es";
import { NpiPipe } from "../pipes/npi.pipe";
import { PhoneFormatPipe } from "../pipes/phone-format.pipe";
import { TaxIdFormatPipe } from "../pipes/taxId-format.pipe";
import { ZipcodeDirective } from "./zipcode.directive";

export class DnValidators {
    //taxIdFormat
    static taxIdFormat(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!(control && control.value) || !control.dirty) {
                return null;
            }
            let val = TaxIdFormatPipe.parse(control.value);
            return val && val.length === TaxIdFormatPipe.maxLength ? null : { 'taxIdFormat': true, value: control.value };
        }
    }

    //npiFormat
    static npiFormat(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!(control && control.value)) {
                return null;
            }
            let val = NpiPipe.parse(control.value);
            return val && val.length === NpiPipe.maxLength ? null : { 'npiFormat': true, value: control.value };
        }
    }

    //phoneFormat
    static phoneFormat(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            let val = PhoneFormatPipe.parse(control.value);
            if (!(val)) {
                return null;
            }
            return val && val.length === PhoneFormatPipe.maxLength ? null : { 'phoneFormat': true, value: control.value };
        }
    }
    
    static zipcodeFormat(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!(control && control.value)) {
                return null;
            }
            let zip = control.value.replace(/\D+/g, '');
            zip = zip && zip.length > 9 ? zip.substring(0, 9) : zip;
            return zip && (zip.length === 5 || zip.length === 9) ? null : { 'zipcodeFormat': true, value: control.value };
        }
    }

    
    //textMatch
    static textNotMatch(textToMatch: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!(control && control.value && textToMatch)) {
                return null;
            }
            
            return isEqual(control.value,textToMatch) ?  { 'textNotMatch': true, value: control.value } : null;
        }
    }

    static textMatch(textToMatch: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!(control && control.value && textToMatch)) {
                return null;
            }            
            return isEqual(control.value,textToMatch) ? null: { 'textMatch': true, value: control.value } ;
        }
    }
}
