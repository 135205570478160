import { Component, NO_ERRORS_SCHEMA, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import each from 'lodash-es/each';
import { Subscription } from 'rxjs';
import { EventsService } from 'src/app/shared/services/events.service';
import { ProviderHttpInterceptor } from '../provider-http-interceptor';

enum ERRORS {
  AJAX = 'ajaxError',
  AUTHORIZATION = 'authorizationError',
  PAGINATION = 'paginationError'
}

@Component({
  selector: 'global-error',
  templateUrl: './global-error.component.html',
  styles: [
  ]
})
export class GlobalErrorComponent implements OnInit, OnDestroy {
  globalError: { [key: string]: boolean } | undefined = undefined;
  exceptionId: string = '';
  authorizationError: boolean = false;
  initialized: boolean = false;

  private subscriptions: { [key: string]: Subscription } = {};

  constructor(
    private providerHttpInterceptor: ProviderHttpInterceptor,
    private modalService: NgbModal,
    private router: Router,
    private eventService: EventsService) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.dismiss();
      }
    });
    this.subscriptions[ERRORS.PAGINATION] = eventService.onPagination.subscribe(() => {
      this.dismiss();
    });
  }

  ngOnInit(): void {
    this.subscriptions[ERRORS.AJAX] = this.providerHttpInterceptor.onAjaxError.subscribe((errorResp) => {
      this.errorHandler(ERRORS.AJAX, errorResp);
    });
    this.subscriptions[ERRORS.AUTHORIZATION] = this.providerHttpInterceptor.onAuthorizationError.subscribe((errorResp) => {
      this.errorHandler(ERRORS.AUTHORIZATION, errorResp);
    });
    this.initialized = true;
  }

  ngOnDestroy(): void {
    each(this.subscriptions, (s) => s.unsubscribe());
  }

  dismiss() {
    this.globalError = undefined;
  }

  private errorHandler(errorType: ERRORS, response: any) {
    if (this.initialized) {
      if (response && response.status === 403) {
        return;
      }
      setTimeout(() => {
        this.globalError = {};
        this.globalError[errorType] = true;
        if (response && response.data) {
          this.exceptionId = response.data.exceptionId;
        }
        if (this.modalService.hasOpenModals()) {
          this.modalService.dismissAll();
        }
        window.setTimeout(function () {
          window.scroll(0, 0);
        }, 0);
      });
    }
  }
}
