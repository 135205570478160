import {Component, OnInit, TemplateRef } from '@angular/core';
import {  Subject } from 'rxjs';

@Component({
  selector: 'dn-confirmation',
  templateUrl: './dn-confirmation.component.html',
  styleUrls: ['./dn-confirmation.component.css'],
  host: { 'class': 'toast-container position-fixed top-1 start-50 translate-middle-x p-3', 'style': 'z-index: 9999999' }
})
export class DnConfirmationComponent implements OnInit  {
  
  onShown = new Subject();
  toasts: any[] = [];
  showHeader = false;


  constructor() { }

  ngOnInit(): void {
  }

 
  isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }

  toastShown(e:any) {
    this.onShown.next(true);
    this.onShown.complete();
  } 

  show(textOrTpl: string | TemplateRef<any>, messageType: string = 'success', delay: number = 4000) {
    let options: any = {};
    options.classname = 'dn-toast ' + 'bg-' + messageType.toLowerCase() + " fade-top ng-enter text-light text-center";
    options.delay = delay;
    this.toasts.push({ textOrTpl, ...options });     
    return this.onShown.asObservable();
  }
  
  remove(toast: any) {
      this.toasts = this.toasts.filter(t => t !== toast);
  }

  clear() {
      this.toasts.splice(0, this.toasts.length);
  }

}
