import { AfterViewInit, Directive, ElementRef, Host, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import each from "lodash-es/each";
import findIndex from "lodash-es/findIndex";
import { Subscription } from "rxjs";
import { FileUploadComponent } from "./file-upload.component";
import { DocumentInfo } from "./file-upload.interface";

@Directive({
    selector: '[fileUploadListener]',
    exportAs: 'fileUploadListener'
})
export class FileUploadListenerDirective implements OnInit, OnDestroy, AfterViewInit {
    private uploadedFiles !: DocumentInfo[];
    private uploadedEventSubscription !: Subscription;
    private deletedEventSubscription !: Subscription;

    constructor(@Host() private fileUpload: FileUploadComponent) { };

    ngOnInit(): void {
        this.uploadedFiles = this.fileUpload.savedDocuments;
    }

    ngAfterViewInit(): void {
        this.uploadedFiles = this.uploadedFiles && Array.isArray(this.uploadedFiles) ? this.uploadedFiles : [];
    
        this.uploadedEventSubscription = this.fileUpload.onFilesUploaded.subscribe((newDocs: any) => {
            let _docs = newDocs as DocumentInfo[];
            if (_docs && _docs.length > 0) {
                each(_docs, (doc) => {
                    if (findIndex(this.uploadedFiles, (f) => { return f.name === doc.name; }) < 0) {
                        this.uploadedFiles.push(doc);
                    }
                });
                let sub = this.fileUpload.dnConfirmation?.show('Successfully uploaded ' + _docs.length + ' document' + (_docs.length == 1 ? "." : "s."), 'success', 5000).subscribe();
            }
        });
        this.deletedEventSubscription = this.fileUpload.onFileDeleted.subscribe((fileName: any) => {
            let ndx = findIndex(this.uploadedFiles, (f) => { return f.name === fileName; });
            if (ndx > -1) {
                this.uploadedFiles.splice(ndx, 1);
            }
        });
    }

    ngOnDestroy(): void {
        this.deletedEventSubscription.unsubscribe();
        this.uploadedEventSubscription.unsubscribe();
    }
}
