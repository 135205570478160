<div style="padding-left: 10px">
  <ul style="list-style-type: none; padding: 0px" highlighter="_prov">
    <li id="name" title="Provider Name & Degree Title" class="font-weight-700"> 
      {{
      providerInfo.firstname|
      providerName :providerInfo.middlename:providerInfo.lastname:providerInfo.suffix:providerInfo.title | uppercase
      }}
    </li>
    <li id="gender" title="Gender">
      Gender: {{providerInfo.gender === 'Unknown' ? 'Other' : providerInfo.gender}}
    </li>
    <li id="board" title="Board Certification" *ngIf="providerInfo.boardStatus && providerInfo.boardStatus.length > 0">
      {{providerInfo.boardName | boardCertification: providerInfo.boardStatus: boardNames}}
    </li>
    <li id="stateLicense" title="State License" [ngClass]="{'text-danger required-field': providerInfo.licenses.length == 0 || !providerInfo.licenses[0].licenseNumber}">License No:
      {{providerInfo.licenses.length > 0 ?
      providerInfo.licenses[0].licenseNumber: ""}}</li>
    <li id="npi" title="NPI" [ngClass]="{'text-danger required-field': !providerInfo.npi}">NPI: {{providerInfo.npi | npi}}</li>
    <li id="languages" title="Languages spoken" *ngIf="getLanguages().length >0 ">Additional Languages: {{getLanguages()| titlecase}}</li>
  </ul>
</div>
