import { AfterViewChecked} from "@angular/core";
import { Component, ElementRef, HostListener, Input, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
    selector: 'site-survey',
    templateUrl: './site-survey.component.html',
    styleUrls: ['./site-survey.component.css']
})

export class SiteSurveyComponent implements OnInit, AfterViewChecked {
    @Input() elementInput !: HTMLElement;
  
        constructor(private element: ElementRef){}
    
    surveyUrl: String =""
    showButton: boolean = true;
    
    ngAfterViewChecked(){
     }

    ngOnInit(): void {        
         this.surveyUrl = environment.surveyUrl;
    }

    @HostListener('window:scroll', ['$event']) // for window scroll events 
    onScroll(event: Event) {
        if (this.elementInput)
            this.showHideMonkeySurvey();
    }

    showHideMonkeySurvey() {
        const hostElement = this.element.nativeElement;
        const surveyMonkeyClass = hostElement.querySelectorAll('.surveyMonkeyBox')[0];
        const rectsurveyMonkey = surveyMonkeyClass.getBoundingClientRect();        
        const rectButton = this.elementInput.getBoundingClientRect();

        if ((rectButton?.top >= rectsurveyMonkey.top && rectButton?.top <= rectsurveyMonkey.bottom) ||
            (rectButton?.bottom >= rectsurveyMonkey.top && rectButton?.bottom <= rectsurveyMonkey.bottom))
            this.showButton = false;
        else
            this.showButton = true;
    }
}