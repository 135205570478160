import { Input, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { DnErrorControlDirective } from "../../dn-error-display/directives/dn-error-control.directive";
import { DocumentInfo } from 'src/app/shared/components/file-upload/file-upload.interface';


@Component({
  selector: 'attest-and-submit-modal',
  templateUrl: './attest-and-submit-modal.component.html',
  styles: [
  ]
})


export class AttestAndSubmitModalComponent implements OnInit {

  @Input() currentUser !: any;
  @Input() uploadedFiles !: DocumentInfo[];
  @Input() token !: any;

  fullName: string = "";
  comments: string = "";
  public isCollapsed = true;
  @ViewChild('errorControl', { static: false }) _errorControl !: DnErrorControlDirective;
  constructor(public activeModal: NgbActiveModal,
    private router: Router,
    private route: ActivatedRoute
  ) { };

  ngOnInit(): void {
  }

  submit(form: NgForm) {
    this._errorControl.showErrors();
    if (form.invalid) {
      return;
    }
    this.activeModal.close(
      { 
        "fullname": this.fullName,
        "comments": this.comments,
        documents: this.uploadedFiles
    });
    
    // this.router.navigate(['confirmDirectoryInfoSuccess'], { relativeTo: this.route }).then((e) => {
    //   if (e) { }
    //   else { }
    // });
  }

  cancelEdits(e: any, form: NgForm){
    this._errorControl.hideErrors();
    this.activeModal.dismiss(
      {
        documents: this.uploadedFiles
      }
    );
    return;
  }


}
