import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnInit, Output } from '@angular/core';
import each from 'lodash-es/each';
import findIndex from 'lodash-es/findIndex';
import { Observable, map, of } from 'rxjs';
import { PersistanceService } from '../../shared/services/persistance.service';
import { Pageable } from '../../shared/shared.interface';
import {
  Affiliation,
  DirectorySearchCriteria,
  Contact,
  Directory,
  DirectoryChangeRequest,
  DirectoryData,
  DIRECTORY_TYPE,
  ReviewedAffiliation,
  CUSTOM_KEY,
  LocationManagerRequest,
  LocationManager,
  Location,
  PracticeEmailChangeRequest, ProviderEmailChangeRequest
} from '../directory.interface';
import isEmpty from "lodash-es/isEmpty";
import omitBy from "lodash-es/omitBy";
import pick from "lodash-es/pick";
import pickBy from "lodash-es/pickBy";
import filter from 'lodash-es/filter';
import { DirectoryHttpService } from './directory-http.service';
import { DocumentInfo } from '../../shared/components/file-upload/file-upload.interface';
import isEqual from 'lodash-es/isEqual';
import isUndefined from 'lodash-es/isUndefined';
import isNull from 'lodash-es/isNull';
import { DirectoryCacheService } from './directory-cache.service';


@Injectable({
  providedIn: 'root'
})
export class DirectoryService {
  private keyPrefix !: string;
  private token = "";
  private customKeys = [DIRECTORY_TYPE.VISITEDAFFILIATIONS];
  private directoryItemTypes = [DIRECTORY_TYPE.PROVIDER, DIRECTORY_TYPE.LOCATION, DIRECTORY_TYPE.PRACTICE, DIRECTORY_TYPE.AFFILIATION];
  private itemtypesToChangesMap = { [DIRECTORY_TYPE.PROVIDER]: "providers", [DIRECTORY_TYPE.LOCATION]: "locations", [DIRECTORY_TYPE.PRACTICE]: "practices", [DIRECTORY_TYPE.AFFILIATION]: "affiliations" };
  private emailRoles: [] | null = null;

  constructor(private directoryHttpService: DirectoryHttpService,
    private persister: PersistanceService,
    private cacheService: DirectoryCacheService) { }

  public buildKeyPrefix(email: any) {
    if (email && email != undefined) {
      this.token = email;
      this.keyPrefix = "dir" + btoa(email);
      let knownKeys: string[] = [];
      each(this.directoryItemTypes, (type) => {
        knownKeys.push(this.getDirectoryItemKey(this.keyPrefix, type));
      });
      each(this.customKeys,(key) =>{
        knownKeys.push(this.getDirectoryItemKey(this.keyPrefix, key));
      });
      this.persister.saveGuid(this.keyPrefix, knownKeys);      
      this.cacheService.loadCache(email);      
      return this.keyPrefix;
    } else {
      console.error("Invalid token");
      throw new Error("Authorization Error")
    }
  }

  public clearDirectoryCache() {
    this.cacheService.clearCache();
  }
  public getDirectoryItemKey(keyPrefix: string, type: any) {
    return keyPrefix + "." + type;
  }

  public getDirectory(keyPrefix: string, pageable: Pageable, searchCriteria?: DirectorySearchCriteria): Observable<DirectoryData> {
    let  params = new HttpParams()
      .set('page', pageable.page)
      .set('size', pageable.size);

    if(searchCriteria){
      Object.entries(searchCriteria)
        .forEach(entry => {
          let key = entry[0];
          let value = entry[1];
          if(!isEmpty(value)){
            params = params.set(key, value);
          }

        })
    }

    return this.directoryHttpService.getDirectory(this.token, params)
      .pipe(
        map((data: DirectoryData) => {
          if (data && data.directory) {
            this.restoreOriginals(keyPrefix, data.directory);
            data.directory.affiliations.forEach((affiliation: Affiliation, index: number) => {
              affiliation.isValid = (affiliation.isValid === undefined || affiliation.isValid === null) ? false : affiliation.isValid;
            });
          }
          return data;
        })
      );
  }

  public getTotalUnvalidatedAffiliations() {
    return this.directoryHttpService.getTotalUnvalidatedAffiliations(this.token);
  }

  public saveDirectoryItem(keyPrefix: string, type: DIRECTORY_TYPE, changed: Object, original: Object) {
    this.persister.saveDirectoryItemByKey(this.getDirectoryItemKey(keyPrefix, type), this.getItemId(type), changed, original);
  }

  public removeDirectoryItem(keyPrefix: string, type: DIRECTORY_TYPE, changed: Object) {
    this.removeDirectoryItems(keyPrefix, type, changed);
  }

  public restoreEdits(keyPrefix: string, type: DIRECTORY_TYPE, items: any) {
    this.restoreFromStorage(keyPrefix, type, items, false);
  }

  public getDocumentsFromStorage(keyPrefix: string): DocumentInfo[] {
    let documents = this.persister.getDirectoryItem(this.getDirectoryItemKey(keyPrefix, DIRECTORY_TYPE.DOCUMENTS));
    return documents.changed && documents.changed.length > 0 && documents.changed[0].directoryUploads ? documents.changed[0].directoryUploads : [];
  }

  public handleRevertedChange(keyPrefix: string, type: DIRECTORY_TYPE, changed: Object) {
    let revertedChange = false;
    let key = this.getDirectoryItemKey(keyPrefix, type);
    let storedData = this.persister.getDirectoryItem(key);
    let itemId = this.getItemId(type);
    if (storedData && storedData.original && changed) {
        let originalNdx = this.getItemIndex(storedData.original, itemId, changed);
        if (originalNdx > -1) {
          let modifiedNdx = this.getItemIndex(storedData.changed, itemId, changed);
          let originalObject = pick(storedData.original[originalNdx],Object.keys(changed));
          let modifiedObject = pick(changed,Object.keys(originalObject));
          revertedChange = isEqual(omitBy(originalObject, (v) => isUndefined(v) || isNull(v)),omitBy(modifiedObject, (v) => isUndefined(v) || isNull(v)));
          if (revertedChange) {
            this.persister.removeDirectoryItemByIndex(key,originalNdx,modifiedNdx);
          }
        }
    }
    return revertedChange;
  }

  public deleteFromStorageByDirectoryItemKey(keyPrefix: string): void {
    this.persister.clearGuidStorage(keyPrefix);
    this.persister.removeSessionItemByKey(keyPrefix);
  }

  public clearFromStorageByDirectoryItemKey(key: string): void {
    this.persister.removeByKey(key);
  }

  public getAPNullRequiredFields(validationObject: any, reqFields: string[], type: string, requiredFields: any): any {
    let pickList = pick(validationObject, reqFields);
    let omitList = omitBy(pickList, function (v, k) {
      return (v !== null && v !== "" && v !== '' && v !== undefined);
    });
    if (!isEmpty(omitList)) {
      switch (type) {
        case DIRECTORY_TYPE.PROVIDER: {
          return requiredFields.provider = Object.keys(omitList);
        }
        // case DIRECTORY_TYPE.PRACTICE: {
        //   return requiredFields.practice = Object.keys(omitList);
        // }
        case DIRECTORY_TYPE.LOCATION: {
          return requiredFields.location = Object.keys(omitList);
        }
        case DIRECTORY_TYPE.AFFILIATION: {
          return requiredFields.affilation = Object.keys(omitList);
        }
      }
    }
    return requiredFields;
  }

  public getCheckedAffiliationsHavingUnValidDataCount(keyPrefix: string, type: DIRECTORY_TYPE, attestationDueDays: number, today: Date) {
    let restoredData = this.persister.getDirectoryItem(this.getDirectoryItemKey(keyPrefix, type));
    let affiliations: ReviewedAffiliation[] = restoredData.changed;
    let checkedAffiliationHavingUnValidDataCnt: number = 0;

    if (affiliations) {
      each(affiliations, (affiliation) => {
        if (affiliation.dueDate) {
          let _date = new Date(affiliation.dueDate);
          _date.setHours(0, 0, 0, 0);
          if (_date.getTime() < today.getTime()) {
            checkedAffiliationHavingUnValidDataCnt++;
          }
        }
      });
    }
    return checkedAffiliationHavingUnValidDataCnt;
  }

  public saveLocationManagerChanges(dir: Directory): Observable<any>{
    const changeRequests: DirectoryChangeRequest = {} as DirectoryChangeRequest;
    changeRequests.currentDirectory = dir;

    return this.directoryHttpService.saveLocationManager(this.token, changeRequests);

  }

  public sendChangeRequests(keyPrefix: string, attested: {}): Observable<any> {
    let originalDirectory = {} as Directory;
    let modifiedDirectory = {} as Directory;
    let invalidated = this.persister.getDirectoryItem(this.getDirectoryItemKey(keyPrefix, DIRECTORY_TYPE.VALIDATION));
    let documents = this.getDocumentsFromStorage(keyPrefix);
    each(this.directoryItemTypes, (type) => {
      let directoryItemKey = this.itemtypesToChangesMap[type as keyof {}];
      let storedData = this.persister.getDirectoryItem(this.getDirectoryItemKey(keyPrefix, type));
      originalDirectory[directoryItemKey as keyof Directory] = storedData?.original ? storedData.original : [];
      modifiedDirectory[directoryItemKey as keyof Directory] = storedData?.changed ? storedData.changed : [];
    });

    const changeRequests: DirectoryChangeRequest = {} as DirectoryChangeRequest;
    changeRequests.currentDirectory = originalDirectory;
    changeRequests.modifiedDirectory = modifiedDirectory;
    changeRequests.invalidated = invalidated?.changed ? invalidated.changed : [];
    changeRequests.documents = documents ? documents : [];
    changeRequests.attested = { fullname: attested["fullname" as keyof {}] } as Contact;
    changeRequests.responseDate = new Date().toISOString();
    changeRequests.comments = attested["comments" as keyof {}];
    return this.directoryHttpService.sendDirectoryValidations(this.token, changeRequests);
  }

  public testDirectory(pageable: Pageable, email: string | null, searchCriteria?: DirectorySearchCriteria): Observable<DirectoryData> {
    return this.getDirectory(this.buildKeyPrefix(email), pageable, searchCriteria);
  }

  private restoreOriginals(keyPrefix: string, directory: Directory) {
    this.restoreFromStorage(keyPrefix, DIRECTORY_TYPE.PROVIDER, directory.providers, true);
    this.restoreFromStorage(keyPrefix, DIRECTORY_TYPE.LOCATION, directory.locations, true);
    this.restoreFromStorage(keyPrefix, DIRECTORY_TYPE.AFFILIATION, directory.affiliations, true);
  }

  private restoreFromStorage(keyPrefix: string, type: DIRECTORY_TYPE, items: any, restoreOriginal: boolean = false) {
    let restoredData = this.persister.getDirectoryItem(this.getDirectoryItemKey(keyPrefix, type));
    let itemId = this.getItemId(type);
    if (restoredData) {
      let editedData: any[] = restoreOriginal ? restoredData.original : restoredData.changed;
      if (editedData && itemId) {
        each(editedData, (editedItem) => {
          let ndx = this.getItemIndex(items, itemId, editedItem)
          if (ndx > -1) {
            items[ndx] = editedItem;
          }
        });
      }
    }
    if (itemId === "accessPointId") {
      let storedValidations = this.persister.getDirectoryItem(this.getDirectoryItemKey(keyPrefix, DIRECTORY_TYPE.VALIDATION));

      each(storedValidations?.changed, (val) => {
        let ndx = this.getItemIndex(items, itemId, val);
        if (ndx > -1) {
          items[ndx].isValid = false;
        }
      });
    }
  }

  private removeDirectoryItems(keyPrefix: string, type: DIRECTORY_TYPE, items: any) {
    items = Array.isArray(items) ? items : [items];
    let storedData = this.persister.getDirectoryItem(this.getDirectoryItemKey(keyPrefix, type));
    let itemId = this.getItemId(type);
    if (storedData) {
      let editedData: any[] = storedData.changed;
      if (editedData && itemId) {
        let updated = filter(editedData, (editedItem) => {
          return this.getItemIndex(items, itemId, editedItem) === -1;
        });
        this.persister.replaceChangedDirectoryItems(this.getDirectoryItemKey(keyPrefix, type), updated);
      }
    }
  }

  private getItemIndex(items: any, itemId: string, editedItem: any): number {
    let ndx = findIndex(items, (item: any) => {
      if (itemId === "accessPointId") {
        return item[itemId]["contractLineId"] === editedItem[itemId]["contractLineId"] &&
          item[itemId]["specialityId"] === editedItem[itemId]["specialityId"]
      } else {
        return item[itemId] === editedItem[itemId];
      }
    });
    return ndx;
  }

  private getItemId(type: DIRECTORY_TYPE) {

    switch (type) {
      case DIRECTORY_TYPE.PROVIDER: return "providerId";
      case DIRECTORY_TYPE.PRACTICE: return "practiceId";
      case DIRECTORY_TYPE.LOCATION: return "locationId";
      case DIRECTORY_TYPE.AFFILIATION:
      case DIRECTORY_TYPE.VALIDATION: return "accessPointId";
      case DIRECTORY_TYPE.ATTESTED: return "fullname";
      case DIRECTORY_TYPE.DOCUMENTS: return "fileName";
      case DIRECTORY_TYPE.DISCLAIMER: return "disclaimer";
      case DIRECTORY_TYPE.VISITEDAFFILIATIONS: return "visitedAffiliations";
    }

  }

  private calculateDueDays(dueDate: any) {
    let today = new Date();
    today.setHours(0, 0, 0, 0);

    let date = new Date(dueDate);
    date.setHours(0, 0, 0, 0);
    let time = date.getTime() - today.getTime();
    return (time / (1000 * 3600 * 24));
  }

  public getGitVersion() {
    return this.directoryHttpService.getGitVersion();
  }

  public getTotalNumberOfEdits (directoryStateKey: string) {
    //get the total number of edits, show the warning message if number exceed max defined in application.yml
    let providers = this.persister.getDirectoryItem(directoryStateKey + '.provider');
    let affiliations = this.persister.getDirectoryItem(directoryStateKey + '.affiliation');
    let locations = this.persister.getDirectoryItem(directoryStateKey + '.location');
    let totalEdits = 0;
    if (providers && providers.changed) {
      totalEdits += providers.changed.length;
    }
    if (affiliations && affiliations.changed) {
      totalEdits += affiliations.changed.length;
    }
    if (locations && locations.changed) {
      totalEdits += locations.changed.length;
    }
    //console.log("Total edits: " + totalEdits);
    return totalEdits;
  }

  public isProviderChanged(directoryStateKey: string, providerId: string): boolean {
    let providers = this.persister.getDirectoryItem(directoryStateKey + '.provider');
    let isChanged = false;
    if (providers && providers.changed) {
      let result = providers.changed.find((provider: { providerId: string; }) => {
        return provider.providerId === providerId;
      });
      if (result && result.providerId === providerId) {
        isChanged = true;
      }
    }
    return isChanged;
  }

  public isLocationChanged(directoryStateKey: string, locationId: string): boolean {
    let locations = this.persister.getDirectoryItem(directoryStateKey + '.location');
    let isChanged = false;
    if (locations && locations.changed) {
      let result = locations.changed.find((location: { locationId: string; }) => {
        return location.locationId === locationId;
      });
      if (result && result.locationId === locationId) {
        isChanged = true;
      }
    }
    return isChanged;
  }

  public isAcceptPatientChanged(directoryStateKey: string, affiliationId: number) {
    let affiliations = this.persister.getDirectoryItem(directoryStateKey + '.affiliation');
    let isChanged = false;
    if (affiliations && affiliations.changed) {
      let result = affiliations.changed.find((affiliation: { affiliationId: number; }) => {
        return affiliation.affiliationId === affiliationId;
      });
      if (result && result.affiliationId === affiliationId) {
        isChanged = true;
      }
    }
    return isChanged;
  }

  public getLocationManagerDetails(locationId: string){
    return this.directoryHttpService.getLocationManagerDetails(this.token, locationId);
  }

  public savePracticeEmailChangeRequest(emailChangeRequest: PracticeEmailChangeRequest){
    return this.directoryHttpService.savePracticeEmailChangeRequest(this.token, emailChangeRequest);
  }

  public saveProviderEmailChangeRequest(emailChangeRequest: ProviderEmailChangeRequest){
    return this.directoryHttpService.saveProviderEmailChangeRequest(this.token, emailChangeRequest);
  }

  public getEmailRoles():Observable<any> {
    if (this.emailRoles) {
      return of(this.emailRoles);
    }else{
      return this.directoryHttpService.getEmailRoles(this.token).pipe(
        map(data => {
          this.emailRoles = data? data.emailRoles : [] ;
          return this.emailRoles;
      }));
    }
  }

}

