<form  dnErrorControl="searchFormControl" (ngSubmit)="onSearchSubmit(ngForm)" #ngForm="ngForm">
<div  class="row d-flex justify-content-left align-items-top">
  <div class="col-md-3 col-sm-12 pe-md-0 mb-1">
    <select class="form-select form-select-sm required" aria-label=" " id="searchType" name="searchType"
            dnDisplayErrors   required  [(ngModel)]="searchType" (ngModelChange)="onSearchTypeChange()">
      <option style="color:grey" selected disabled value="">Select Criteria</option>
      <option class="text-body" *ngFor="let sd of searchTypeDropdown" [value]="sd.value">{{sd.type}}</option>
    </select>
  </div>

  <div class="col-md-4 col-sm-12 pe-md-0 mb-1 search">
    <i class="fa fa-search" aria-hidden="true"></i><input type="input" class="form-control form-control-sm required" id="searchBox" name="searchBox"
           required  #searchBox="ngModel" dnDisplayErrors (ngModelChange)="onSearchBoxChange()"
           [(ngModel)]="searchText" placeholder="Search by criteria">
    <button type="button" class="btn text-muted" (click)="searchText=''" *ngIf="searchText"><i class="fa fa-times" aria-hidden="true"></i></button>

  </div>
  <div class="col-md-3 col-sm-12 mb-1">
    <button type="submit"  [disabled]="lazyParams.loading" class="btn btn-primary btn-sm">Search</button>
    <button type="button" (click)="onClearSearch(ngForm)" [disabled]="lazyParams.loading" class="btn btn-outline-secondary btn-sm ms-1">Clear</button>
  </div>

  <div class="row" *ngIf="isFiltered">
    <div class="col-md-12">
      <label class="text-info fw-lighter small"><i>{{displayText}}</i></label>
    </div>
  </div>
</div>
</form>
