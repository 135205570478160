import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError, Observable } from 'rxjs'; 

@Injectable()
export class ProviderHttpInterceptor implements HttpInterceptor{
  onAjaxError: BehaviorSubject<Object> = new BehaviorSubject({}); 
  onAuthorizationError: BehaviorSubject<Object> = new BehaviorSubject({});
 
  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
           .pipe(
            catchError((error) => { 
                let method;
                let requestUrl;
                if (error instanceof HttpErrorResponse && !(error.error instanceof ErrorEvent)) {
                  if (error.status == 403) {
                     this.onAuthorizationError.next(error);
                  }
                  if (error.status != 401 && req.url != 'version') {
                     this.onAjaxError.next(error); 
                  }
                } 
                return throwError(()=>error);
            })
           );
  }
  
}
