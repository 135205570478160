import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'network'
})
export class NetworkPipe implements PipeTransform {
  transform(network?: string, subnetwork?: string): string {    
    let val =  subnetwork ? `${network}<br/>${subnetwork}` : network;
    return val ? val.toUpperCase() : '';
  }
}