import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'legal-and-privacy',
  templateUrl: './legal-and-privacy.component.html'
})
export class LegalAndPrivacyComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
