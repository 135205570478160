import { Input } from "@angular/core";
import { OnInit } from "@angular/core";
import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'validateAffiliation',
    styles: [
        '.modal-header { border-bottom: 0 none;  }',
        '.modal-footer { border-top: 0 none; }'
    ],
    templateUrl: "validate-affiliation-modal.component.html"
})
export class ValidateAffiliationModalComponent implements OnInit {
    @Input() requiredFields !: any;
    @Input() isSelectAll !: boolean
    providerFields !: string
    locationFields !: string
    affiliationFields !: string
    practiceFields !: string

    constructor(public activeModal: NgbActiveModal) { };
    ngOnInit(): void {
        this.providerFields = this.requiredFields?.provider? JSON.parse(JSON.stringify(this.requiredFields?.provider)) : '';
        this.locationFields = this.requiredFields?.location? JSON.parse(JSON.stringify(this.requiredFields?.location)) : '';
        this.affiliationFields = this.requiredFields?.affilation? JSON.parse(JSON.stringify(this.requiredFields?.affilation)) : '';
        // this.practiceFields = this.requiredFields?.practice? JSON.parse(JSON.stringify(this.requiredFields?.practice)) : '';
        // this.locationFields += this.practiceFields; 
    }

}