<div class="modal-header">
  <div class="align-top">
    <h3 class="modal-title"><i class="fa-solid fa-triangle-exclamation fa-lg text-warning"></i>
      {{'headers.directory-reset' | translate}}</h3>
  </div>
</div>
<div class="modal-body align-middle">
  {{'content.directory-reset' | translate}}
</div>
<div class="modal-footer">
  <div class="d-grid gap-2 d-md-flex justify-content-md-end">
    <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
    <button type="button" [autofocus]="true" class="btn btn-primary" (click)="continue()">Continue</button>
  </div>
</div>