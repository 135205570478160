import { Injectable } from '@angular/core';
import each from 'lodash-es/each';
import find from 'lodash-es/find';
import findIndex from 'lodash-es/findIndex';
import map from 'lodash-es/map';
import isEmpty from 'lodash-es/isEmpty';
import { Affiliation } from 'src/app/directory/directory.interface';


@Injectable({
  providedIn: 'root'
})
export class PersistanceService {
  private key_suffix_original = ".original";
  private key_suffix_modified = ".modified";
  private _guid !: string;

  constructor() { }

  public set(key: string, data: any) {
    if (data) {
      localStorage.setItem(key, JSON.stringify(data))
    }
  }

  private get(key: string) {
    let data = localStorage.getItem(key);
    return data ? JSON.parse(data) : data;
  }

  public getSessionItem(key: string) {
    let data = sessionStorage.getItem(key);
    return data ? JSON.parse(data) : data;
  }

  public setSessionItem(key: string, data: any) {
    sessionStorage.setItem(key, JSON.stringify(data))
  }

  public removeByKey(key: string) {
    localStorage.removeItem(key);
  }

  public clearSessionStorage() {
    sessionStorage.clear()
  }

  public clearStorage() {
    localStorage.clear();
  }

  public removeSessionItemByKey(key: string) {
    sessionStorage.removeItem(key);
  }

  public clearGuidStorage(guid: string) {
    let val = this.loadGuid(guid);
    let keys = val["keys" as keyof Object];
    each(keys, (key) => {
      this.removeByKey(key);
    });
    this.removeByKey(guid);
  }

  public removeDirectoryItemByIndex(key: string, originalIndex: number, modifiedIndex: number) {
    let saved = this.getDirectoryItem(key);
    if (saved.original) {
      saved.original.splice(originalIndex,1);
      this.set(key + this.key_suffix_original, saved.original);
    }
    if (saved.changed) {
      saved.changed.splice(modifiedIndex,1);
      this.set(key + this.key_suffix_modified, saved.changed);
    }
  }

  public replaceChangedDirectoryItems(key: string, changed: any[]) {
    if (changed) {
      this.set(key + this.key_suffix_modified, changed);
    }
  }

  public saveDirectoryItemByKey(key: string, dataKey: string, changed: Object, original: Object) {
    if (!isEmpty(original)) {
      let _key = key + this.key_suffix_original;
      let data = this.get(_key);
      if (!(data && original[dataKey as keyof Object] && this.findIndexOf(data, original, dataKey) > -1)) {
        this.saveDirItem(_key, original, dataKey);
      }
    }
    if (changed) {
      this.saveDirItem(key + this.key_suffix_modified, changed, dataKey);
    }
  }

  public getDirectoryItem(key: string) {
    let original = this.get(key + this.key_suffix_original);
    let changed = this.get(key + this.key_suffix_modified);
    return { 'original': original, 'changed': changed }
  }

  private saveDirItem(key: string, dirItem: Object, dataKey: String) {
    let data = this.get(key);
    if (data && dirItem && dirItem[dataKey as keyof Object]) {
      let index = this.findIndexOf(data, dirItem, dataKey);
      if (index > -1) {
        data.splice(index, 1, dirItem);
      } else {
        data.push(dirItem);
      }
    } else {
      data = [];
      data.push(dirItem);
    }
    this.set(key, data);
    this.preserveDirectoryItemKey(key);
  }

  public getDirectoryItemByKey(key: string) {
    return this.get(key);
  }

  public saveGuid(key: string, keys: string[]) {
    this._guid = key;
    let _knownKeys: string[] = [];
    each(keys, (key) => {
      _knownKeys.push(key);
      _knownKeys.push(key + this.key_suffix_original);
      _knownKeys.push(key + this.key_suffix_modified);
    });
    this.loadGuid(this._guid, _knownKeys);
  }


  private loadGuid(guid: string, knownKeys: string[] = []) {
    let guidVal = this.get(guid);
    let keys = guidVal ? guidVal["keys" as keyof Object] : [];
    if (keys.length === 0) {
      guidVal = { "created": new Date().toUTCString(), "keys": knownKeys };
      this.set(guid, guidVal);
    }
    return guidVal;
  }

  private preserveDirectoryItemKey(itemKey: string) {
    let val = this.loadGuid(this._guid);
    let keys = val["keys" as keyof Object];
    if (!find(keys, (k) => itemKey === k)) {
      keys.push(itemKey);
      val["keys" as keyof Object] = keys;
      this.set(this._guid, val);
    }
  }

  private findIndexOf(data: any[], dirItem: Object, dataKey: String) {
    return findIndex(data, (d) => {
      if (dataKey === 'accessPointId') {
        let aff = dirItem as Affiliation;
        return d['accessPointId']["contractLineId"] === aff.accessPointId.contractLineId &&
          d['accessPointId']["specialityId"] === aff.accessPointId.specialityId
      } else {
        return d[dataKey as keyof Object] === dirItem[dataKey as keyof Object];
      }
    });
  }
}
