import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ScrollToErrorFormDirective } from './directives/scroll-to-error-form.directive';
import { FileUploadModule } from 'primeng/fileupload';
import { FormsModule } from '@angular/forms';
import { MessagesModule } from 'primeng/messages';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { CommonModule } from '@angular/common';
import { BoardCertificationPipe } from './pipes/board-certification.pipe';
import { NetworkPipe } from './pipes/network.pipe';
import { WorkHoursPipe } from './pipes/work-hours.pipe';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { HighlighterDirective } from './directives/highlighter.directive';
import { FormatSizePipe } from './pipes/format-size.pipe';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { NgbModule, NgbTimepicker } from '@ng-bootstrap/ng-bootstrap';
import { PhoneFormatPipe } from './pipes/phone-format.pipe';
import { TaxIdFormatPipe } from './pipes/taxId-format.pipe';
import { CityStateZipPipe } from './pipes/cityStateZip.pipe';
import { TaxIdDirective } from './directives/tax-id.directive';
import { DigitsOnlyDirective } from './directives/digits-only.directive';
import { NpiDirective } from './directives/npi.directive';
import { FormatPhoneDirective } from './directives/format-phone.directive';
import { TranslateModule } from '@ngx-translate/core';
import { ZipcodeDirective } from './directives/zipcode.directive';
import { DnConfirmationComponent } from './components/dn-confirmation/dn-confirmation.component';
import { FileUploadListenerDirective } from './components/file-upload/file-upload-listener.directive';
import { TextValueAccessor } from './directives/text-value-accessor';
import { TaxIdMaskPipe } from './pipes/taxId-mask.pipe';
import { TableModule } from 'primeng/table';
import { CaptchaValidatorDirective } from './directives/validate-captcha.directive';
import { MaxEditWarnComponent } from './components/max-edit-warn/max-edit-warn.component';
import { OfficeHoursComponent } from './components/officeHours/office-hours.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { DnErrorDisplayModule } from '../dn-error-display/dn-error-display.module';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { OneRequiredDirective } from './directives/one-required.directive';
import { SiteSurveyComponent } from './components/site-survey/site-survey.component';
import { TextMatchDirective } from './directives/text-match.directive';


@NgModule({
  declarations: [
    ScrollToErrorFormDirective, TaxIdDirective, AutoFocusDirective, HighlighterDirective, DigitsOnlyDirective, NpiDirective, FormatPhoneDirective,
    ZipcodeDirective, TextValueAccessor,
    FileUploadComponent, DnConfirmationComponent, FileUploadListenerDirective,
    BoardCertificationPipe,
    NetworkPipe,
    WorkHoursPipe,
    FormatSizePipe,
    PhoneFormatPipe,
    TaxIdFormatPipe,
    CityStateZipPipe,
    TaxIdMaskPipe,
    CaptchaValidatorDirective,
    MaxEditWarnComponent,
    OfficeHoursComponent,
    TimePickerComponent,
    OneRequiredDirective,
    SiteSurveyComponent,
    TextMatchDirective
  ],
    exports: [
        ScrollToErrorFormDirective, TaxIdDirective, AutoFocusDirective, HighlighterDirective, DigitsOnlyDirective, NpiDirective, FormatPhoneDirective,
        ZipcodeDirective, TextValueAccessor,
        FileUploadComponent, DnConfirmationComponent, FileUploadListenerDirective,
        BoardCertificationPipe,
        NetworkPipe,
        WorkHoursPipe,
        FormatSizePipe,
        PhoneFormatPipe,
        TaxIdFormatPipe,
        TaxIdMaskPipe,
        CityStateZipPipe,
        TranslateModule,
        NgbModule,
        CaptchaValidatorDirective,
        MaxEditWarnComponent,
        OfficeHoursComponent,
        NgbTimepicker,
        TimePickerComponent,
        OneRequiredDirective,
        SiteSurveyComponent,
        TextMatchDirective
    ],
  imports: [
    MessagesModule,
    CommonModule,
    FileUploadModule,
    FormsModule,
    HttpClientModule,
    TooltipModule,
    ButtonModule,
    NgbModule,
    TableModule,
    TranslateModule,
    MultiSelectModule,
    DnErrorDisplayModule
  ]
})
export class SharedModule {
}
