import { ElementRef, HostListener } from '@angular/core';
import { Directive } from '@angular/core';

@Directive({
  selector: '[scrollToErrorForm]',
  exportAs: 'scrollToErrorForm',
  providers: [
    { provide: Window, useValue: window }
  ]
})
export class ScrollToErrorFormDirective {

  constructor(private el: ElementRef,
    private window: Window) { }

  @HostListener('submit')
  onSubmit(event: any) {
    let firstInvalid = this.el.nativeElement.querySelector('.ng-invalid');
    if (firstInvalid) {
      //window.scroll(0, 0);
      firstInvalid.scrollIntoView({ behavior: 'smooth' });
      firstInvalid.focus();
    }
  }
}