import { Input, ViewChild } from "@angular/core";
import { OnInit } from "@angular/core";
import { Component } from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import filter from "lodash-es/filter";
import trim from "lodash-es/trim";
import { DocumentInfo } from "src/app/shared/components/file-upload/file-upload.interface";
import { WorkHoursPipe } from "src/app/shared/pipes/work-hours.pipe";
import { ReferenceDataService } from "src/app/shared/services/reference-data.service";
import { DnErrorControlDirective } from "../../dn-error-display/directives/dn-error-control.directive";
import { Affiliation, Location, EditedLocation } from "../directory.interface";
import { LanguagesComponent } from "../languages/languages.component";
import {DirectoryService} from "../services/directory.service";

import { Subscription } from "rxjs";
import { OfficeHoursComponent } from "src/app/shared/components/officeHours/office-hours.component";

@Component({
  selector: 'locationEdit',
  styles: [
    '.modal-header { border-bottom: 0 none;  }',
    '.modal-footer { border-top: 0 none; }'
  ],
  templateUrl: "location-edit-modal.component.html"
})
export class LocationEditModalComponent implements OnInit {
  @Input() affilation !: Affiliation;
  @Input() location !: EditedLocation;
  @Input() uploadedFiles !: DocumentInfo[];
  @Input() directoryStateKey !: string;
  @Input() token !: any;

  @ViewChild(OfficeHoursComponent)  public officeHoursComponent!: OfficeHoursComponent;
  @ViewChild('errorControl', { static: false }) _errorControl !: DnErrorControlDirective;
  @ViewChild('locationLanguages', { static: false, read: LanguagesComponent }) languages !: LanguagesComponent;
  public isCollapsed = true;
  private _checkHandicap = false;
  public officeHours!: any;
  public officeHoursStr: any = "";
  public originalOfficeHrsStr: string = "";
  public allStates: any;
  private regex = /[&\/\\#|+()$~%.'":*?<>{}^]/g;
  public maxEditReached: boolean = false;

  private saveEventSubscription !: Subscription;
  private cancelEventSubscription !: Subscription;
  public editOfficeHours = true;
  public updateOriginalHours: boolean = false;


  constructor(public activeModal: NgbActiveModal,
              private refService: ReferenceDataService,
              private directoryService: DirectoryService) { };

  ngOnInit(): void {
    this.getWorkHours();
    this.refService.getStates().subscribe(data => {
      this.allStates = data;
    });

  }

  getWorkHours() {
    if (Array.isArray(this.location.workHours) && this.location.workHours.length > 0) {
      this.officeHours = new WorkHoursPipe().transform(this.location.workHours);
      if (this.officeHours) {
        this.officeHours.forEach((value: string, key: string) => {
          if (key) {
            this.officeHoursStr += value + ": " + key + "\n";
          }
        });
      }
    } else {
      this.officeHoursStr = this.location.workHours;
    }
    if(! this.updateOriginalHours){
      this.originalOfficeHrsStr = this.officeHoursStr;
    }

  }

  public set checkHandicap(val: boolean) {
    this.location.handicapAccessible = val == true ? 'Yes' : 'No';
  }

  public get checkHandicap() {
    return this.location.handicapAccessible == 'Yes';
  }

  saveEdits(e: any, form: NgForm) {
    this._errorControl.showErrors();
    form.control.updateValueAndValidity();
    if (form.valid) {
      let otherLangs = filter(this.languages.otherLanguages?.split(this.regex), (e) => { return e != '' });
      //this.location.otherLanguages = otherLangs ? otherLangs.join(", ") : null;
      this.location.officeLanguages = this.languages.selectedLanguages;
      if (!trim(this.officeHoursStr)) {
        this.officeHoursStr = [];
      }
       if (this.isEditedOfficeHours()) {
      //   this.location.workHours = this.officeHoursStr;
         this.location.officeHours = this.officeHoursStr?.length > 0 ? this.officeHoursStr : "";
       }
      this.location.officeLanguages = this.languages.selectedLanguages;
      this.activeModal.close(
        {
          "editedLocation": this.location,
          "originalOfficeHrs": this.originalOfficeHrsStr,
          documents: this.uploadedFiles
        }
      );
    }
    return;

  }

  getLanguages() {
    return {
      languages: this.location.officeLanguages ? this.location.officeLanguages : [],
      otherLanguages: this.location.otherLanguages
    }
  }

  asIsOrder(a: any, b: any) {
    return 1;
  }

  cancelEdits(e: any, form: NgForm) {
    this._errorControl.hideErrors();
    this.activeModal.dismiss(
      {
        documents: this.uploadedFiles
      }
    );
    return;
  }

  private isEditedOfficeHours() {
    return this.originalOfficeHrsStr !== this.officeHoursStr;
  }

  setOfficeHours(e: Event) {
    this.officeHoursStr = e;
  }

  isMaxEditReached(maxEditReached: boolean) {
    this.maxEditReached = maxEditReached;
  }

  public disableContinue() {
    let changed = true;
    if (this.maxEditReached) {
      changed = this.directoryService.isLocationChanged(this.directoryStateKey, this.location.locationId);
    }
    return !changed;
  }

  ngAfterViewInit(): void {
    this.saveEventSubscription = this.officeHoursComponent.onSaveOfficeHours.subscribe((workHours: any) => {
      if (workHours) {
        this.officeHoursStr = "";
        this.location.workHours = workHours;
        this.updateOriginalHours = true;
        this.getWorkHours();
        this.editOfficeHours = true;
      }
    });

    this.cancelEventSubscription = this.officeHoursComponent.onCancelOfficeHours.subscribe((cancel: any) => {
      if(cancel){
        this.editOfficeHours = true;
      }
    });

  }

  ngOnDestroy(): void {
    this.saveEventSubscription.unsubscribe();
    this.cancelEventSubscription.unsubscribe();
  }


}
