import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppRoutingModule, initializeAppFactory } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { DirectoryModule } from './directory/directory.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TestComponent } from './test/test/test.component';
import { CoreModule } from './core/core.module';
import { GlobalErrorHandler } from './core/errors/global-error-handler';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ProviderHttpInterceptor } from './core/errors/provider-http-interceptor';
import { HttpSessionHandler } from './core/errors/http-session-handler';
import { environment } from '../environments/environment';
import { DnErrorDisplayModule } from './dn-error-display/dn-error-display.module';
import { DnErrorMessageService } from './dn-error-display/dn-error-message.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { YmlLoaderService } from './shared/yml-loader.service';
import {JoyrideModule} from "ngx-joyride";
import { AuthHeaderInterceptor } from './core/services/AuthHeaderInterceptor';
import { Router, ROUTES } from '@angular/router';
 

@NgModule({
  declarations: [
    AppComponent,
    TestComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    DirectoryModule,
    NgbModule,
    CoreModule,
    DnErrorDisplayModule,
    JoyrideModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useExisting: YmlLoaderService
      },
      defaultLanguage: 'en'
    })
  ],
  providers: [
    {provide: APP_INITIALIZER, useFactory: initializeAppFactory , multi: true, deps: [HttpClient, Router]},
    ProviderHttpInterceptor,
    { provide: HTTP_INTERCEPTORS, useClass: HttpSessionHandler, multi: true },
    { provide: HTTP_INTERCEPTORS, useExisting: ProviderHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHeaderInterceptor, multi: true },    
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private errorMessageService: DnErrorMessageService) {
    this.errorMessageService.errors(environment.common.errorMessages)
  }
}
