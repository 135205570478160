<div class="modal-header">
    <div class="align-top">
      <h3 class="modal-title"><i class="fa-solid fa-triangle-exclamation fa-lg text-warning"></i>
        {{'headers.attestation-warning' | translate}}</h3>
    </div>
  </div>
  <div class="modal-body align-middle">
    <span *ngIf="!isFilteredAlert">{{'content.invalid-directory-submit' | translate}}</span>
    <span *ngIf="isFilteredAlert">{{'content.isfiltered-directory-submit' | translate}}</span>
  </div>
  <div class="modal-footer">
    <div class="d-grid gap-2 d-md-flex justify-content-md-end">      
      <button type="button" [autofocus]="true" class="btn btn-primary" (click)="ok()">OK</button>
    </div>
  </div>