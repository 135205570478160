import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DnErrorDisplayModule } from '../dn-error-display/dn-error-display.module';
import { SharedModule } from '../shared/shared.module';
import { DnpHeaderComponent } from './dnp-header/dnp-header.component';
import { GlobalErrorComponent } from './errors/global-error/global-error.component';
import { DnpFooterComponent } from './dnp-footer/dnp-footer.component';
import { RouterModule } from '@angular/router';
import { DnpHomeComponent } from './dnp-home/dnp-home.component';
import { DirectoryModule } from '../directory/directory.module';
import { LegalAndPrivacyComponent } from './legal-and-privacy/legal-and-privacy.component';


@NgModule({
  declarations: [
    DnpHeaderComponent, GlobalErrorComponent, DnpFooterComponent, DnpHomeComponent, LegalAndPrivacyComponent
  ],
  exports: [
    DnpHeaderComponent,
    GlobalErrorComponent,
    DnpFooterComponent,
    DnpHomeComponent
  ],
  imports: [
    CommonModule,
    DnErrorDisplayModule,
    SharedModule,
    RouterModule,
    DirectoryModule
  ]
})
export class CoreModule { }
