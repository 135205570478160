import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import {
  Affiliation,
  DIRECTORY_TYPE,
  DirectoryData,
  DirectorySearchCriteria,
  EditedAffiliation,
  EditedLocation,
  EditedProvider,
  Location,
  Provider,
  ReviewedAffiliation,
  CUSTOM_KEY,
  LocationManager,
  Directory, ProviderEmailChangeRequest
} from '../directory.interface';
import { DirectoryService } from '../services/directory.service';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { BoardStatus, Pageable } from 'src/app/shared/shared.interface';
import map from 'lodash-es/map';
import sortBy from 'lodash-es/sortBy';
import filter from 'lodash-es/filter';
import { ReferenceDataService } from 'src/app/shared/services/reference-data.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UploadModalComponent } from './upload-modal.component';
import { LocationEditModalComponent } from '../location-edit-modal/location-edit-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import isEqual from 'lodash-es/isEqual';
import findIndex from 'lodash-es/findIndex';
import { ProviderEditModalComponent } from '../provider-edit-modal/provider-edit-modal.component';
import cloneDeep from 'lodash-es/cloneDeep';
import find from 'lodash-es/find';
import { ConfirmResetInfoModalComponent } from "../confirm-reset-info-modal/confirm-reset-info-modal.component";
import { ValidateAffiliationModalComponent } from '../validate-affiliation-modal/validate-affiliation-modal.component';

import each from "lodash-es/each";
import { Table } from "primeng/table/table";
import { ConfirmSubmitWarningModalComponent } from '../confirm-submit-warning-modal/confirm-submit-warning-modal.component';
import { AttestAndSubmitModalComponent } from '../attest-and-submit-modal/attest-and-submit-modal.component';
import { Observable, Subscription } from 'rxjs';
import isEmpty from 'lodash-es/isEmpty';
import { PersistanceService } from "../../shared/services/persistance.service";
import { EventsService } from 'src/app/shared/services/events.service';
import { TitleCasePipe, ViewportScroller } from "@angular/common";
import { JoyrideService } from 'ngx-joyride';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { LocationManagerModalComponent } from '../location-manager-modal/location-manager-modal.component';
import { DirectoryBaseComponent } from '../directory-base/directory-base.component';
import { LocationInfoComponent } from '../location-info/location-info.component';

@Component({
  selector: 'directory-list',
  templateUrl: './directory-list.component.html',
  styleUrls: ['./directory-list.component.css', './directory-list-joyride.component.css']
})
export class DirectoryListComponent extends DirectoryBaseComponent implements OnInit{
  @ViewChild('directoryTable') directoryTable!: Table;
  @ViewChild('btnSubmitRef') btnSubmitRef!: ElementRef<HTMLDivElement>;

  private rowGroupParent !: Affiliation;
  private clonedAffiliations: { [s: number]: Affiliation } = {};
  private today !: Date;
  private showTour !: false;
  private tourKey !: string;

  isSubmitting = false;
  boardNames: string[] = [];
  boardCertification !: string;
  multiSortMeta = [{ field: 'officeName', order: 1 },
  { field: 'locationHash', order: 1 },
  { field: 'providerFullname', order: 1 }
    // { field: 'providerId', order: 1 }
  ];
  sortFields = map(this.multiSortMeta, 'field');
  _options !: SelectItem[];
  lazyParams = {
    toggleRowBg: false,
    totalRecords: 0,
    loading: true,
    pageNo: 0,
    size: 100
  }
  modalClosed: boolean = false;
  initialPage: number = 0;
  editingAffiliationId = -1;
  requiredFields: any = [];
  locReqFields: string[] = [];
  provReqFields: string[] = [];
  affReqFields: string[] = [];
  pracReqFields: string[] = [];
  isSelectAll: boolean = false;
  isFirstTimeError: boolean = true;
  myDiv!: any;

  attestationDueDays: number = 0;
  affiliationsHavingUnValidDataCnt: number = 0;

  public isTelehealth: any[] = [
    {value: "Yes", text: "Yes"},
    {value: "No", text: "No"}
  ];

  MAXNUMBEROFEDITS = 0;
  public maxEditReached: boolean = false;
  private rowIndex: any = null;
  tableHeadClassTop: number = 56;
  directoryClassBottom: number = 165;

  btnSubmitId="btnSubmit";

  customPageReportTemplate = ''; //Access Point Counts to Top of Directory

  constructor(private dir: DirectoryService,
    private referenceDataService: ReferenceDataService,
    private modlService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private persistanceService: PersistanceService,
    private eventService: EventsService,
    private viewportScroller: ViewportScroller,
    private readonly joyrideService: JoyrideService,
    private element: ElementRef,
    private renderer: Renderer2
  ) {
    super();
    this.directoryService = dir;
    this.route = activatedRoute;
    this.modalService = modlService;
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(e: any) {
    if (this.joyrideService.isTourInProgress()) {
      this.joyrideService.closeTour();
    }
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this._options = [{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }];
    this.referenceDataService.getBoardNames().subscribe((boardNames) => {
      this.boardNames = boardNames;
    });
    this.today = new Date();
    this.today.setHours(0, 0, 0, 0);
    this.referenceDataService.getRequiredFields().subscribe((data) => {
      this.locReqFields = data?.locationReqFields?.split(",");
      this.provReqFields = data?.providerReqFields?.split(",");
      this.affReqFields = data?.affiliationReqFields?.split(",");
      this.pracReqFields = data?.practiceReqFields?.split(",");
    });
    this.referenceDataService.getAttestationDueDays().subscribe(resp => {
      this.attestationDueDays = resp;
    });
    if (this.directoryStateKey) {
      this.directoryService.getTotalUnvalidatedAffiliations().subscribe((data) => {
        this.affiliationsHavingUnValidDataCnt = data;
      });
      this.tourKey = this.directoryService.getDirectoryItemKey(this.directoryStateKey, CUSTOM_KEY.TOUR);
      let tourStatus = this.persistanceService.getDirectoryItemByKey(this.tourKey)
      this.showTour = tourStatus === undefined || tourStatus == null ? true : tourStatus.status;
    }
    this.referenceDataService.getMaxNumberEdits().subscribe((resp) => {
      this.MAXNUMBEROFEDITS = resp;
      //console.log("max edit number: " + this.MAXNUMBEROFEDITS);
    });
  }

  getSummary() {
    return { "Validated": this.affiliations.filter(aff => aff?.isValid === true).length };
  }

  getLocationInfo(practiceId: string, locationId: string) {
    let location: Location = JSON.parse(JSON.stringify(this.locations.find(location => location.locationId === locationId)));
    return location;

  }

  getLocationState(locationId: string) {
    let location = this.locations.find(location => location.locationId === locationId);
    return location ? location.state : "";
  }

  getLocationWorkHours(locationId: string) {
    let location = this.locations.find(location => location.locationId === locationId);
    return location ? location.workHours : "";
  }


  getProvider(affiliation: Affiliation) {
    let providerId: string = affiliation.providerId;
    let locationId: string = affiliation.locationId;
    let provider: any = JSON.parse(JSON.stringify(this.providers.find(provider => provider.providerId === providerId)));
    if (provider) {
      let license = find(provider.licenses, (l) => l.licensedState === this.getLocationState(locationId));
      provider.licenses = [];
      if (license) {
        provider.licenses.push(license);
      }
    }
    return provider;
  }

  onRowEditInit(affiliation: Affiliation, index: number) {
    if(this.rowIndex != null){
      let oldEditedAffiliation = this.affiliations[this.rowIndex];
      this.onRowEditCancel(oldEditedAffiliation, this.rowIndex);
    }
    this.rowIndex = index;
    this.editingAffiliationId = affiliation.affiliationId;
    this.clonedAffiliations[affiliation.affiliationId] = { ...affiliation };
  }

  onRowEditSave(affiliation: Affiliation, index: number) {
    //delete this.clonedAffiliations[affiliation.affiliationId];
    this.onRowEdit();
    let provider = find(this.providers, (p) => p.providerId === affiliation.providerId);
    let location = find(this.locations, (l) => l.locationId === affiliation.locationId);
    let originalAff = this.clonedAffiliations[affiliation.affiliationId] as EditedAffiliation;
    originalAff.npi = provider ? provider.npi : "";
    originalAff.taxId = location ? location.taxId : "";
    this.saveDirectoryItem(DIRECTORY_TYPE.AFFILIATION, affiliation, originalAff);
    this.rowIndex = null;
  }

  onRowEdit() {
    this.editingAffiliationId = -1;
    this.isMaxNumberOfEditReached();
  }

  trackBy(index: number, affiliation: Affiliation) {
    return affiliation.affiliationId;
  }

  onEvent(event: any) {
    event.stopPropagation();
  }

  onRowEditCancel(affiliation: Affiliation, index: number) {
    this.editingAffiliationId = -1;
    this.affiliations[index] = this.clonedAffiliations[affiliation.affiliationId];
    this.affiliations = [...this.affiliations]
    this.rowIndex = null;
  }

  groupRowsBy(data: Affiliation) {
    return data.locationId + data.providerId;
  }

  isDatePastDue(dueDate: Date) {
    let date = new Date(dueDate);
    date.setHours(0, 0, 0, 0);
    return date.getTime() < this.today.getTime();
  }

  toggleRowBackground(rowGroup: any, rowIndex: number) {
    if (rowGroup) {
      this.lazyParams.toggleRowBg = rowIndex === 0 ? false : !this.lazyParams.toggleRowBg;
    }
    return this.lazyParams.toggleRowBg;
  }

  shouldShowRowGroup(rowGroup: any, rowdata: Affiliation, rowindex: number) {
    let showRowGroup = rowindex === (this.lazyParams.pageNo * this.lazyParams.size) || this.rowGroupParent.locationId !== rowdata.locationId
    if (showRowGroup) {
      this.rowGroupParent = rowdata;
    }
    return showRowGroup;
  }

  calculateRowSpan(rowdata: Affiliation) {
    let locGroup = sortBy(filter(this.affiliations, (aff) => {
      return aff.locationId === rowdata.locationId;
    }), this.sortFields as any);
    return locGroup.length === 1 || (locGroup[0].affiliationId !== rowdata.affiliationId) ? null : locGroup.length;
  }

  filterDirectoryGrid(searchCriteria: DirectorySearchCriteria) {
    if (!searchCriteria) {
      searchCriteria = new DirectorySearchCriteria();
    }    
    let event: LazyLoadEvent = {
      first: 0
    }
    this.initialPage = 0;
    this.loadDirectory(event, searchCriteria);
  }

  loadDirectory(event?: LazyLoadEvent, searchCriteria?: DirectorySearchCriteria) {
    let pageSize = event?.rows ? event?.rows : this.lazyParams.size
    const pageable: Pageable = {
      page: (event?.first ? event?.first : 0) / pageSize,
      size: pageSize
    };
    this.eventService.broadcast('onPagination');
    if (pageSize != this.lazyParams.size) {
      this.eventService.broadcast('rowSizeChanged');
    }
    if (searchCriteria) {
      this.affiliations = [];
      this.locations = [];
      this.providers = [];
    }
    this.lazyParams.loading = true;
    //this.directoryService.getDirectory(this.directoryStateKey, this.pageable).subscribe( (data) => {
    this.directoryService.testDirectory(pageable, this.email, searchCriteria).subscribe((data) => {
      this.populateDirectoryTable(data, pageable);
      setTimeout(() => {
        this.hostElement.querySelectorAll('.p-datatable-wrapper')[0].setAttribute("style","overflow: visible");
      }, (100));

      if (this.showTour && this.lazyParams.totalRecords > 0 && this.lazyParams.pageNo === 0) {

        this.joyrideService.startTour(
          {
            steps: ['explainValidStep'],
            stepDefaultPosition: 'top',
            showCounter: true
          }
        )
      }
    });
  }

  onDone() {
    this.showTour = false;
    this.persistanceService.set(this.tourKey, { status: false });
  }

  private populateDirectoryTable(data: DirectoryData, pageable: Pageable) {
    if (data) {
      this.lazyParams.totalRecords = data.totalItems;
      this.lazyParams.pageNo = pageable.page;
      this.lazyParams.size = pageable.size;
      const first = data.totalItems < 1 ? 0 : this.directoryTable.first + 1;
      const last = this.directoryTable.first + data.directory.affiliations.length;
      this.customPageReportTemplate = `Showing ${first} to ${last} of ${data.totalItems} access points`;
      if (data.directory) {
        data.directory.affiliations = sortBy(data.directory.affiliations, this.sortFields as any);
        this.affiliations = data.directory.affiliations;
        this.locations = data.directory.locations;
        this.providers = data.directory.providers;
      }
    }
    this.lazyParams.loading = false;
    setTimeout(() => {
      this.restoreChanged();
      this.validateAllAffiliations();
    });
  }

  openUploadModal(affiliation: Affiliation) {
    this.modalClosed = false;
    const modalRef = this.modalService.open(UploadModalComponent, { size: 'xl', centered: true });
    modalRef.componentInstance.affiliation = affiliation;
    modalRef.result.then((result) => {
      this.modalClosed = true;
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      this.modalClosed = true;
      console.log(`${reason}`);
    })
  }

  openLocationModal(affiliation: Affiliation) {
    let locationIndex = findIndex(this.locations, (location) => location.locationId === affiliation.locationId);
    if (locationIndex > -1) {
      const modalRef = this.modalService.open(LocationEditModalComponent, { size: 'xl', centered: true, backdrop: 'static', keyboard: false });
      modalRef.componentInstance.affilation = cloneDeep(affiliation);
      modalRef.componentInstance.location = cloneDeep(this.locations[locationIndex]) as EditedLocation;
      let documents = this.directoryService.getDocumentsFromStorage(this.directoryStateKey);
      modalRef.componentInstance.uploadedFiles = cloneDeep(documents);
      modalRef.componentInstance.directoryStateKey = this.directoryStateKey;
      modalRef.componentInstance.token = this.email; //Token for upload document's API

      modalRef.result.then((result) => {
        if (result.editedLocation) {
          let isSame = isEqual(this.locations[locationIndex], result.editedLocation);
          if (!isSame) {
            let originalLocation = cloneDeep(this.locations[locationIndex]) as EditedLocation;
            originalLocation.officeHours = result.editedLocation.officeHours ? result.originalOfficeHrs : "";
            this.saveDirectoryItem(DIRECTORY_TYPE.LOCATION, result.editedLocation, originalLocation);
            this.locations[locationIndex] = result.editedLocation;
            this.validateAllAffiliations();
          }
          this.isChanged(documents, result.documents);
        }

        if (result.editedPractice) {
          this.isChanged(documents, result.documents);
        }
        this.isMaxNumberOfEditReached();
      }, (reason) => {
        this.isChanged(documents, reason.documents);
        this.isMaxNumberOfEditReached();
      })
    }
  }   
  
  openProviderModal(affiliation: Affiliation) {
    let providerIndex = findIndex(this.providers, (provider) => provider.providerId === affiliation.providerId);
    let locationIndex = findIndex(this.locations, (location) => location.locationId === affiliation.locationId);
    if (providerIndex > -1) {
      const modalRef = this.modalService.open(ProviderEditModalComponent, { size: 'xl', centered: true, backdrop: 'static', keyboard: false });
      modalRef.componentInstance.provider = cloneDeep(this.providers[providerIndex]);
      modalRef.componentInstance.location = cloneDeep(this.locations[locationIndex]);      
      let documents = this.directoryService.getDocumentsFromStorage(this.directoryStateKey);
      modalRef.componentInstance.uploadedFiles = cloneDeep(documents);
      modalRef.componentInstance.directoryStateKey = this.directoryStateKey;
      modalRef.componentInstance.boardNameList = this.boardNames;
      modalRef.componentInstance.token = this.email; //Token for upload document's API

      modalRef.result.then((data: any) => {
        let isSame = isEqual(this.providers[providerIndex], data.provider);
        if (!isSame) {
          let originalProvider = this.providers[providerIndex] as EditedProvider;
          originalProvider.practiceId = affiliation.practiceId;
          //data.provider.practiceId =  affiliation.practiceId;
          this.saveDirectoryItem(DIRECTORY_TYPE.PROVIDER, data.provider, originalProvider);
          this.providers[providerIndex] = data.provider;
        }
        this.isChanged(documents, data.documents);
        this.isMaxNumberOfEditReached();
      }, (reason) => {
        this.isChanged(documents, reason.documents);
        this.isMaxNumberOfEditReached();
      });
    }
  }


  private restoreChanged() {
    this.directoryService.restoreEdits(this.directoryStateKey, DIRECTORY_TYPE.PROVIDER, this.providers);
    this.directoryService.restoreEdits(this.directoryStateKey, DIRECTORY_TYPE.LOCATION, this.locations);
    this.directoryService.restoreEdits(this.directoryStateKey, DIRECTORY_TYPE.AFFILIATION, this.affiliations);
    //this.directoryService.restoreEdits(this.directoryStateKey, DIRECTORY_TYPE.DOCUMENTS, this.documents);
  }

  private saveDirectoryItem(type: DIRECTORY_TYPE, changed: Object, original: Object) {
    if (!this.directoryService.handleRevertedChange(this.directoryStateKey, type, changed)) {
      this.directoryService.saveDirectoryItem(this.directoryStateKey, type, changed, original);
    }
  }

  private resetPageData() {
    this.directoryService.clearDirectoryCache();
    this.directoryService.deleteFromStorageByDirectoryItemKey(this.directoryStateKey);
    this.initialPage = 0;
    this.persistanceService.setSessionItem("showDisclaimer", false);
    this.isFirstTimeError = true;
    location.reload();
  }

  public resetScrollToTop() {
    this.directoryTable.resetScrollTop();
    window.scrollTo(0, 0);
  }

  public openConfirmResetInfoModal() {
    const modalRef = this.modalService.open(ConfirmResetInfoModalComponent, { size: 'md', centered: true });
    modalRef.result.then((result) => {
      if (result) {
        this.resetPageData();
      }
    })
  }

  submitValidations(affiliations: any[], isFiltered: boolean) {
    if (isFiltered) {
      const modalRef = this.modalService.open(AlertModalComponent, { size: 'md', centered: true, backdrop: 'static', keyboard: false });
      modalRef.componentInstance.isFilteredAlert = true;
    }
    else {
      let visitedAffiliations: number[] = this.persistanceService.getDirectoryItemByKey(this.directoryService.getDirectoryItemKey(this.directoryStateKey, DIRECTORY_TYPE.VISITEDAFFILIATIONS));
      if (this.lazyParams.totalRecords > visitedAffiliations.length) { //on refresh total number could change if provider/practice email is changed
        const modalRef = this.modalService.open(AlertModalComponent, { size: 'md', centered: true, backdrop: 'static', keyboard: false });
      }
      else {
        let subscription: Subscription;
        subscription = this.shouldSubmitValidations(affiliations).subscribe((continueSubmit) => {
          this.closeSubscription(subscription);
          if (continueSubmit) {
            subscription = this.attestValidations().subscribe((attested) => {
              this.closeSubscription(subscription);
              if (!isEmpty(attested)) {
                this.isSubmitting = true;
                subscription = this.directoryService.sendChangeRequests(this.directoryStateKey, attested).subscribe((submitted) => {
                  this.isSubmitting = false;
                  this.closeSubscription(subscription);
                  if (submitted?.status) {
                    this.directoryService.deleteFromStorageByDirectoryItemKey(this.directoryStateKey);
                    this.directoryService.clearFromStorageByDirectoryItemKey(this.tourKey);
                    this.directoryService.clearDirectoryCache();
                    let navbarClass = this.hostElement.parentNode?.parentElement?.parentNode?.querySelectorAll('.navbar')[0];
                    this.renderer.setStyle(navbarClass, "display", "block");
                    this.router.navigate(['confirmation'], {
                      relativeTo: this.route, state: {
                        'token': this.email,
                        'confirmationNumber': submitted.confirmationNumber,
                        'isDirChanged': submitted.isDirChanged
                      }
                    }).then((e) => {
                    }, (err) => {
                      console.log(err);
                    });
                  }
                });
              }
            });
          }
        });
      }
    }
  }

  private closeSubscription(subscription: Subscription) {
    if (subscription) {
      subscription.unsubscribe();
    }
  }

  private attestValidations(): Observable<{}> {
    return new Observable((observer) => {
      try {
        const modalRef = this.modalService.open(AttestAndSubmitModalComponent, { size: 'xl', centered: true });
        let documents = this.directoryService.getDocumentsFromStorage(this.directoryStateKey);
        modalRef.componentInstance.uploadedFiles = cloneDeep(documents);
        modalRef.componentInstance.token = this.email; //Token for upload document's API
        modalRef.result.then((result) => {
          this.modalClosed = true;
          this.isChanged(documents, result.documents);
          observer.next(result);
        }, (reason) => {
          this.isChanged(documents, reason.documents);
          this.modalClosed = true;
          observer.next({});
        })
      } catch (err) {
        observer.error(err);
      }
    });
  }

  private shouldSubmitValidations(affiliations: any[]): Observable<boolean> {
    let affilationUnChecked = false;
    let checkedAffiliationHavingUnValidDataCnt = this.directoryService.getCheckedAffiliationsHavingUnValidDataCount(this.directoryStateKey, DIRECTORY_TYPE.VALIDATION, this.attestationDueDays, this.today);
    if (affiliations) {
      each(affiliations, (affiliation) => {
        if (!affiliation.isValid) {
          affilationUnChecked = true;
        }
      });
    }

    return new Observable((observer) => {
      try {
        if (affilationUnChecked && checkedAffiliationHavingUnValidDataCnt > 0) {
          const modalRef = this.modalService.open(ConfirmSubmitWarningModalComponent, { size: 'lg', centered: true });
          modalRef.componentInstance.affiliationsHavingUnValidDataCnt = checkedAffiliationHavingUnValidDataCnt;
          modalRef.result.then((result) => {
            this.modalClosed = true;
            observer.next(result);
          }, (reason) => {
            this.modalClosed = true;
            observer.next(false);
          })
        } else {
          observer.next(true);
        }
      } catch (error) {
        observer.error(error);
      }
    });
  }

  private validateAffiliation(affiliation: Affiliation) {

    this.requiredFields = [];

    let locationIndex = findIndex(this.locations, (location) => location.locationId === affiliation.locationId);
    let provider = this.getProvider(affiliation);
    let location = this.locations[locationIndex];
    let contactStr: String = "At least one of Phone Number, Fax Number, or Office Email Address";

    this.directoryService.getAPNullRequiredFields(provider, this.provReqFields, DIRECTORY_TYPE.PROVIDER, this.requiredFields);
    this.directoryService.getAPNullRequiredFields(location, (this.locReqFields.concat(this.pracReqFields)), DIRECTORY_TYPE.LOCATION, this.requiredFields);
    this.requiredFields = this.directoryService.getAPNullRequiredFields(affiliation, this.affReqFields, DIRECTORY_TYPE.AFFILIATION, this.requiredFields);

    if (this.requiredFields?.location?.length > 0) {
      let myStr: String = "";
      each(this.requiredFields.location, (ol) => {
        myStr = myStr + this.changeToUpperCase(ol);
      });
      this.requiredFields.location = myStr;
    }
    if(this.locReqFields?.includes("contact")){

      if (
        //(this.requiredFields?.location == null || this.requiredFields?.location?.length == 0) &&
        (location.officePhone?.length == 0 || location.officePhone == null) &&
        (location.officeEmail?.length == 0 || location.officeEmail == null) &&
        (location.officeFax?.length == 0 || location.officeFax == null)) {
        this.requiredFields.location = this.requiredFields.location ? this.requiredFields.location + ", " + contactStr : contactStr ;
      }
   }

    if (this.requiredFields?.provider?.length > 0) {
      let myStr: String = "";
      each(this.requiredFields.provider, (ol) => {
        myStr = myStr + this.changeToUpperCase(ol);
      });
      this.requiredFields.provider = myStr;
    }
    if (provider.licenses.length <= 0) {
      this.requiredFields.provider = this.requiredFields.provider ? this.requiredFields.provider +", " + ["License Number"] : ["License Number"];
    }


  }

  showRequiredFieldsWarning(e: any, affiliation: Affiliation, hideWarning: boolean = false) {
    if (e.target.checked) {
      this.validateAffiliation(affiliation);
      if (this.requiredFields.provider?.length > 0 || this.requiredFields.location?.length > 0 || this.requiredFields.affilation?.length > 0 || this.requiredFields.practice?.length > 0) {
        affiliation.isValid = false;
        this.saveInValidAffiliation(affiliation); //save
        if (!hideWarning) {
          e.target.checked = false;
          this.validateAPPopup();
          return true;
        }
      } else {
        affiliation.isValid = true;
        e.target.checked = true;
        this.saveInValidAffiliation(affiliation, true); //Delete
        return;
      }
    } else {
      affiliation.isValid = false;
      e.target.checked = false;
      this.saveInValidAffiliation(affiliation); //save
      return;
    }
    return false;
  }

  validateAllAffiliations() { // Check all valid checkbox by default
    const storedInValidData = this.persistanceService.getDirectoryItem(this.directoryService.getDirectoryItemKey(this.directoryStateKey, DIRECTORY_TYPE.VALIDATION));
    this.affiliations.forEach(affiliation => {
      this.validateAffiliation(affiliation);
      if (this.requiredFields.provider?.length > 0 || this.requiredFields?.location?.length > 0 || this.requiredFields.affilation?.length > 0 || this.requiredFields.practice?.length > 0) {
        affiliation.isValid = false;
        this.saveInValidAffiliation(affiliation); //save
      } else {
        affiliation.isValid = true;
      }
      if (storedInValidData.changed != null) {
        let matchFound = storedInValidData.changed.find((x: { affiliationId: number; }) => x.affiliationId === affiliation.affiliationId);
        if (matchFound)
          affiliation.isValid = false;
      }

      let temp = this.persistanceService.getDirectoryItemByKey(this.directoryService.getDirectoryItemKey(this.directoryStateKey, DIRECTORY_TYPE.VISITEDAFFILIATIONS));
      let visitedAffiliations: number[] = temp != null ? temp : [];
      if (!visitedAffiliations.some(x => x === affiliation.affiliationId)) {
        visitedAffiliations.push(affiliation.affiliationId);
      }
      this.persistanceService.set(this.directoryService.getDirectoryItemKey(this.directoryStateKey, DIRECTORY_TYPE.VISITEDAFFILIATIONS), visitedAffiliations);
    });
  }

  saveInValidAffiliation(affiliation: Affiliation, isDelete: boolean = false) {
    let inValidAffiliation = new ReviewedAffiliation();
    inValidAffiliation.accessPointId = affiliation.accessPointId;
    inValidAffiliation.affiliationId = affiliation.affiliationId;
    inValidAffiliation.dueDate = affiliation.dueDate;
    if (isDelete)
      this.directoryService.removeDirectoryItem(this.directoryStateKey, DIRECTORY_TYPE.VALIDATION, inValidAffiliation);
    else
      this.saveDirectoryItem(DIRECTORY_TYPE.VALIDATION, inValidAffiliation, {});
  }

  validateAPPopup() {
    const modalRef = this.modalService.open(ValidateAffiliationModalComponent, { size: 'md', centered: true });
    modalRef.componentInstance.requiredFields = this.requiredFields;
    modalRef.componentInstance.isSelectAll = this.isSelectAll;

    modalRef.result.then((result) => {
      this.modalClosed = true;
    }, (reason) => {
      this.modalClosed = true;
    })

  }

  showOfficeManagerIconForPractice(showIcon:any,affiliation: Affiliation) {
    setTimeout(()=>{
      if (affiliation.locationId===showIcon.locationId && 
        affiliation.practiceId === showIcon.practiceId &&
        affiliation.providerId === showIcon.providerId ) {
        affiliation.showOfficeManagerIcon = showIcon.show;         
      }else{
        affiliation.showOfficeManagerIcon = false;
      }
    });

   
  }

  private isChanged(originalDocs: any, changedDocs: any) {
    let isSame = isEqual(originalDocs, changedDocs);
    if (!isSame) {
      this.saveDirectoryItem(DIRECTORY_TYPE.DOCUMENTS, { directoryUploads: changedDocs }, {});
    }
  }

  private isMaxNumberOfEditReached() {
    if (this.MAXNUMBEROFEDITS == 0) {
      this.referenceDataService.getMaxNumberEdits().subscribe((resp) => {
        this.MAXNUMBEROFEDITS = resp;
      });
    } else {
      let totalEdits = this.directoryService.getTotalNumberOfEdits(this.directoryStateKey);
      if (totalEdits >= this.MAXNUMBEROFEDITS) {
        this.maxEditReached = true;
        this.viewportScroller.scrollToPosition([1, this.getMaxEditWarnScrollY()]);
      } else {
        this.maxEditReached = false;
      }
    }
  }
  private getMaxEditWarnScrollY() {
    let maxEditWarnElem = document.getElementById('maxEditWarnDiv');
    let maxEditWarnElemY = maxEditWarnElem ? this.getOffset(maxEditWarnElem).top : 0;
    let footerElem = document.getElementById('footer');
    let footerHeight = footerElem ? footerElem.offsetHeight : 0;
    let offSetY = maxEditWarnElemY - footerHeight;
    return offSetY;
  }
  private getOffset(el: Element) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY
    };
  }
  public disableAcceptNewPatient(affiliationId: number) {

    let changed = true;
    if (this.maxEditReached) {
      changed = this.directoryService.isAcceptPatientChanged(this.directoryStateKey, affiliationId);
    }
    return !changed;
  }

  private get hostElement(): HTMLElement {
    return this.element.nativeElement;
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event: Event) {
    const theadClass = this.hostElement.querySelectorAll('.p-datatable-thead')[0];
    const directoryClass =  this.hostElement.querySelectorAll('.directory')[0];

    if (theadClass != null) {
      const recttheadClass = theadClass.getBoundingClientRect();
      const rectdirectoryClass = directoryClass.getBoundingClientRect();
      let navbarClass = this.hostElement.parentNode?.parentElement?.parentNode?.querySelectorAll('.navbar')[0];
      if (recttheadClass.top <= this.tableHeadClassTop) {
        this.renderer.setStyle(navbarClass, "display", "none")
      }
      else {
       this.renderer.setStyle(navbarClass, "display", "block")
      }
      if (rectdirectoryClass.bottom <= this.directoryClassBottom) {
        this.renderer.setStyle(navbarClass, "display", "block")
      }
    }
  }
}
