<ngb-toast *ngFor="let toast of toasts" [class]="toast.classname" [autohide]="true"
    [animation]="true" [delay]="toast.delay || 5000" (hidden)="remove(toast)" 
    (shown)="toastShown($event)" #toastNgb>

    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
    </ng-template>

    <ng-template #text>{{ toast.textOrTpl }}</ng-template>

</ngb-toast>