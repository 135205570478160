import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {catchError, map, Observable, of} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ReferenceDataService {
  private baseUrl: string = '/api/reference';
  private captchaBase: string = '/api';
  private statesUrl: string = "assets/states.json";
  private maxNumberEdits = -1; // acting as a catch

  constructor(private http: HttpClient) { }

  public getBoardNames(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/boards")
  }

  public getLanguages(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/languages");
  }

  public getTitles(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/titles");
  }

  public getStates(): Observable<any> {
    return this.http.get<any>(this.statesUrl);
  }

  public getRequiredFields(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/requiredFields");
  }


  public getAttestationDueDays(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/attestationDueDays");
  }

  public getCaptchaCode(guid: string): Observable<any> {
    return this.http.get<any>(`${this.captchaBase}/captcha/${guid}`);
  }

  public validateCaptcha(guid: string, captcha: string): Observable<any> {
    return this.http.get<any>(`${this.captchaBase}/validateCaptcha/${guid}/${captcha}`);
  }

  private loadMaxNumberEdits(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "/maxNumberOfEdits", {}).pipe(
      catchError(() => {
        console.error("Cannot get MAX edit number from service");
        throw new Error("Could not get MAX edit number from service");
      }),
      map( (resp: { maxNumberOfEdits: number; }) => {
        this.maxNumberEdits = resp.maxNumberOfEdits
        return this.maxNumberEdits;
      })
    );
  }

  public getMaxNumberEdits(): Observable<any> {
    if (this.maxNumberEdits == -1) {
      return this.loadMaxNumberEdits();
    }
    return of(this.maxNumberEdits);
  }

}
