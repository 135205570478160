import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http'
import { catchError, Observable, of } from 'rxjs';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs';

export interface UploadResponse {
  isError: boolean;
  file: any;
  id?: string;
  error?: string;
}

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  private url: string = '/api/documents';
  private myfiles: any = [];

  constructor(private http: HttpClient) {

  }

  public docgenFileUpload(file: any, token: any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<UploadResponse>(this.getUrl(token), formData)
      .pipe(
        map(result => {
          let response: UploadResponse = result && result.id ? { isError: false, file: file.name, id: result.id } : { isError: true, file: file.name, error: 'System unavailable' };
          return response;
        }),
        catchError(e => of({ isError: true, file: file.name, error: e }))
      );
  }

  public uploadFiles(files: any[], token: any): Observable<any[]> {
    let observables: Observable<any>[] = [];
    for (let file of files) {
      observables.push(this.docgenFileUpload(file, token));
    }
    return forkJoin(observables);
  };

  public getFileFromDocgen(docgenId: string, token: any): Observable<Blob> {
    let getUrl = this.getUrl(token) + "/" + docgenId;
    return this.http.get(getUrl, { responseType: 'blob' });
  }

  private getUrl(token: any) {
    if (token && token != undefined) {
      return `${this.url}/${token}`;
    }
    return this.url;
  }
}