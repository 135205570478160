export interface Pageable {
    page: number;
    size: number;
}

export enum BoardStatus {
    NOT_CERTIFIED = 'Not Board Certified',
    CERTIFIED = 'Certified',
    UNKNOWN='Unknown'
}

export class SearchType {
 static readonly OFFICE_NAME: SearchType = {type: "Office Name",value: "officeName"};
 static readonly FIRST_NAME: SearchType = {type: "Provider First Name",value: "firstname"};
  static readonly  LAST_NAME: SearchType = {type: "Provider Last Name",value: "lastname"};
  static readonly  TAX_ID: SearchType = {type: "Tax ID",value: "taxId"};
  static readonly  NPI_1: SearchType = {type: "Type 1 NPI",value: "npi1"};
  static readonly  NPI_2: SearchType = {type: "Type 2 NPI",value: "npi2"};

  type?: string = undefined;
  value?: string;
  directive?: string = undefined;
  maxLength?: number = undefined;

}
export interface Timer {
    hour: number,
    minute: number
}
