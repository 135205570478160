import { TitleCasePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import map from 'lodash-es/map';
import { ReferenceDataService } from 'src/app/shared/services/reference-data.service';

@Component({
  selector: 'languages-update',
  templateUrl: './languages.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguagesComponent implements OnInit {
  @Input() currentLanguages !: {
    languages: string[]
  };
  
  selectedLanguages: string[] = [];
  otherLanguages: string = "";
  languages: any[] = [];
  openLanguageInput: boolean = false;
  otherLangchecked: boolean = false;

  showOverlay = false;

  constructor(private referenceDataService: ReferenceDataService) { }

  ngOnInit(): void {
    this.referenceDataService.getLanguages().subscribe(resp => {
      this.languages = map(resp, (r) => {
        const lang = this.currentLanguages.languages.find(x => x.toLowerCase() == r.toLowerCase());
        return { name: r, value: lang ? lang : r}
      });
      this.selectedLanguages = this.currentLanguages.languages;

      const templang=map(this.currentLanguages.languages, (r) => {
        return { name: r, value: r}
      });
      const additionalLanguages = templang.filter(x => !this.languages.some(y => y.value === x.value));
      if(additionalLanguages.length > 0){
        this.languages=this.languages.concat(additionalLanguages).sort((a, b) => 
        (a.value < b.value ? -1 : 1)
        );
      }
      // this.otherLanguages = this.currentLanguages.otherLanguages;
      // if (this.otherLanguages) {
      //   this.openLanguageInput = true;
      // }
    });   
  }

  OnOtherLangsClicked(e: any) {
    this.openLanguageInput = e.target.checked;
    if (!e.target.checked) {
      this.otherLanguages = '';
      this.otherLangchecked = false;
    }
    else {
      this.otherLangchecked = true;
    }
    this.showOverlay = false;
  }
}