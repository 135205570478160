import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DirectoryHttpService } from 'src/app/directory/services/directory-http.service';
import { UploadModalComponent } from 'src/app/directory/directory-list/upload-modal.component';
import { Affiliation } from 'src/app/directory/directory.interface';
import { PersistanceService } from 'src/app/shared/services/persistance.service';
import { DnConfirmationComponent } from 'src/app/shared/components/dn-confirmation/dn-confirmation.component';

@Component({
  selector: 'test',
  templateUrl: './test.component.html',
  styles: [
  ]
})
export class TestComponent implements OnInit {
  private affiliation: Affiliation = {
    "accessPointId": {
      "contractLineId": "2B8C0ADE-4ADD-EC11-96C1-005056BC9150",
      "specialityId": "66C4C5F8-4BBC-EA11-81EA-0631A9F233D0"
    },
    "network": "DMX",
    "subnetwork": "COMMERCIAL",
    "acceptNewPatients": "",
    "speciality": "Endodontics",
    "levelName": "Endodontics",
    "dueDate": new Date("2021-11-14T00:00:00"),
    "providerId": "ED0FA66B-3F41-EC11-96B6-005056BC9150",
    "locationId": "F847AD3A-3BDD-EC11-96C1-005056BC9150",
    "boardName": "",
    "boardStatus": "Unknown",
    "affiliationId": 0,
    "officeName": "Mission Dental Group",
    "providerFullname": "TYLER A DAVIS"
  };
  @ViewChild('dnConfirm', {static: false, read: DnConfirmationComponent}) dnConfirmation !: DnConfirmationComponent;
 
  constructor(private modalService: NgbModal,
    private router: Router,
    private persister: PersistanceService,
    private httpService: DirectoryHttpService) { }

  ngOnInit(): void {
  }

  routeToDirectory(email: any) {
    this.httpService.getDirectoryValidationLink(email.value).subscribe((resp: any) => {
      if (resp && resp.token) {
        this.router.navigate(['directory', resp.token]).then((e) => {
          if (e) {
            console.log("Navigation is successful!");
          } else {
            console.log("Navigation has failed!");
          }
        });
      } else {
        throw Error("Email Not found");
      }
    });
  }
  openUploadModal() {
    let testDocs = new Map<string, string>();
    testDocs.set("test.pdf", "12345678-1234-1234-1234-123456789123");
    const modalRef = this.modalService.open(UploadModalComponent, { size: 'xl', centered: true });
    modalRef.componentInstance.affiliation = this.affiliation;
    modalRef.componentInstance.documents = testDocs;
    modalRef.result.then((result) => {

      console.log(`Closed with: ${result}`);
    }, (reason) => {

      console.log(`${reason}`);
    })
  }
  clearStorage() {
    this.persister.clearStorage();
    this.dnConfirmation.show('Cleared storage', 'success', 5000).subscribe();
  }
}
