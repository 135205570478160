<div class="modal-header d-flex border-bottom" >
  <div class="">
    <h3 class="modal-title">{{'headers.providerEdit' | translate}}</h3>
  </div>
  <span class="fw-light fs-6 text-muted">
    <span>{{location.address1 | uppercase}}</span><br>
    <span *ngIf="location.address2"> {{location.address2 | uppercase}}<br></span>
    <span>{{location.city | cityStateZip: location.state : location.zip | uppercase}}</span><br>
  </span>  
</div>
<div class="modal-body">
  <max-edit-warn (maxEditReachedEvent)="isMaxEditReached($event)" [directoryStateKey]="directoryStateKey"></max-edit-warn>
  <form name="providerEditForm" scrollToErrorForm dnErrorControl="providerEditControl" #form="ngForm"
    #errorControl="dnErrorControl" id="collapseUpload">
    <div class="row" style="padding-bottom: 15px" [ngbCollapse]="!isCollapsed">
      <div class="col-sm-12">
        <span style="margin-bottom: 5px" [innerHTML]="'content.providerEdit.docUploadIns' | translate"> </span>
      </div>
    </div>
    <div class="card" [ngbCollapse]="!isCollapsed">
      <div class="card-body">  
        <div class="row">
          <div class="col-sm-4 form-floating mb-3 required ">
            <input type="text" class="form-control" maxlength="50" id="firstname" name="firstname" dnDisplayErrors
              required [(ngModel)]="provider.firstname">
            <label for="firstname" class="label-margin-10">First Name</label>
          </div>
          <div class="col-sm-2 form-floating mb-3">
            <input type="text" id="middlename" maxlength="50" class="form-control" [(ngModel)]="provider.middlename"
              name="middlename">
            <label for="middlename" class="label-margin-10">Middle Name</label>
          </div>
          <div class="col-sm-4 form-floating mb-3 required">
            <input type="text" id="lastname" class="form-control" maxlength="50" [(ngModel)]="provider.lastname"
              name="lastname" dnDisplayErrors required>
            <label for="lastname" class="label-margin-10">Last Name</label>
          </div>
          <div class="col-sm-2 form-floating mb-3">
            <input type="text" id="suffix" maxlength="10" class="form-control" [(ngModel)]="provider.suffix"
              name="suffix">
            <label for="suffix" class="label-margin-10">Suffix</label>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 form-floating mb-3  required">
            <input type="text" class="form-control" id="licenseNumber" maxlength="50" name="licenseNumber"
              dnDisplayErrors required [(ngModel)]="this.model.stateLicense.licenseNumber">
            <label for="licenseNumber" class="label-margin-10">License Number (State: {{location.state}})</label>
          </div>

          <div class="col-sm-3 form-floating mb-3 required">
            <input type="text" class="form-control" npi id="npi" [(ngModel)]="provider.npi" maxlength="10" name="NPI"
              dnDisplayErrors required>
            <label for="npi" class="label-margin-10">NPI</label>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3 mb-3 form-floating">
            <select class="form-select" id="gender" [(ngModel)]="provider.gender" name="gender">
              <option selected disabled>Gender</option>
              <option *ngFor="let g of gender" [value]="g.value">{{g.text}}</option>
            </select>
            <label for="gender" class="label-margin-10">Gender</label>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 form-floating mb-3">
            <languages-update #providerLanguages [currentLanguages]="getProviderLanguages()"></languages-update>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 form-floating mb-3  required">
            <select class="form-select" id="boardStatus" [(ngModel)]="provider.boardStatus" (change)="onBoardChanged()"
              name="boardStatus" dnDisplayErrors required>
              <option selected disabled>Board Status</option>
              <option *ngFor="let bs of boardStatus" [value]="bs">{{bs}}</option>
            </select>
            <label for="boardStatus" class="label-margin-10">Board Status</label>
          </div>
          <div class="col-sm-8 form-floating mb-3" *ngIf="provider.boardStatus=='Certified'">
            <select class="form-select" id="boardName" [ngClass]="{'text-muted': provider.boardName === ''}"
              [(ngModel)]="provider.boardName" name="boardName">
              <option style="color:grey" value="">Select Board Name</option>
              <option class="text-body" *ngFor="let t of boardNameList" [value]="t">{{t}}</option>
            </select>
            <label for="boardName" class="label-margin-10">Board Name</label>
          </div>
          <div class="row " *ngIf="uploadedFiles.length">
            <div class="col-sm-4  mb-3 form-check form-check-inline " style="margin-left:-10.5px;">
              <label for="" class="form-check-label">Count of uploaded documents: {{uploadedFiles.length}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: 1em;">
      <div [ngbCollapse]="isCollapsed">
        <file-upload #fileUpload [savedDocuments]="uploadedFiles" [token]="token" fileUploadListener>
        </file-upload>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-info text-white" title="Click to Upload Files"
        (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseUpload"
        *ngIf="isCollapsed">Upload File</button>
      <button type="button" title="Cancel Changes" class="btn btn-secondary" (click)="OnCancel($event)"
        *ngIf="isCollapsed">Cancel</button>
      <button type="submit" class="btn btn-primary" title="Continue" (click)="saveEdits($event, form)"
        *ngIf="isCollapsed" [disabled]="disableContinue()">Continue</button>
      <button type="button" class="btn btn-info text-white" title="Return to Edit Provider'"
        (click)="isCollapsed = !isCollapsed;" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseUpload"
        *ngIf="!isCollapsed"><i class="fa-solid fa-arrow-left"></i> Return to Edit</button>
    </div>
  </form>
</div>
