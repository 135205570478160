import { AfterViewInit, Directive, ElementRef, HostListener, Renderer2} from "@angular/core";
import { AbstractControl, NgControl, ValidationErrors } from "@angular/forms";
import { Subscription } from "rxjs";

@Directive()
export default abstract class DnAbstractTextFormatter implements AfterViewInit {
    /*key - cursorPos of the separator, value - separator */
    protected abstract separators: Map<number, string>;

    abstract parse(val: string): string;

    abstract transform(val: string): string;

    abstract validate(control: AbstractControl): ValidationErrors | null
    constructor(protected ngControl: NgControl,
        protected renderer: Renderer2,
        protected el: ElementRef) { }

    private _subscription !: Subscription | undefined;


    ngAfterViewInit(): void {
        this.ngControl.control?.setValidators(this.validate);
        this._subscription = this.ngControl.valueChanges?.subscribe(() => {
            if (!this.ngControl.dirty) {
                this.initView(this.ngControl.value);
            }
        });
    }

    @HostListener('window:beforeunload')  
    ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    @HostListener('input', ['$event'])
    onInputChange(e: any) {
        let cursorPos = e.target.selectionEnd;
        if (this.ngControl) {
            let separator = this.appendSeparator(e);
            cursorPos = this.formatInput(this.ngControl.value, e);
        }
        this.renderer.setProperty(this.el.nativeElement, 'selectionStart', cursorPos)
        this.renderer.setProperty(this.el.nativeElement, 'selectionEnd', cursorPos)
    }

    protected appendSeparator(e: any) {
        let cursorPos = e.target.selectionEnd;
        let lastChar = e.inputType?.startsWith('insert') && this.ngControl.value ? this.ngControl.value.slice(-1) : undefined;
        let separator = this.separators.get(cursorPos);
        return (separator && lastChar === separator.charAt(0)) ? separator : "";
    }
    
    protected initView(val: string) {
        val = this.parse(val);
        let viewVal = this.transform(val);
        this.ngControl.viewToModelUpdate(val);
        this.ngControl.valueAccessor?.writeValue(viewVal);
    }

    protected formatInput(value: string, e: any) {
        if (value) {
            let cursorPos = e.target.selectionEnd;
            let lastCharSeparator = value.slice(-1);
            let separator = this.separators.get(cursorPos);

            let viewVal;
            if (!e.inputType?.startsWith('delete')) {
                let val = this.parse(value);
                viewVal = this.transform(val);
                if (separator && lastCharSeparator && viewVal
                    && (lastCharSeparator === separator.charAt(0) && viewVal.slice(-1) !== separator)) {
                    viewVal = viewVal + separator;
                }
                this.ngControl.viewToModelUpdate(val);
                this.ngControl.valueAccessor?.writeValue(viewVal);
            }
            if (viewVal && !e.inputType?.startsWith('delete')) {
                cursorPos = cursorPos + this.incrementCursorPos(cursorPos, viewVal);
            }
            return cursorPos;
        }
    }

    private incrementCursorPos(cursorPos: number, viewVal: string) {
        let incremental = 0;
        let separator = this.separators.get(cursorPos);
        if (viewVal && separator && viewVal.substr(cursorPos - 1, separator.length) === separator) {
            incremental = separator.length;
        }
        separator = this.separators.get(cursorPos + 1);
        if (viewVal && separator && viewVal.substr(cursorPos, separator.length) === separator) {
            incremental = separator.length;
        }

        return incremental;
    }
}
