<div class="card">
<div class="card-body">
    <h5 class="card-title">{{'headers.confirmSuccess' | translate}}</h5>
      <span>We have received your directory validation updates and are in the process of reviewing your information. </span>
      <span *ngIf="confirmationNumber">Your confirmation number is <b>{{confirmationNumber}}</b> for your records.<br><br> </span>
      <span *ngIf="isDirChanged">
        <a href="#" (click)="getDirValidationsDoc($event, confirmationNumber)"
          pTooltip="Click to download document" tooltipPosition="bottom"
          class="text-decoration-none">
          Please click here to download the directory validation updates.
        </a><br><br></span>
       <span>Upon completion of the review, you may be contacted by one of our
      representatives.</span>
      <br><br>
      <span>
        We'd love to hear about your ProviderDNA experience.
        Click <a target="_blank" href="{{surveyUrl}}">here</a>
        to fill out a brief survey for ProviderDNA.
      </span>
      <br><br>
  <div style="text-align:center">
    <button  class="btn btn-link" (click) = "back()"><h5>Start Over</h5></button>
  </div>
</div>
</div>
<site-survey></site-survey>

