<div class="modal-header border-bottom">
    <div class="align-top">
      <h3 class="modal-title"> {{'headers.practiceEmailEdit' | translate}}</h3>
    </div>
    <span class="fw-light fs-6 text-muted">
      <span>{{location.address1 | uppercase}}</span><br>
      <span *ngIf="location.address2"> {{location.address2 | uppercase}}<br></span>
      <span>{{location.city | cityStateZip: location.state : location.zip | uppercase}}</span><br>
    </span>
</div>

<div class="modal-body">
  <dn-confirmation #dnConfirm></dn-confirmation>
   <form name="_practiceEmailEditForm" #_practiceEmailEditForm="ngForm" dnErrorControl="errorControl" #errorControl="dnErrorControl">
      <div class="row" style="padding-bottom: 15px" id="instructions">
        <div class="col-sm-12">
          <span style="margin-bottom: 5px">{{'content.practice-email-edit.instruction' | translate}} <br></span>
        </div>
      </div>
      <div class="card" id="practiceEmailEditForm">
        <div class="card-body">
          <ngb-alert #ngbAlert type="danger" *ngIf="showCompletionError" (closed)="showCompletionError=false">
            {{completionErrorMessage}}
          </ngb-alert>
          <div class="col-sm-6 form-floating   mb-3 required">
            <input class="form-control" type="text" maxlength="100" id="userName" name="userName"
                   [(ngModel)]="emailChangeRequest.submittedBy" dnDisplayErrors required  >
            <label for="userName">Your Full Name</label>
          </div>
           <div class="col-sm-6 form-floating  mb-3 required">
            <input class="form-control" type="text" maxlength="12" id="taxId" name="taxId"
                   [(ngModel)]="emailChangeRequest.taxId" dnDisplayErrors  required taxId>
            <label for="taxId">Tax ID</label>
          </div>
          <div class="col-sm-6 form-floating mb-3 required">
            <input #currentEmail="ngModel" class="form-control" type="text" maxlength="200" id="oldPracticeEmail" name="oldPracticeEmail"
              [(ngModel)]="emailChangeRequest.currentEmail" dnDisplayErrors  pattern=".+@.+\..+$" required
              [errorMessages]="{ pattern : 'Please enter valid email id.' }" >
            <label for="oldPracticeEmail">Current Practice Email</label>
          </div>

          <div class="col-sm-6 form-floating  mb-3 required">
            <input #newEmail="ngModel" class="form-control" type="text" maxlength="200" id="newPracticeEmail" name="newPracticeEmail"
                   [(ngModel)]="emailChangeRequest.newEmail" dnDisplayErrors required pattern=".+@.+\..+$"
                   [errorMessages]="{ pattern : 'Please enter valid email id.', textNotMatch : 'Old Practice Email and New Practice Email cannot be same.' }"
                   [textMatch]="currentEmail" [sameText]="false">
            <label for="newPracticeEmail">New Practice Email</label>
          </div>

          <div class="col-sm-6 form-floating  mb-3 required">
            <input class="form-control" type="text" maxlength="200" id="confirmEmailId" name="confirmEmailId"
                   [errorMessages]="{ textMatch : 'New Practice Email and Confirm Email are not matching.' }"
                   confirmField
                   [(ngModel)]="confirmEmail" dnDisplayErrors  required [textMatch]="newEmail">
            <label for="confirmEmailId">Confirm Email</label>
          </div>
        </div>
      </div>

      <div class="modal-footer">

        <button type="button" class="btn btn-secondary" (click)="cancelEdits($event, _practiceEmailEditForm)"
          [disabled]="isRunning">Cancel</button>
        <button type="submit" class="btn btn-primary" (click)="saveEdits($event, _practiceEmailEditForm)"
          [disabled]="isRunning || readOnly">Update <i class="fa fa-spinner" aria-hidden="true" *ngIf="isRunning"></i></button>
      </div>

    </form>
</div>
