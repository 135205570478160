import { Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm, NgModel} from "@angular/forms";
import {DirectorySearchCriteria} from "../directory.interface";
import {SearchType} from "../../shared/shared.interface";
import {DnErrorControlDirective} from "../../dn-error-display/directives/dn-error-control.directive";
import {DnValidators} from "../../shared/directives/dn-validators";
import {NpiPipe} from "../../shared/pipes/npi.pipe";
import {TaxIdFormatPipe} from "../../shared/pipes/taxId-format.pipe";

@Component({
  selector: 'directory-search',
  templateUrl: './directory-search.component.html',
  styleUrls: ['./directory-search.component.css']
})
export class DirectorySearchComponent implements OnInit {

  @Output("loadDirectory")
  loadDirectory: EventEmitter<DirectorySearchCriteria> = new EventEmitter<DirectorySearchCriteria>();

  @Input("lazyParams")
  lazyParams: any;

  public searchTypeDropdown = [SearchType.OFFICE_NAME,
                                SearchType.FIRST_NAME,
                                SearchType.LAST_NAME,
                                SearchType.TAX_ID,
                                SearchType.NPI_1,
                                SearchType.NPI_2];

  public searchType: string = "";
  public searchText: string = "";
  public isFiltered: boolean = false;
  public displayText: string = "";
  @ViewChild(DnErrorControlDirective, {static: false}) _errorControl !: DnErrorControlDirective;
  @ViewChild("searchBox", {static: false}) _searchBox !: NgModel;

  public npiValidator;
  public taxValidator;

  constructor() {
    this.npiValidator = DnValidators.npiFormat();
    this.taxValidator = DnValidators.taxIdFormat()

  }

  ngOnInit(): void {
  }

  onSearchSubmit(searchForm: NgForm) {
    this._errorControl.showErrors();
    if (searchForm.valid) {
      let searchCriteria = new DirectorySearchCriteria();
      searchCriteria[this.searchType as keyof DirectorySearchCriteria] = this.searchText;
      this.loadDirectory.emit(searchCriteria);
      const searchType = this.searchTypeDropdown.find(x => x.value == this.searchType)?.type;      
      this.displayText = `Showing results for ${searchType} "${this.searchText}"`
      this.isFiltered = true;
    }

  }

  onClearSearch(searchForm?: NgForm) {
    if(this.isFiltered){
      this.loadDirectory.emit();
    }
    this.clearSearch();
  }

  clearSearch() {
    this.searchText = "";
    this.searchType = "";
    window.scrollTo(0, 0);
    this.isFiltered = false;
    this._errorControl.hideErrors();
  }

  onSearchBoxChange(){
    if(this.isNpiSelected()){
      this._searchBox.control.patchValue( NpiPipe.parse(this._searchBox.viewModel), {onlySelf: false, emitModelToViewChange:true, emitViewToModelChange: false});
    }else if (this.isTaxCriteria()){
      this._searchBox.control.patchValue( TaxIdFormatPipe.parse(this._searchBox.viewModel), {onlySelf: false, emitModelToViewChange:true, emitViewToModelChange: false});
    }

  }

  onSearchTypeChange() {
    this._errorControl.hideErrors();
    this._searchBox.control.removeValidators(this.npiValidator);
    this._searchBox.control.removeValidators(this.taxValidator);

    if (this.isNpiSelected()) {
      this._searchBox.control.addValidators(this.npiValidator)

    } else if(this.isTaxCriteria()) {
      this._searchBox.control.addValidators(this.taxValidator)

    }
    this._searchBox.control.updateValueAndValidity();
  }

  isNpiSelected(): boolean {
    return this.searchType == SearchType.NPI_1.value || this.searchType == SearchType.NPI_2.value
  }

  isTaxCriteria(): boolean {
    return this.searchType == SearchType.TAX_ID.value
  }

}
