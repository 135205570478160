<div class="row">
    <div class="col-lg-5 form-floating">
        <p-multiSelect inputId="lan" [options]="languages" [(ngModel)]="selectedLanguages" optionLabel="name"
            optionValue="value" [showToggleAll]="false" defaultLabel="Select Additional Languages" [overlayVisible]="showOverlay"
            #multiSelect class="form-control">
        </p-multiSelect>
        <label for="lan" style="margin-left: 10.5px">Additional Languages</label>
    </div>

</div>
<!--
    <div class="col-lg-3 mt-3">
        <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" [(ngModel)]="openLanguageInput" id="otherLangs"
                (click)="OnOtherLangsClicked($event)">
            <label class="form-check-label" for="otherLangs"> Other Languages</label>
        </div>
    </div>
    <div class="col-lg-4 pull-left-50 mt-2" *ngIf="openLanguageInput">
        <input class="form-control" type="text" placeholder="Enter unlisted language" title="Enter a language that does not appear on the dropdown menu. If entering more than one language separate using a comma." maxlength="100"
            [autofocus]="otherLangchecked" [(ngModel)]="otherLanguages" id="otherLangText">
    </div>
    -->
