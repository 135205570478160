import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { GlobalErrorComponent } from './core/errors/global-error/global-error.component';
import { DirectoryListComponent } from './directory/directory-list/directory-list.component';
import {
  ConfirmDirectoryInfoSuccessComponent
} from "./directory/confirm-directory-info-success/confirm-directory-info-success.component";
import { DnpHomeComponent } from './core/dnp-home/dnp-home.component';
import { ServiceRequestComponent } from './service-request/service-request/service-request.component';
import { DisclaimerGuard } from "./shared/router-guards/disclaimer-guard.service";
import { DisclaimerPageComponent } from "./directory/disclaimer-page/disclaimer-page.component";
import { TestComponent } from './test/test/test.component';
import {LinkExpiryComponent} from "./directory/link-expiry/link-expiry.component";
import { LegalAndPrivacyComponent } from "./core/legal-and-privacy/legal-and-privacy.component";
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';

import { each } from 'lodash-es';

const routes: Routes = [
  { path: '', pathMatch: "full", redirectTo: "home" },
  { path: 'home', component: DnpHomeComponent },
  { path: 'link-expired', component: LinkExpiryComponent},
  { path: 'legal-and-privacy', component: LegalAndPrivacyComponent },
  { path: 'disclaimer', component: DisclaimerPageComponent },
  { path: 'services', component: ServiceRequestComponent },
  { path: 'directory', redirectTo: 'home', pathMatch: "full" },
  {
    path: 'directory/:email',
    children: [
      { path: '', component: DirectoryListComponent, canActivate: [DisclaimerGuard] },      
      { path: 'confirmation', component: ConfirmDirectoryInfoSuccessComponent },      
    ]
  },  
  {
    path: 'admin/directory/:email',
    children: [
      { path: '', component: DirectoryListComponent, canActivate: [DisclaimerGuard] },       
    ]
  }

];

const catchAll: Routes = [  { path: 'error', component: GlobalErrorComponent },  
                            { path: '**', pathMatch: "full", redirectTo: "home" }];
const optionalRoutes: Routes = [{"path": "test","component": TestComponent}];

export function initializeAppFactory(httpClient: HttpClient, router:Router): () => Observable<any> {
  let appBase: string = '';  
  return () => httpClient.get<[]>(`${appBase}/routes`)
  .pipe(    
    map( resp => { 
      let dynamicRoutes: Routes = [];
       each(resp, function(r){        
         let o = optionalRoutes.find(o => r === o.path);
         if (o) {
          dynamicRoutes.push(o);
         }
      });   
      router.resetConfig([
         ...routes, ...dynamicRoutes, ...catchAll
      ]);
    })
  );
 }
 
@NgModule({
  imports: [RouterModule.forRoot([], { useHash: true, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]  
})
export class AppRoutingModule {}
