import { Component, Input, OnInit } from '@angular/core';
import { Affiliation, Provider } from '../directory.interface';

@Component({
  selector: 'provider-info',
  templateUrl: './provider-info.component.html',
  styles: [
  ]
})
export class ProviderInfoComponent implements OnInit {

  @Input() providerInfo: any;
  @Input() boardNames: string[] = [];

  constructor() { }

  ngOnInit(): void {  

  }

  getLanguages() {    
    let languages : string[] = this.providerInfo.languages ? this.providerInfo.languages.join(", ") : "";
    let otherLang = this.providerInfo.otherLanguages ? this.providerInfo.otherLanguages : "";
    return languages +  ( languages && otherLang ? ", " : "") + otherLang;
  }

}
