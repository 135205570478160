import { Input } from "@angular/core";
import { OnInit } from "@angular/core";
import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Affiliation } from "../directory.interface";

@Component({
    selector: 'uplodModal',
    styles: [
        '.modal-header { border-bottom: 0 none;  }',
        '.modal-footer { border-top: 0 none; }'
    ],
    template: `
    <div class="modal-header">
      <div class="align-top"> 
        <h4 class="modal-title">Upload Documents</h4>
        <span *ngIf="affiliation">            
            <span class="fw-bold fs-6 pt-3">
            {{affiliation.providerFullname | titlecase}}<br>            
            {{affiliation.speciality}} 
            </span>
        </span>       
      </div>
      <div class="align-top">  
          <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          </button>
       </div>    
    </div>
    <div class="modal-body">
        <!-- <span ngbAutofocus><file-upload [uploadedFiles]="documents"></file-upload></span>            -->
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="activeModal.close('Save click')">Close</button>
    </div>
    `     
  })
export class UploadModalComponent implements OnInit {
    @Input() affiliation !: Affiliation;
    @Input() documents !: Map<string,string>;
    
    constructor(public activeModal: NgbActiveModal) {};
    ngOnInit(): void {
        
    }  
  
}