<div class="container-fluid p-0 m-0">
    <div id="wrapper">
        <dnp-header></dnp-header>
        <div id="main-wrapper" class="mx-1 shadow-sm bg-body rounded">
            <main>
                <global-error></global-error>
                <router-outlet></router-outlet>
            </main>
        </div>
        <dnp-footer></dnp-footer>
    </div>
</div>