import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'confirm-reset-info-modal',
  templateUrl: './confirm-reset-info-modal.component.html',
})
export class ConfirmResetInfoModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
  }

  continue(){
    this.activeModal.close(true);
  }

  cancel(){
    this.activeModal.close(false);
  }
}
