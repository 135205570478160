<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <div class="d-flex">
                <div class="col-sm-12">
                    <p-fileUpload [customUpload]="true" height="20px" width="20px" name="myUpload[]"
                        (uploadHandler)="uploadDocuments($event)" [files]="filesToUpload" [multiple]="true"
                        accept=".CSV,.DOC,.DOCX,.PDF,.JPEG,.PNG,.TIF,.XLS,.XLSX,.JPG,.TIFF,.TXT,.PPT,.TEXT,.PPTX"
                        [maxFileSize]="52400000" [fileLimit]="fileLimit" (onSelect)="onFileSelect($event)"
                        (onUpload)="uploading=false" (onRemove)="onFileRemove($event)" cancelLabel="Clear"
                        (onClear)="reset()" invalidFileLimitMessageDetail="limit is {0} at a time."
                        [showUploadButton]="false" [showCancelButton]="false" #uploader>

                        <ng-template let-file let-index="index" pTemplate="file">

                            <ngb-alert type="danger" [ngClass]="{'d-none': getIndex(file) > 0}"
                                *ngIf="systemUnavailable && showErrors" (closed)="showErrors=false">
                                Document Upload is currently unavailable. Please try again later.
                            </ngb-alert>
                            <div class="row" *ngIf="index === 0" style="border-bottom: 1px inset; font-weight: bold;">
                                <div style="align-self: center;" class="col-sm-4 pt-2 text-break">Document Name</div>
                                <div style="align-self: center;" class="col-sm-3 pt-2 text-end">Size</div>
                                <div class="col-sm-4 pt-2">
                                    Description
                                </div>
                                <div class="col-sm-1 text-end">

                                </div>
                            </div>
                            <div class="row" style="border-bottom: 1px inset">
                                <div style="align-self: center;" class="col-sm-4 pt-2 text-break"
                                    [ngClass]="{'text-danger': showErrors && !uploading}">{{file.name}}</div>
                                <div style="align-self: center;" class="col-sm-3 pt-2 text-end">{{file.size |
                                    formatSize}}</div>
                                <div class="col-sm-4 pt-2">
                                    <select class="form-select" id="doc" name="doc"
                                        [(ngModel)]="file.documentDescription">
                                        <option *ngFor="let dd of documentDescription" [value]="dd">{{dd}}</option>
                                        <!-- <option [ngValue]="null" selected disabled>Select User</option> -->
                                    </select>
                                </div>
                                <div class="col-sm-1" style="all: unset;">
                                    <button type="button" icon="pi pi-times" pTooltip="Remove" tooltipPosition="bottom"
                                        class="p-button-lg p-button-rounded p-button-text p-button-danger" pButton
                                        (click)="removeFromInputList($event, file)" [disabled]="uploading"></button>
                                </div>
                            </div>
                        </ng-template>
                    </p-fileUpload>
                    <div class="d-grid gap-2 d-sm-flex justify-content-sm-end mt-3">
                        <button type="button" class="btn btn-secondary" (click)="uploader.clear()"
                            [disabled]="!uploader.hasFiles() || uploader.uploading || uploading"><i class="fa-solid fa-xmark"></i>
                            Clear</button>
                        <button type="button" class="btn btn-primary" (click)="uploader.upload()"
                            [disabled]="!uploader.hasFiles() || uploader.isFileLimitExceeded() || uploading"><i
                                class="fa-solid fa-arrow-up-from-bracket"></i> Upload <i class="fa fa-spinner" aria-hidden="true" *ngIf="uploading"></i></button>

                    </div>
                    <div class="progress mt-3" style="height: 2rem;" [ngClass]="{'invisible': !uploading}">
                        <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 100%; "
                            aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            Uploading files .....
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <dn-confirmation #dnConfirm></dn-confirmation>
    <div class="row" *ngIf="documentMap && documentMap.size > 0">    
        <div >
            <h6 class="fw-bold">Uploaded Documents</h6>
        </div>
        <div class="card" >
            <p-table #fileUploadTable [value]="documentMap | keyvalue" sortField="value.createDate" [sortOrder]="-1">
                <ng-template pTemplate="header">
                    <tr>
                        <th [pSortableColumn]="'key'" class="fw-bold">Name <p-sortIcon [field]="'key'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'value.documentDescription'" class="fw-bold">Description <p-sortIcon
                                [field]="'value.documentDescription'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'value.createDate'" class="fw-bold">Created <p-sortIcon
                            [field]="'value.createDate'"></p-sortIcon>
                        </th>
                        <th>
    
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td style="padding: unset;">
                            <a href="#" (click)="getUploadedFile($event, item.key)" pTooltip="Click to view document"
                                tooltipPosition="bottom" [ngClass]="{'fw-bold text-success': (isNewFile(item.key))}"
                                class="text-decoration-none" style="font-size: 0.9em !important;">{{item.key}}
                            </a>
                        </td>
                        <td style="padding: unset;">{{documentMap.get(item.key)?.documentDescription}}</td>
                        <td style="padding: unset;">
                            {{documentMap.get(item.key)?.createDate | date:'short'}}
                        </td>
                        <td style="padding: unset;"> 
                            <button type="button" pButton icon="pi pi-times"
                                class="p-button-sm p-button-rounded p-button-text p-button-danger" pTooltip="Delete"
                                tooltipPosition="bottom" (click)="removeUploadedFile($event,item.key)"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>    
            <p class="mt-5" *ngIf="showIframe">
                <iframe width="100%" height="400px" [src]="blobUrl"></iframe>
            </p>    
        </div>
</div>