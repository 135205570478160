import { AfterViewInit, Directive, HostListener, Input, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup, NgModel, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import isEmpty from 'lodash-es/isEmpty';
import { Subscription } from 'rxjs';


@Directive({
  selector: '[oneRequired]',
  providers: [
     {provide: NG_VALIDATORS,useExisting:OneRequiredDirective, multi: true}
  ]
})
export class OneRequiredDirective implements AfterViewInit, Validator, OnDestroy {
  private _subscription !: Subscription;
  @Input('oneRequired') errTarget !: NgModel;  // <input class="form-control" type="hidden" name="Err" ngModel dnDisplayErrors #ErrTarget="ngModel" [oneRequired]="errTarget">  
  
  constructor(){}  

  ngAfterViewInit(): void {    
    setTimeout(() => {
      let formGroup = <FormGroup>this.errTarget.control.parent;      
      Object.values(formGroup.controls).forEach( control => { 
        control.addValidators(this.oneRequiredValidator)
      });
      this._subscription = formGroup.valueChanges.subscribe((val) => {  
        this.validate(this.errTarget.control);   
      });     
    });
  } 

  @HostListener('window:beforeunload')  
  ngOnDestroy(): void {
      this._subscription?.unsubscribe();      
  }

  oneRequiredValidator(formControl: AbstractControl):  ValidationErrors | null {    
    let formGroup = <FormGroup>formControl.parent;        
    let isAtLeastOneFilled = Object.keys(formGroup.controls).find(key=> !isEmpty(formGroup.get(key)?.value));
    return !isAtLeastOneFilled ? {'oneRequired':true} : null; 
  }
 
  validate(formControl: AbstractControl) {
    let formGroup = <FormGroup> formControl.parent;   
    let isAtLeastOneFilled = Object.keys(formGroup.controls).find(key=> !isEmpty(formGroup.get(key)?.value));     
    if ( this.errTarget.control.value != !isAtLeastOneFilled) {       
      this.errTarget.control.setValue(!isAtLeastOneFilled);
      Object.values(formGroup.controls).forEach( control => {
        if (control != formControl) {             
         this.setErrors(!isAtLeastOneFilled,control,'oneRequired') ;                
        }        
      });   
    }               
    return  !isAtLeastOneFilled ? {['contactRequired'] : true} : null;
  }

  private setErrors(setError:boolean, control:AbstractControl, error:string) { 
    let validationError = setError ? {[error]: true} : null;
    if (validationError) {
      control.updateValueAndValidity();
      control.setErrors({ ...control.errors, ...validationError}) ;                
    }else{      
      this.removeError(control,error);
    }
    return validationError;
  }

  private removeError(control:AbstractControl, error:string):void {
    if (error && control?.errors && control.hasError(error)) {
      delete control.errors[error];
      control.updateValueAndValidity();
    } 
  }  
}
