import { AfterViewInit, Input, ViewChild } from "@angular/core";
import { OnInit } from "@angular/core";
import { Component } from "@angular/core";
import { NgForm, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import filter from "lodash-es/filter";
import { DnErrorControlDirective } from "../../dn-error-display/directives/dn-error-control.directive";
import { ReferenceDataService } from "src/app/shared/services/reference-data.service";
import { BoardStatus } from "src/app/shared/shared.interface";
import { Affiliation, Provider, Location, License } from "../directory.interface";
import { LanguagesComponent } from "../languages/languages.component";
import findIndex from "lodash-es/findIndex";
import { FileUploadComponent } from "src/app/shared/components/file-upload/file-upload.component";

import each from "lodash-es/each";
import { DocumentInfo } from "../../shared/components/file-upload/file-upload.interface";
import {DirectoryService} from "../services/directory.service";
import find from "lodash-es/find";


@Component({
  selector: 'providerEdit',
  styles: [
    '.modal-header { border-bottom: 0 none;  }',
    '.modal-footer { border-top: 0 none; }'
  ],
  templateUrl: "provider-edit-modal.component.html"
})
export class ProviderEditModalComponent implements OnInit, AfterViewInit {
  @ViewChild('providerLanguages', { static: false, read: LanguagesComponent }) languages !: LanguagesComponent;
  @ViewChild('errorControl', { static: false }) _errorControl !: DnErrorControlDirective;
  @ViewChild('fileUpload') fileUpload !: FileUploadComponent
  @Input() provider!: Provider;
  @Input() location !: Location;
  @Input() uploadedFiles !: DocumentInfo[];
  @Input() directoryStateKey !: string;
  @Input() token !: any;

  public isCollapsed = true;
  model = {
    stateLicense: {} as License,
    stateLiceseIndex: -1
  }

  private origBoardName !: string | undefined | null;
  private regex = /[&\/\\#|+()$~%.'":*?<>{}^]/g;
  public titleList: string[] = [];
  public boardNameList: string[] = [];
  public gender: any[] = [
    {value: "Male", text: "Male"}, 
    {value: "Female", text: "Female"},  
    {value: "Unknown", text: "Other"}
  ];
  public boardStatus: string[] = ["Certified", "Not Board Certified", "Unknown"];

  private deleteFiles: string[] = [];
  public maxEditReached: boolean = false;

  constructor(public activeModal: NgbActiveModal,
              public referenceDataService: ReferenceDataService,
              private directoryService: DirectoryService) { };

  ngOnInit(): void {
    this.getTitles();    
    this.provider = this.provider ? this.provider : {} as Provider;
    this.provider.boardStatus = this.provider.boardStatus == null ? "" : this.provider.boardStatus;    
    this.origBoardName = this.provider.boardName;
    this.provider.boardName = findIndex(this.boardNameList,(b)=>{ return this.provider.boardName?.toLowerCase() === b.toLowerCase();}) > -1 ? this.provider.boardName : "";
    
    if (this.provider.licenses && this.provider.licenses.length > 0) {
      this.model.stateLiceseIndex = findIndex(this.provider.licenses, (license) => { return license.licensedState === this.location?.state });
      if (this.model.stateLiceseIndex > -1) {
        this.model.stateLicense = this.provider.licenses[this.model.stateLiceseIndex];
      }
    }
    if (this.model.stateLiceseIndex === -1) { // New license entry
      this.model.stateLicense.licensedState = this.location.state;
      this.model.stateLicense.licenseTypeName = "State License";
    }
  }

  ngAfterViewInit() {
  }

  getTitles() {
    this.referenceDataService.getTitles().subscribe(resp => {
      this.titleList = resp;
    });
  }

  onBoardChanged() {
    if (this.provider.boardStatus === BoardStatus.NOT_CERTIFIED || this.provider.boardStatus === BoardStatus.UNKNOWN) {
      this.provider.boardName = "";
      this.origBoardName = "";
    }
  }

  getProviderLanguages() {
    return {
      languages: this.provider.languages ? this.provider.languages : [],
      otherLanguages: this.provider.otherLanguages
    }
  }

  saveEdits(e: any, form: NgForm) {
    this._errorControl.showErrors();
    if (form.valid) {
      let otherLangs = filter(this.languages.otherLanguages?.split(this.regex), (e) => { return e != '' });
      //this.provider.otherLanguages = otherLangs ? otherLangs.join(", ") : null;
      this.provider.languages = this.languages.selectedLanguages;
      
      if (this.origBoardName == null && this.provider.boardName == "") {
        this.provider.boardName = this.origBoardName;
      }

      this.provider.licenses = Array.isArray(this.provider.licenses) ? this.provider.licenses : [];
      if (this.model.stateLiceseIndex === -1) {
        this.provider.licenses.push(this.model.stateLicense);
      } else {
        this.provider.licenses[this.model.stateLiceseIndex] = this.model.stateLicense;
      }

      this.activeModal.close(
        {
          provider: this.provider,
          documents: this.uploadedFiles
        }
      );
    }
    return;
  }

  OnCancel(e: any) {
    this._errorControl.hideErrors();
    this.activeModal.dismiss(
      {
        documents: this.uploadedFiles
      }
    );
    return;
  }

  isMaxEditReached(maxEditReached: boolean) {
    this.maxEditReached = maxEditReached;
  }

  public disableContinue() {
    let changed = true;
    if (this.maxEditReached) {
      changed = this.directoryService.isProviderChanged(this.directoryStateKey, this.provider.providerId);
    }
    return !changed;
  }

   
}
