import { Directive, HostListener, OnInit } from '@angular/core';
import { AbstractControl, ValidationErrors, Validator } from '@angular/forms';
import DnAbstractTextFormatter from './dn-abstract-text-formatter';
import { DnValidators } from './dn-validators';


@Directive({
  selector: '[zipcode]'
})
export class ZipcodeDirective extends DnAbstractTextFormatter implements OnInit, Validator {
  protected separators: Map<number, string> = new Map([[6, '-']]);
  private allButDigits = /\D+/g;

  ngOnInit(): void {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return DnValidators.zipcodeFormat()(control);
  }

  parse(zip: string) {
    if (zip) {
      zip = zip.replace(this.allButDigits, '');
    }
    return zip && zip.length > 9 ? zip.substring(0, 9) : zip;
  }

  transform(zip: string) {
    return zip && zip.length > 5 ? `${zip.substr(0, 5)}-${zip.substr(5)}` : zip;
  }

  @HostListener('blur', ['$event'])
  onBlur(e: any) {
    if (this.ngControl) {
      this.initView(this.ngControl.value);
    }
  }
}