import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DirectoryHttpService } from '../services/directory-http.service';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'confirm-directory-info-success',
  templateUrl: './confirm-directory-info-success.component.html',
  styles: [
  ]
})
export class ConfirmDirectoryInfoSuccessComponent implements OnInit {

  confirmationNumber!: String
  token!: string
  isDirChanged!: boolean
  surveyUrl: any =""

  constructor(private dirHttpService: DirectoryHttpService,private location: Location)
   { }

  ngOnInit(): void {
    this.confirmationNumber = history.state['confirmationNumber'];
    this.token = history.state['token'];
    this.isDirChanged = history.state['isDirChanged'];
    this.surveyUrl = environment.surveyUrl;
  }

  getDirValidationsDoc(e: any, confirmationNumber: String) {
    e.preventDefault();
    if (confirmationNumber) {
        this.dirHttpService.getDirValidationsDoc(this.token, confirmationNumber.toString()).
        subscribe(resp => {
          let fileName = resp.headers.get("Dn-Dir-Validations-Doc")
          if(fileName) {
            this.downLoadFile(resp, "application/ms-excel", fileName);
          }
        }
      );
    }
}

downLoadFile(res: any, type: string, fileName: string) {
  let blob = new Blob([res.body], { type: type});
  const element = document.createElement('a');
      element.href = URL.createObjectURL(blob);
      element.setAttribute('download', fileName);
      element.click();
}

  back() {
    this.location.back();
  }

}
