import { DocumentInfo } from "../shared/components/file-upload/file-upload.interface";

export const EMAIL_ROLES = {
    offceManager:"LOCAL ADMIN",
    provider: "PROVIDER",
    practice: "PRACTICE"
}

export enum DIRECTORY_TYPE {
    PROVIDER = "provider",
    LOCATION = "location",
    PRACTICE = "practice",
    AFFILIATION = "affiliation",
    VALIDATION = "validation",
    ATTESTED = "attested",
    DOCUMENTS = "documents",
    DISCLAIMER = "disclaimer",
    VISITEDAFFILIATIONS = "visitedAffiliations"
}

export enum CUSTOM_KEY {
    TOUR = "tour"
}

export interface Location {
    locationId: string;
    active: string;
    officeName: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
    website?: string;
    officePhone?: string;
    officeFax?: string;
    officeLanguages?: string[];
    otherLanguages?: string | null;
    officeEmail?: string;
    workHours?: string | string[];
    handicapAccessible?: string;
    practiceId: string;
    taxId?: string;
    groupNpi?: string;
    contactType? : string;
    contactPersonName?: string;
    country?: string;
}

export interface EditedLocation extends Location {
    officeHours?: string; // displayed workhours
}

export interface LocationManager {    
    lastName?: string;
    primaryEmail?: string;
    primaryPhone?: string;

}

export interface Provider {
    providerId: string;
    active: string;
    lastname?: string;
    firstname?: string;
    middlename?: string;
    suffix?: string;
    title?: string;
    phone?: string;
    cellPhone?: string;
    fax?: string;
    email?: string;
    license?: string;
    npi?: string;
    taxId?: string;
    gender?: string;
    languages?: string[];
    otherLanguages?: string | null;
    licenses?: License[];
    boardName?: string | null;
    boardStatus?: string;
}

export interface EditedProvider extends Provider {
    practiceId?: String;
}

export interface AccessPointId {
    contractLineId: String;
    specialityId: String;
}

export interface Affiliation {
    affiliationId: number;
    accessPointId: AccessPointId;
    network?: string;
    subnetwork?: string;
    acceptNewPatients?: string;
    isTelehealth?: string;
    speciality?: string;
    levelName?: string;
    dueDate?: Date;
    comments?: string;
    providerId: string;
    locationId: string;
    isValid?: boolean;
    dateValidated?: Date;
    boardName?: string;
    boardStatus?: string;
    officeName: string;
    providerFullname: string;
    practiceId?: string;
    locationHash?: string;
    emailRoles?:string[];
    showOfficeManagerIcon?: boolean;
}

export interface EditedAffiliation extends Affiliation {
    npi?: string;
    taxId?: string;
}

export interface DirectoryData {
    totalItems: number;
    totalPages: number;
    currentPage: number;
    directory: Directory
}


export interface Directory {
    locations: Location[];
    providers: Provider[];
    affiliations: Affiliation[];
    documents: DocumentInfo[];
}

export interface License {
    licenseNumber?: string;
    licenseTypeName?: string;
    licensedState?: string;
    issueDate?: string;
    modifiedDate?: string;
    expirationDate?: string;
}

export class ReviewedAffiliation {
    affiliationId !: number;
    accessPointId !: AccessPointId;
    dueDate?: Date;
}

export interface Contact {
    fullname?: String;
    phone?: number;
    email?: String;
}

export interface DirectoryChangeRequest {
    currentDirectory: Directory;
    modifiedDirectory: Directory;
    validated: ReviewedAffiliation[];
    invalidated: ReviewedAffiliation[];
    documents: DocumentInfo[];
    comments: String;
    responseDate: string;
    attested: Contact;
}

export interface LocationManagerRequest {
    currentDirectory: Directory;
    modifiedDirectory: Directory;
}

export class DirectorySearchCriteria{
  officeName?: string = undefined //location
  taxId?: string = undefined//location
  firstname?: string = undefined// provider
  lastname?: string = undefined // provider
  npi1?: string = undefined // provider
  npi2?: string = undefined// location

  constructor(){}
}

export interface EmailChangeRequest {
    submittedBy?: string;
    currentEmail?: string;
    newEmail?: string;
    entityId?: string;   
}
export interface PracticeEmailChangeRequest extends EmailChangeRequest{
  taxId?: string;
  providerId?: string;
}

export interface ProviderEmailChangeRequest extends EmailChangeRequest {
  npi?: string;
}

export interface IOfficeManager {
    name: string;
    value: LocationManager;
}