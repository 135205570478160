import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, Observable } from 'rxjs';
 

@Injectable()
export class HttpSessionHandler implements HttpInterceptor {
   
  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
           .pipe(
            catchError((error) => {  
                if (error instanceof HttpErrorResponse && !(error.error instanceof ErrorEvent)) {
                  if (error.status === 403) {
                    return throwError(()=> {
                      this.router.navigate([""]);
                      throw new Error('autorization error');
                    });
                  }
                } 
                return throwError(()=>error);
            })
           );
  }
  
}
