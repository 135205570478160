import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";

export class AuthHeaderInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const token = btoa('58b5c8b1-d490-4166-99ee-023d7d48f055');
      req = req.clone({
        headers: req.headers.set('x-portal-client-id', token),
      });
      return next.handle(req);
    }
  }