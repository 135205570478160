import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'npi'
})
export class NpiPipe implements PipeTransform {
  private static regex = /[^0-9]/g;
  public static maxLength = 10;

  transform(npinum?: string): string {
    return NpiPipe.parse(npinum);
  }

  static parse(val: any) {
    if (val) {
      val = val.replace(NpiPipe.regex, '');
      if (val && val.length > NpiPipe.maxLength) {
        val = val.substring(0, NpiPipe.maxLength);
      }
    }
    return val;
  }

}
