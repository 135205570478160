<header class="pt-3 sticky-md-top bg-light">
  <div class="d-flex flex-wrap justify-content-center mx-3">
    <!-- <a href="#" class="d-flex align-bottom m-1 mb-md-1 me-md-auto text-decoration-none"> -->
      <span class="d-flex align-bottom m-1 mb-md-1 me-md-auto text-decoration-none" style="height: 35px">
      <img src="assets/images/ProviderDNA-Logo.png" height="30px" />
      </span>
    <!-- </a> -->
    <ul class="nav nav-pills fs-6 align-top" style="display:flex;list-style:none;padding: 0px;justify-content: space-between;">
      <li class="nav-item nav-link">
        <a href="tel:{{'headers.main.phone' | translate}}" class="link"><i class="fa-solid fa-phone" title="Phone"></i> {{'headers.main.phone' | translate}}</a>            
      </li>
      <li  class="nav-item nav-link">        
        <a href="mailto:{{'headers.main.email' | translate}}" class="link" style="padding-left: 3px; "><i class="fa-solid fa-envelope" title="Email"></i> {{'headers.main.email' | translate}}</a>
      </li>
    </ul>
  </div>
  <nav class="navbar navbar-expand-sm navbar-dark bg-primary p-0">
    <div class="container-fluid">
      <div class="navbar-nav" style="min-height:20px">
        <!-- <a routerLink="/home" routerLinkActive="active" class="nav-item nav-link" aria-current="page">Home</a> -->
        <!-- <a routerLink="/services" routerLinkActive="active" class="nav-item nav-link">Service Requests</a> -->
       
      </div>
    </div>
  </nav>
</header>