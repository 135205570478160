import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import {AbstractControl, NgForm, NgModel} from "@angular/forms";
import { DnErrorControlDirective } from "src/app/dn-error-display/directives/dn-error-control.directive";
import { Provider, ProviderEmailChangeRequest } from "../directory.interface";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DirectoryService } from "../services/directory.service";
import { environment } from "src/environments/environment";
import { DnConfirmationComponent } from "src/app/shared/components/dn-confirmation/dn-confirmation.component";

@Component({
    selector: 'provider-email-edit',
    styles: [
        '.modal-header { border-bottom: 0 none;  }',
        '.modal-footer { border-top: 0 none; }'
    ],
    templateUrl: "provider-email-edit-modal.component.html"
})

export class ProviderEmailEditModel implements OnInit {
  
    @ViewChild('errorControl', { static: true }) _errorControl !: DnErrorControlDirective;
    @ViewChild('provEmailEditForm',{ read: ElementRef }) formElement !: ElementRef;
    @ViewChild('dnConfirm', {static: false, read: DnConfirmationComponent}) dnConfirmation !: DnConfirmationComponent;
 
    @Input() provider !: Provider;
    @Input() readOnly: boolean = false;
    emailChangeRequest!: ProviderEmailChangeRequest;   
    confirmEmail: string = '';
    showCompletionError: boolean = false;
    completionErrorMessage = environment.common.errorMessages.changeCompletion;
    isRunning = false;
    constructor(public activeModal: NgbActiveModal, 
               private directoryService: DirectoryService) { };

    ngOnInit(): void {
      console.log(this.readOnly);
      this.emailChangeRequest = {} as ProviderEmailChangeRequest;
      this.emailChangeRequest.entityId = this.provider.providerId;
    }

    ngAfterViewInit(): void {
      //this._errorControl.showErrors(['confirmEmailError']);
    }


    cancelEdits(e: any, form: NgForm) {
        this._errorControl.hideErrors();
        this.showCompletionError = false;
        this.activeModal.close(false);
        return;
    }

    saveEdits(e: any, form: NgForm) {
      this._errorControl.showErrors();

      if (form.valid ){
          this.isRunning = true;
          this.directoryService.saveProviderEmailChangeRequest(this.emailChangeRequest).subscribe(resp => {
            this.isRunning = false;
            if (resp?.status && resp?.status !== "ERROR") {
              this.dnConfirmation.show("Email Changed Successfully", 'success', 8000).subscribe(()=>{
                this.activeModal.close(true);
              });
            }else{
               this.showCompletionError = true;
               this.formElement.nativeElement.scrollIntoView({behavior: 'smooth'});
               let sub = form.valueChanges?.subscribe(()=>{              
                 this.showCompletionError = false;
                 sub?.unsubscribe();
               });
            }
          });
        }
    }

    
}
