import { EventEmitter, Injectable, Output } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class EventsService {
    @Output() onRowSizeChanged: EventEmitter<any> = new EventEmitter();
    @Output() onPagination: EventEmitter<any> = new EventEmitter();
    constructor() { }

    public broadcast(eventName: string) {
        switch (eventName) {
            case "rowSizeChanged": this.onRowSizeChanged.emit("true");
                break;
            case "onPagination": this.onPagination.emit();
                break;
            default:
                break;
        }
    }
}
