
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatSize'
})
export class FormatSizePipe implements PipeTransform {
  transform(bytes: number) {    
    if (bytes == 0) {
      return '0 B';
    }
    let k = 1000,
    dm = 3,
    sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
 