import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {DirectoryService} from "../services/directory.service";
import {PersistanceService} from "../../shared/services/persistance.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DIRECTORY_TYPE} from "../directory.interface";
import { ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'disclaimer-page',
  templateUrl: './disclaimer-page.component.html',
  styleUrls: ['./disclaimer-page.component.css']
})
export class DisclaimerPageComponent implements OnInit {
  @ViewChild('btnContinue', { static: true }) btnContinueRef!: ElementRef<HTMLButtonElement>;

  iAgreeCheckBox: boolean = false;
  isContactUsVisible: boolean = false;
  isSubmitting : boolean = false;
  disclaimer  = undefined;
  constructor( private persistanceService: PersistanceService, 
               private router: Router,
               private activatedRoute: ActivatedRoute, 
               private directoryService: DirectoryService,
               private element: ElementRef,
               private renderer: Renderer2,
               private translate: TranslateService) {
    this.iAgreeCheckBox = false;
  };

  ngOnInit(): void {
    let email = this.activatedRoute.snapshot.queryParamMap.get("next");
    this.showNavigationBar();
    if(!email){
      this.router.navigate(["/home"])
    }
    this.translate.get('disclaimer.content').subscribe((label) => {
        this.disclaimer = label;
    });
  }

  onContinue() {
    if(this.iAgreeCheckBox) {
      this.isSubmitting = true;
      let email = this.activatedRoute.snapshot.queryParamMap.get("next");
      let directoryListUrl = "/directory/" + email;
      this.persistanceService.setSessionItem("showDisclaimer", false);      
      this.router.navigate([directoryListUrl],{queryParams: {readOnly: this.activatedRoute.snapshot.queryParamMap.get("readOnly")}}).then(d =>{
        this.isSubmitting = false;
      });
      
    }
  }

  private get hostElement(): HTMLElement {
    return this.element.nativeElement;
  }
  onDisagree() {
    this.isSubmitting = false;
    this.router.navigate(["home"])
  }

  onDisclaimerScroll(e:Event): any {
    let disclaimerContainer = document.getElementById("disclaimer-div");
    if (disclaimerContainer) {
      let disclaimerContainerRect = disclaimerContainer.getBoundingClientRect();
      let disclaimerContainerBottom = disclaimerContainerRect.bottom;

      let disclaimerEndElm = document.getElementsByClassName("disclaimer-end");
      if (disclaimerEndElm && !this.isContactUsVisible) {
        let disclaimerEndRect = disclaimerEndElm[0].getBoundingClientRect();
        let disclaimerEndTop = disclaimerEndRect.top;
        let disclaimerEndBottom = disclaimerEndRect.bottom;
        this.isContactUsVisible = (disclaimerEndTop > 0) && (disclaimerEndBottom < disclaimerContainerBottom);
      }
    }
  }

  showNavigationBar(){
    let navbarClass = this.hostElement.parentNode?.parentElement?.parentNode?.querySelectorAll('.navbar')[0];
    this.renderer.setStyle(navbarClass, "display", "block")
  }
}
