import { AfterViewInit, Directive, HostListener } from '@angular/core';
import { AbstractControl, NgControl, ValidationErrors, Validator } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NpiPipe } from '../pipes/npi.pipe';
import { DnValidators } from './dn-validators';

@Directive({
  selector: '[npi]'
})
export class NpiDirective implements AfterViewInit, Validator {
  private NpiPipe: NpiPipe = new NpiPipe();
  private _subscription !: Subscription | undefined;

  constructor(private ngControl: NgControl) { }

  ngAfterViewInit(): void {
    this.ngControl.control?.setValidators(this.validate);
    this._subscription = this.ngControl.valueChanges?.subscribe(() => {
      this._subscription?.unsubscribe();
      this.formatNpi(this.ngControl.value);
    });
  }

  @HostListener('input', ['$event'])
  onInputChange() {
    if (this.ngControl) {
      this.formatNpi(this.ngControl.value);
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return DnValidators.npiFormat()(control);
  }

  private formatNpi(val: any) {
    val = NpiPipe.parse(val);
    this.ngControl.viewToModelUpdate(val);
    this.ngControl.valueAccessor?.writeValue(this.NpiPipe.transform(val));
  }

}