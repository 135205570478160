import { AfterViewInit, Directive, HostListener } from '@angular/core';
import { AbstractControl, NgControl, ValidationErrors, Validator } from '@angular/forms';
import each from 'lodash-es/each';
import { Subscription } from 'rxjs';
import { PhoneFormatPipe } from '../pipes/phone-format.pipe';
import DnAbstractTextFormatter from './dn-abstract-text-formatter';

import { DnValidators } from './dn-validators';

@Directive({
  selector: '[formatPhone]'
})
export class FormatPhoneDirective extends DnAbstractTextFormatter implements AfterViewInit, Validator {
  protected separators: Map<number, string> = new Map([[4, '-'], [8, '-']]);

  private phoneFormatPipe: PhoneFormatPipe = new PhoneFormatPipe();

  validate(control: AbstractControl): ValidationErrors | null {
    return DnValidators.phoneFormat()(control);
  }

  parse(val: string): string {
    return PhoneFormatPipe.parse(val);
  }

  transform(val: string): string {
    return this.phoneFormatPipe.transform(val)
  }
}