import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DirectoryListComponent } from "../directory-list/directory-list.component";
import { Affiliation } from "../directory.interface";
import { DirectoryService } from '../services/directory.service';

@Component({
  selector: 'confirm-submit-warning-modal',
  templateUrl: './confirm-submit-warning-modal.component.html',
  styles: [
  ]
})

export class ConfirmSubmitWarningModalComponent implements OnInit {

  modalClosed: boolean = false;
  @Input() affiliationsHavingUnValidDataCnt: number = 0;
  contentText = '';

  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal,
    private translate: TranslateService,
    private directoryService: DirectoryService) { };

  ngOnInit(): void {
    let warningLabel = this.affiliationsHavingUnValidDataCnt > 1 ? 'content.attestation-warning.multiple' : 'content.attestation-warning.single';
    this.translate.get(warningLabel, { unValidatedCount: this.affiliationsHavingUnValidDataCnt > 1 ? this.affiliationsHavingUnValidDataCnt : 'one' }).subscribe((label) => {
      this.contentText = label;
    });
  }

  continue() {
    this.activeModal.close(true);
  }

  cancel() {
    this.activeModal.close(false);
  }

}
