<div style="margin-right:10px">
<div class="container-fluid large">
  <div id="disclaimer-div" (scroll)="onDisclaimerScroll($event)" class="row card overflow-auto content square scrollbar-dusty-grass text-muted">
    <div class="card-body" style="color:#484e53;" [innerHTML]="disclaimer">
    </div>
  </div>
  <div class="d-flex justify-content-center" *ngIf="disclaimer==undefined">
    <div class="spinner-border text-secondary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div class="row align-items-center justify-content-md-end border-top" style="padding-top:25px;padding-bottom:25px;">
      <div class="row  align-items-center">
        <div class="col-md-10 d-flex justify-content-md-end">
          <span style="color:red">*&nbsp;</span>
          <div class="form-check" >
            <input [disabled]="!isContactUsVisible" class="form-check-input" type="checkbox" [(ngModel)]="iAgreeCheckBox" id="termsAndConditionsCheckBox">
            <label class="form-check-label" for="termsAndConditionsCheckBox" title="Agree to terms and conditions to continue">
              I have reviewed and agree to the Terms and Conditions
            </label>
          </div>
        </div>
        <div class="col-md-2 d-flex">
          <site-survey [elementInput]="btnContinue"></site-survey>
          <button #btnContinue type="button" id="btnContinue" class="btn btn-primary" [disabled]="!iAgreeCheckBox || isSubmitting"
            (click)="onContinue()">Continue <i class="fa fa-spinner" aria-hidden="true" *ngIf="isSubmitting"></i> </button>
        </div>
      </div>
  </div>
</div>
</div>
