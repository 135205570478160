import { Injectable, OnInit } from '@angular/core';
import { DirectoryHttpService } from './directory-http.service';
import { Contact, IOfficeManager, LocationManager } from '../directory.interface';
 

export enum CACHE_NAME {
  OFFICE_MANAGERS = "officeManagers",
}

@Injectable({
  providedIn: 'root'
})
export class DirectoryCacheService{
 
  private _officeManagers : Map<string, IOfficeManager> = new Map<string, IOfficeManager>()

  constructor(private httpService: DirectoryHttpService) { }
 
         
  public loadCache(token:string) {
    this.retrieveOfficeManagers(token);
  }     
  
  public clearCache() {
    this._officeManagers.clear();
  }   

  public retrieveOfficeManagers(token:string) {
    this.clearCache();
    this.httpService.getLocationManagers(token).subscribe((managers) =>{
      if (managers) {        
        managers.forEach((d: any) => {
            let key = this.getOfficeManagerKey(d); 
            this._officeManagers.set( key, {name: key, value: d});
        }); 
      }
    });
  }

  public get officeManagers():IOfficeManager[] {
    return Array.from(this._officeManagers.values());
  }
  
  public addToCache(name:CACHE_NAME, data:any) {
    switch(name) {
      case CACHE_NAME.OFFICE_MANAGERS: 
        let key = this.getOfficeManagerKey(data);       
        this._officeManagers.set( key, {name: key, value: data});break;
      default:
        console.error("Cache not found");
        throw new Error("Invalid cache");
    } 
  }
 

  private getOfficeManagerKey(data:LocationManager) {
    let name = data.lastName ? data.lastName + ' - ' : "";
    return name + data.primaryEmail;
  }
}
