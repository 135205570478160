<div style="margin-right:7px">

<div id="instructions" class="row">
    <div class="col-sm-12 card instructions">
        <div class="card-body instList" [innerHTML]="'content.directory.instructions' | translate">
        </div>
    </div>
</div>
<div class="row align-items-center px-1" [ngClass]="{invisible: lazyParams.loading}">
  <div class="col-md-8">
    <directory-search #directorySearch  [lazyParams]="lazyParams" (loadDirectory)="filterDirectoryGrid($event)" ></directory-search>
</div>
  <div class="col-md-4">
  </div>
</div>
<div class="row px-1" [ngClass]="{invisible: lazyParams.loading}">
    <div class="col-md-4"></div>
    <div class="col-md-4 d-flex justify-content-center align-self-center">
        <label class="text-muted fw-lighter">{{customPageReportTemplate}}</label>
    </div>
    <div class="col-md-4 d-flex justify-content-end">
        <div id="legends">
            <ul class="list-group list-group-flush list-group-horizontal-sm float-md-end" >
              <li class="list-group-item  border-0 p-1"><i class="fa-solid fa-square text-success pe-1"></i>Changed</li>
              <li class="list-group-item  border-0 p-1"><i class="fa-solid fa-square text-danger pe-1"></i>Required</li>
              <li class="list-group-item  border-0 p-1"><i class="fa-solid fa-square text-critical pe-1"></i>Past due date</li>
            </ul>
        </div>
    </div>
</div>

<div id="directoryMain" (onscroll)="onScroll($event)" class="row">
    <div class="directory" >
        <p-table #directoryTable [value]="affiliations" dataKey="affiliationId" [rowTrackBy]="trackBy" editMode="row"
            styleClass="p-datatable-sm" rowGroupMode="rowspan" [groupRowsBy]="groupRowsBy" sortMode="multiple"
            [multiSortMeta]="multiSortMeta" responsiveLayout="scroll" [paginator]="true" [rows]="lazyParams.size"
            [totalRecords]="lazyParams.totalRecords" [loading]="lazyParams.loading" [lazy]="true"
            (onLazyLoad)="loadDirectory($event)" [showCurrentPageReport]="true" [(first)]="initialPage"
            [rowsPerPageOptions]="[50,75,100]" [pageLinks]="8" [showJumpToPageInput]="false"
            (onPage)="resetScrollToTop()"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} access points">
            <ng-template pTemplate="header">
                <tr *ngIf="!lazyParams.loading">
                    <th class="col-3 tableHead editable bg-primary text-light">Location</th>
                    <th class="col-2 tableHead editable bg-primary  text-light">Provider</th>
                    <th class="col-1 tableHead editable bg-primary  text-light" style="width: 5%;"></th>
                    <th class="col-1 tableHead editable bg-primary  text-light" style="width: 9%;">Accepting New Patients</th>
                    <th class="col-1 tableHead editable bg-primary  text-light">Telehealth</th>
                    <th class="col-1 tableHead bg-primary text-light">Specialty</th>
                    <th class="col-1 tableHead bg-primary text-light">Network</th>
                    <th class="col-1 tableHead bg-primary text-light">Due Date</th>
                    <th class="col-1 tableHead bg-primary text-light">Status</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-affiliation let-rowIndex="rowIndex" let-rowgroup="rowgroup"
                let-rowspan="rowspan" let-editing="editing">
                <tr [pEditableRow]="affiliation">
                    <td *ngIf="shouldShowRowGroup(rowgroup,affiliation,rowIndex)"
                        [attr.rowspan]="calculateRowSpan(affiliation)" class="col-3 bg-white align-top"
                        style="border-width: 1.1px;">
                        <div>
                            <button pButton pTooltip="Edit Location" tooltipPosition="bottom" type="button"
                                icon="fa-solid fa-pencil text-warning" (click)="openLocationModal(affiliation)"
                                class="p-button-sm openInModal p-button-rounded p-button-text"></button>
                            <button pButton pTooltip="Add/Edit Office Manager" tooltipPosition="bottom" type="button"
                                icon="fa-solid fa-people-roof text-info" (click)="openLocationManagerModal(affiliation)"
                                class="p-button-sm openInModal p-button-rounded p-button-text fontFamilyClass" style="float: right !important;" *ngIf="hasLocationAccess(affiliation) || affiliation.showOfficeManagerIcon"></button>
                            <button pButton pTooltip="Change Practice Email" tooltipPosition="bottom" type="button"
                                    icon="far fa-envelope text-info" (click)="openPracticeEmailChangeModal(affiliation)"
                                    class="p-button-md openInModal p-button-rounded p-button-text fontFamilyClass" style="float: right !important;" *ngIf="hasPracticeAccess(affiliation)"></button>

                        </div>
                        <span class="col align-top">
                            <location-info
                                [locationInfo]='getLocationInfo(affiliation.practiceId,affiliation.locationId)'
                                [affiliation]="affiliation"
                                [isAlsoPracticeManager]="isPracticeEmailRole"
                                (addOfficeManagerRequest)="openLocationManagerModal($event)"
                                (onShowOfficeManagerIcon)="showOfficeManagerIconForPractice($event,affiliation)">
                            </location-info>
                        </span>
                    </td>
                    <td class="col-2 align-middle" *ngIf="rowgroup" [attr.rowspan]="rowspan"
                        style="border-width: 1.1px;">
                        <button pButton pTooltip="Edit Provider" tooltipPosition="bottom" type="button"
                            icon="fa-solid fa-pencil text-warning" (click)="openProviderModal(affiliation)"
                            class="p-0 p-button-sm openInModal p-button-rounded p-button-text">
                        </button>
                       <button pButton pTooltip="Change Provider Email" tooltipPosition="bottom" type="button"
                               icon="far fa-envelope text-info" (click)="openProviderEmailChangeModal(affiliation)"
                               class="p-button-md openInModal p-button-rounded p-button-text fontFamilyClass" style="float: right !important;"
                               *ngIf="hasProviderAccess(affiliation)"></button>
                        <span class="col align-top">
                            <provider-info [providerInfo]="getProvider(affiliation)" [boardNames]="boardNames"></provider-info>
                        </span>
                    </td>
                    <td class="col-1">
                        <div style="margin-top: -40px;">
                            <button pButton pTooltip="Edit Affiliation" tooltipPosition="bottom" type="button"
                                icon="fa-solid fa-pencil text-warning" (click)="onRowEditInit(affiliation, rowIndex)"
                                *ngIf="editingAffiliationId != affiliation.affiliationId && !disableAcceptNewPatient(affiliation.affiliationId)"
                                class="p-button-sm p-button-rounded p-button-text">
                            </button>
                            <button pButton pTooltip="Edit Affiliation" tooltipPosition="bottom" type="button"
                                icon="fa-solid fa-pencil text-muted" [disabled]="true"
                                *ngIf="disableAcceptNewPatient(affiliation.affiliationId)" class="p-button-sm p-button-rounded p-button-text">
                            </button>
                            <button pButton pTooltip="Apply Changes" tooltipPosition="top" type="button" icon="fa-solid fa-check text-success"
                                (click)="onRowEditSave(affiliation,rowIndex)" *ngIf="editingAffiliationId == affiliation.affiliationId"
                                class="p-buton-sm p-button-rounded p-button-text">
                            </button>
                            <button pButton pTooltip="Cancel Changes" tooltipPosition="top" type="button" icon="fa-solid fa-times text-danger"
                                (click)="onRowEditCancel(affiliation,rowIndex)" *ngIf="editingAffiliationId == affiliation.affiliationId"
                                class="p-button-sm p-button-rounded p-button-text">
                            </button>
                        </div>
                    </td>
                    <td class="col-1">
                        <div style="align-items: left; display: table; margin: left;">
                            <select class="form-select fs-6"
                                style="width: auto;" name="acceptNewPatients" id="acceptNewPatients"
                                *ngIf="editingAffiliationId == affiliation.affiliationId"
                                [(ngModel)]="affiliation.acceptNewPatients">
                                <option *ngFor="let _option of _options" [ngValue]="_option.value">
                                    {{_option.label}}
                                </option>
                            </select>
                            <div highlighter="newPatientP{{rowIndex}}">
                                <span id="newPatient{{rowIndex}}" title="Accepting New Patients" class="col align-top"
                                    highlighter="newPatientP{{rowIndex}}"
                                    *ngIf="editingAffiliationId != affiliation.affiliationId || maxEditReached" (click)="onEvent($event)"
                                    style="padding-left: 15px;">{{affiliation.acceptNewPatients}}</span>
                            </div>
                        </div>
                    </td>
                    <td class="col-1">
                                <select class="form-select" id="isTelehealth" style="width: auto;" [(ngModel)]="affiliation.isTelehealth" name="isTelehealth"
                                      *ngIf="editingAffiliationId == affiliation.affiliationId">
                                      <option value="" disabled>Select</option>
                                    <option *ngFor="let g of isTelehealth" [value]="g.value">{{g.text}}</option>
                                </select>
                        <div highlighter="newPatientP{{rowIndex}}">
                            <span id="isTelehealth{{rowIndex}}" class="col align-top" title="Telehealth" style="padding-left: 15px;"
                                *ngIf="editingAffiliationId != affiliation.affiliationId || maxEditReached" (click)="onEvent($event)">
                                {{affiliation.isTelehealth}}</span>
                        </div>
                    </td>
                    <td class="col-1"><span title="Speciality">{{affiliation.speciality | titlecase}}</span></td>
                    <td class="col-1"><span title="Network" [innerHtml]="affiliation.network | network: affiliation.subnetwork"></span></td>
                    <td class="col-1" [ngClass]="{'text-critical': isDatePastDue(affiliation.dueDate)}">
                        <span title="Due Date">{{affiliation.dueDate | date:"MM/dd/yyyy"}}</span></td>
                    <td class="col-1">
                        <span joyrideStep="explainValidStep" [stepContent]="customContent" [doneTemplate]="doneButton" *ngIf="rowIndex==0"></span>
                        <div class="form-check">
                            <input type="checkbox" id="valid{{rowIndex}}" name="valid{{rowIndex}}"
                                [(ngModel)]="affiliation.isValid" pTooltip="{{'content.joyride-steps.tooltip' | translate}}" tooltipPosition="bottom"
                                class="form-check-input" (click)="showRequiredFieldsWarning($event, affiliation)">
                            <label class="form-check-label" for="valid{{rowIndex}}">Valid&nbsp;</label>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer" let-columns>
                <tr>
                    <td *ngFor="let col of columns">
                        {{col.header}}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8">No Entries found</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<ng-template #customContent>
  <div class="container joyride-container">
        {{'content.joyride-steps.status' | translate}}
  </div>
</ng-template>
<ng-template #doneButton>
  <button (click)="onDone()" class="btn btn-info btn-sm text-white">OK</button>
</ng-template>
<div class="row" style="padding-top:15px;" id="maxEditWarnDiv">
  <div class="col-sm-12 justify-content-around">
    <div *ngIf="maxEditReached && !lazyParams.loading"
         class="align-items-center justify-content-start alert alert-danger"
         style="padding-top:15px; padding-bottom:15px;"
         role="alert" data-mdb-color="danger">
      <i class="fa-solid fa-triangle-exclamation fa-lg text-danger"></i>&nbsp;&nbsp;
      <span [innerHTML] = "'maxEditedWarning.content' | translate"></span>
    </div>
  </div>
</div>
<div id="confirmDirectoryInfoBtn" class="row">
    <div class="col-sm-12 card border-0" [ngClass]="{ngHide: !(!lazyParams.loading && lazyParams.totalRecords > 0)}" >
        <div class="card-body" style="z-index: 1000;">
            <div class="d-grid gap-2 d-sm-flex justify-content-sm-end">
              <button type="button" class="btn btn-outline-secondary" *ngIf="directorySearch.isFiltered"
                      (click)="directorySearch.onClearSearch()">Clear Search</button>
                <button type="button" class="btn btn-secondary" [disabled]="isSubmitting"
                    (click)="openConfirmResetInfoModal()">Reset</button>
                <button type="submit" #btnSubmitRef [id]="btnSubmitId" class="btn btn-primary" [disabled]="isSubmitting || readOnly"
                    (click)="submitValidations(affiliations,directorySearch.isFiltered)" >Submit <i class="fa-solid fa-spinner"
                        *ngIf="isSubmitting"></i></button>
            </div>
        </div>
    </div>
</div>
<site-survey [elementInput]="btnSubmitRef"></site-survey>
</div>
