<div class="modal-header">
  <div class="align-top">
    <h3 class="modal-title">{{'headers.attestation' | translate}}</h3>
  </div>
</div>
<div class="modal-body align-middle">

  <form name="confirmDirectoryInfoForm" #form="ngForm" dnErrorControl="confirmDirectoryInfoErrorControl"
    #errorControl="dnErrorControl" scrollToErrorForm id="collapseUpload">
    <div class="card" [ngbCollapse]="!isCollapsed">
      <div class="card-body">
        <div class="form-group">
          <span [innerHTML]="'content.attestation' | translate"></span>
        </div>
        <br>
        <div class="col-sm-6">
          <div class="form-floating required">
            <input [autofocus]="true" class="form-control" placeholder="Full name" maxlength="100" name="fullName"
              id="fullName" [(ngModel)]="fullName" type="text" dnDisplayErrors required>
            <label for="fullName"><span>Full Name</span></label>
          </div>
        </div>
        <br>
        <div class="col-sm-10">
          <div class="form-floating">
            <textarea class="form-control" id="comments" name="comments" type="text" maxlength="500"
              style="height: 150px" placeholder="Comments (Optional)" [(ngModel)]="comments">
              </textarea>
            <label for="comments">Comments</label>
          </div>
        </div>
        <br>
        <div class="row " *ngIf="uploadedFiles.length">
          <div class="col-sm-4  mb-3 form-check form-check-inline " style="margin-left:-10.5px;">
            <label for="" class="form-check-label">Count of uploaded documents: {{uploadedFiles.length}}</label>
          </div>
        </div>
      </div>
    </div>

    <div [ngbCollapse]="isCollapsed">
      <file-upload #fileUpload [savedDocuments]="uploadedFiles" [token]="token" fileUploadListener>
      </file-upload>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-info text-white" title="Click to Upload Files"
        (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseUpload"
        *ngIf="isCollapsed">Upload File</button>
        <button type="button" class="btn btn-secondary" (click)="cancelEdits($event, form)"
        *ngIf="isCollapsed">Cancel</button>
      <button type="submit" class="btn btn-primary" (click)="submit(form)" *ngIf="isCollapsed">Submit</button>

      <button type="button" class="btn btn-info text-white" title="Return to Confirm"
        (click)="isCollapsed = !isCollapsed;" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseUpload"
        *ngIf="!isCollapsed"><i class="fa-solid fa-arrow-left"></i> Return to Confirm</button>
    </div>
  </form>
</div>