import { TitleCasePipe } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import cloneDeep from 'lodash-es/cloneDeep';
import findIndex from 'lodash-es/findIndex';
import { map } from 'rxjs';
import { Affiliation, EMAIL_ROLES, Provider, Location, EditedLocation, PracticeEmailChangeRequest, ProviderEmailChangeRequest } from '../directory.interface';
import { DirectoryService } from '../services/directory.service';
import { LocationManagerModalComponent } from '../location-manager-modal/location-manager-modal.component';
import { PracticeEmailEditModal } from "../practice-email-edit-modal/practice-email-edit-modal.component";
import { ProviderEmailEditModel } from '../provider-email-edit-modal/provider-email-edit-modal.component';

@Component({
  selector: 'directory-base',
  template: ''
})
export class DirectoryBaseComponent implements OnInit, OnDestroy {

  protected directoryService !: DirectoryService;
  protected titlecasePipe: TitleCasePipe = new TitleCasePipe();
  protected email !: string | null;
  protected route !: ActivatedRoute
  protected modalService !: NgbModal

  readOnly: boolean = false;
  directoryStateKey!: string;
  affiliations !: Affiliation[];
  locations !: Location[];
  providers !: Provider[];

  isLocEmailRole: boolean = false;
  isPracticeEmailRole: boolean = false;
  isProviderEmailRole: boolean = false;  

  constructor() { }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.email = routeParams.get('email');
    this.readOnly = this.route.snapshot.queryParamMap.get("readOnly") ? true : false;
    this.directoryStateKey = this.directoryService.buildKeyPrefix(this.email);
    this.directoryService.getEmailRoles().subscribe((emailRoles) => {
        if (emailRoles.includes(EMAIL_ROLES.offceManager) || emailRoles.includes(EMAIL_ROLES.practice)){
            this.isLocEmailRole = true;
        }
        this.isPracticeEmailRole = emailRoles.includes(EMAIL_ROLES.practice);
        this.isProviderEmailRole = emailRoles.includes(EMAIL_ROLES.provider);
    });    
  }

  protected retriveLocationManagers(locationId: string) {
    return this.directoryService.getLocationManagerDetails(locationId).pipe(
      map((locManagers) => {
          return locManagers;
      })
  );
  }

  protected changeToUpperCase(type: string) {
    switch (type) {
      case "officeName": return "Office Name ";
      case "boardStatus": return "Board Status ";
      case "firstname": return "First Name ";
      case "lastname": return "Last Name ";
      case "taxId": return "Tax ID ";
      case "npi": return "NPI ";
      default:
        return this.titlecasePipe.transform(type) + " ";
    }
  }

  hasProviderAccess(affiliation:Affiliation) {
    return affiliation.emailRoles && affiliation.emailRoles.includes(EMAIL_ROLES.provider);
  }

  hasPracticeAccess(affiliation:Affiliation) {
    return affiliation.emailRoles && affiliation.emailRoles.includes(EMAIL_ROLES.practice);
  }

  hasLocationAccess(affiliation:Affiliation) {
    return affiliation.emailRoles && (affiliation.emailRoles.includes(EMAIL_ROLES.offceManager) || affiliation.emailRoles.includes(EMAIL_ROLES.practice));
  }


  openLocationManagerModal(affiliation: Affiliation) {
    let locationIndex = findIndex(this.locations, (location) => location.locationId === affiliation.locationId);
    //let locManager = {} as LocationManager

    if (locationIndex > -1) {
      const modalRef = this.modalService.open(LocationManagerModalComponent, { size: 'lg', centered: true, backdrop: 'static', keyboard: false });
      modalRef.componentInstance.affilation = cloneDeep(affiliation);
      let location = cloneDeep(this.locations[locationIndex]) as EditedLocation;
      modalRef.componentInstance.location = location;
      modalRef.componentInstance.affiliations = this.affiliations;
      modalRef.componentInstance.providers = this.providers;      
      modalRef.componentInstance.readOnly = this.readOnly; 
      modalRef.result.then((result) => {
      }, (reason) => {
      }).catch(()=>{});
    }
  }

  openPracticeEmailChangeModal(affiliation: Affiliation) {
    let locationIndex = findIndex(this.locations, (location) => location.locationId === affiliation.locationId);
    let providerIndex  = findIndex(this.providers, (provider) => provider.providerId === affiliation.providerId);

    if (locationIndex > -1 && providerIndex > -1) {
      const modalRef = this.modalService.open(PracticeEmailEditModal, { size: 'lg', centered: true, backdrop: 'static', keyboard: false });
      modalRef.componentInstance.location = this.locations[locationIndex];
      modalRef.componentInstance.practiceId = this.locations[locationIndex].practiceId;
      modalRef.componentInstance.providerId = this.providers[providerIndex].providerId;
      modalRef.componentInstance.readOnly = this.readOnly;      
      modalRef.result.then((result) => {
      }).catch(()=>{});
    }
  }

  openProviderEmailChangeModal(affiliation: Affiliation) {
    let providerIndex = findIndex(this.providers, (provider) => provider.providerId === affiliation.providerId);

    if (providerIndex > -1) {
      const modalRef = this.modalService.open(ProviderEmailEditModel, { size: 'lg', centered: true, backdrop: 'static', keyboard: false });
      modalRef.componentInstance.provider = this.providers[providerIndex];
      modalRef.componentInstance.readOnly = this.readOnly;
      modalRef.result.then((result) => {
      }).catch(()=>{}); 
    }
  }

  @HostListener('window:beforeunload')    
  ngOnDestroy(): void {
    if (this.modalService) {
      if (this.modalService.hasOpenModals()) {
        this.modalService.dismissAll();
      }
    }
  }
}
