import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { load } from 'js-yaml';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class YmlLoaderService implements TranslateLoader {
  private baseUrl = "assets/labels/labels.yml";
  private cache !: any;
  
  constructor(private httpClient: HttpClient) {
  }

  getTranslation(lang: string): Observable<any> {
    return this.getLabels();
  }

  private loadYml() {
    return this.httpClient.get(this.baseUrl, {
      observe: "body",
      responseType: "text"
    }).pipe(
      catchError(() => { 
          console.error("Could not find translation file");
          throw new Error("Could not find translation file");
       }),
      map( (resp) => {        
        this.cache = load(resp ? resp : "");
        return this.cache;
      })
    );
  }

  private getLabels() {
    if (!this.cache) {
       return this.loadYml(); 
    }  
    return of(this.cache);
  }
}

