<div class="modal-header border-bottom">
    <div class="align-top">
      <h3 class="modal-title">{{'headers.locationManager' | translate}}</h3>
    </div>
    <span class="fw-light fs-6 text-muted">
      <span>{{location.address1 | uppercase}}</span><br>
      <span *ngIf="location.address2"> {{location.address2 | uppercase}}<br></span>
      <span>{{location.city | cityStateZip: location.state : location.zip | uppercase}}</span><br>
    </span> 
</div>

<div class="modal-body">
  <dn-confirmation></dn-confirmation>
  <div class="row" style="padding-top:15px;" *ngIf="showDuplicateWarning">
    <div class="col-sm-12 justify-content-around">
      <div class="align-items-center justify-content-start alert alert-warning"
           style="padding-top:15px; padding-bottom:15px;"
           role="alert" data-mdb-color="warning">
        <i class="fa-solid fa-triangle-exclamation fa-lg text-danger"></i>&nbsp;&nbsp;
        <span>An office manager has already been assigned to this location. Please click <button  class="btn btn-link" (click)="refreshForm()">here</button> to refresh the page.</span>
      </div>
    </div>
  </div>
  
    <form name="locManagerForm" #form="ngForm" dnErrorControl="locManagerErrorControl" #errorControl="dnErrorControl">    

      <div class="row" style="padding-bottom: 15px" id="instructions">
        <div class="col-sm-12">
          <span style="margin-bottom: 5px" [innerHTML]="'content.locationManager.instructions' | translate"> </span>
        </div>
      </div>
      <div class="card" id="locManager">
        <div class="d-flex justify-content-center" *ngIf="isLoading || isSubmitting">
          <div class="spinner-border text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="card-body" *ngIf="!isLoading">

          <div class="col-sm-6 form-floating mb-3">
            <p-dropdown id="lan" [options]="existingOfficeManagers" 
                optionLabel="name"
                optionValue="name" 
                name="selectedManager"
                placeholder="Select Office Manager"
                
                #drpdown class="form-control" 
                [(ngModel)]="selectedOption" 
                (onChange)="onSelectedLocationManager($event,form)"               
                >
            </p-dropdown >
            <label for="lan" >Select Office Manager</label>
          </div>
          <br>
          <div class="col-sm-8 form-floating  mb-3 required">
            <input class="form-control " type="text" maxlength="200" id="OfficeManagerName" name="OfficeManagerName"
              [(ngModel)]="locManager.lastName" dnDisplayErrors required [autofocus]="_focus" #officeManagerName>
            <label for="OfficeManagerName">Office Manager Name</label>
          </div>   

          <div class="col-sm-7 form-floating  mb-3 required">
            <input class="form-control" autofocus="true" type="text" maxlength="200" id="OfficeManagerEmail" name="OfficeManagerEmail"
              [(ngModel)]="locManager.primaryEmail" dnDisplayErrors required pattern=".+@.+\..+$"
              [errorMessages]="{ pattern : 'Please enter a valid Email ID.' }" >
            <label for="OfficeManagerEmail">Office Manager Email</label>
          </div>       
          <div class="col-sm-5 form-floating  mb-3 required">
            <input class="form-control" type="text" maxlength="12" id="OfficeManagerPhone" name="OfficeManagerPhone"
              [(ngModel)]="locManager.primaryPhone" dnDisplayErrors required formatPhone>
            <label for="OfficeManagerPhone">Office Manager Phone</label>
          </div>  
                    
        </div>
      </div>

      <div class="modal-footer" *ngIf="!isLoading">
        
        <button type="button" class="btn btn-secondary" (click)="cancelEdits($event, form)"
        [disabled]="isSubmitting" >Cancel</button>
        <button type="submit" class="btn btn-primary" (click)="saveEdits($event, form)"
        [disabled]="isSubmitting || readOnly" >Update <i class="fa fa-spinner" aria-hidden="true" *ngIf="isSubmitting"></i></button>
      </div>    

    </form>      
</div>