<div class="modal-header border-bottom">
  <div class="align-top">
    <h3 class="modal-title"> {{'headers.providerEmailEdit' | translate}}</h3>
  </div>
  <span class="fw-light fs-6 text-muted">
    <span>{{
      provider.firstname|
      providerName :provider.middlename:provider.lastname:provider.suffix:provider.title | uppercase
      }}</span><br>
    <span>NPI: {{provider.npi | uppercase}}</span><br>
  </span>
</div>

<div class="modal-body">
  <dn-confirmation #dnConfirm></dn-confirmation>
 <form name="provEmailEditForm" #provEmailEditForm="ngForm" dnErrorControl="errorControl" #errorControl="dnErrorControl">
    <div class="row" style="padding-bottom: 15px" id="instructions">
      <div class="col-sm-12">
        <span style="margin-bottom: 5px">{{'content.provider-email-edit.instruction' | translate}} <br></span>
      </div>
    </div>

    <div class="card" id="providerEmailEditForm">

      <div class="card-body">
        <ngb-alert #ngbAlert type="danger" *ngIf="showCompletionError" (closed)="showCompletionError=false">
          {{completionErrorMessage}}
        </ngb-alert>
        <div class="col-sm-6 form-floating   mb-3 required">
          <input class="form-control" type="text" maxlength="100" id="userName" name="userName"
                 [(ngModel)]="emailChangeRequest.submittedBy" dnDisplayErrors required  >
          <label for="userName">Your Full Name</label>
        </div>
         <div class="col-sm-6 form-floating  mb-3 required">
          <input class="form-control" type="text" maxlength="12" id="npiId" name="npiId"
                 [(ngModel)]="emailChangeRequest.npi" dnDisplayErrors  required npi>
          <label for="npiId">NPI</label>
        </div>
        <div class="col-sm-6 form-floating mb-3 required">
          <input  #currentEmail="ngModel" class="form-control" type="text" maxlength="200" id="oldProviderEmail" name="oldProviderEmail"
            [(ngModel)]="emailChangeRequest.currentEmail" dnDisplayErrors  pattern=".+@.+\..+$" required
            [errorMessages]="{ pattern : 'Please enter valid email id.' }" >
          <label for="oldproviderEmail">Current Provider Email</label>
        </div>


        <div class="col-sm-6 form-floating  mb-3 required">
          <input #newEmail="ngModel" class="form-control" type="text" maxlength="200" id="newProviderEmail" name="newProviderEmail"
                 [(ngModel)]="emailChangeRequest.newEmail" dnDisplayErrors required pattern=".+@.+\..+$"
                 [errorMessages]="{ pattern : 'Please enter valid email id.', textNotMatch : 'Current Provider Email and New Provider Email cannot be same.' }"
                 [textMatch]="currentEmail" [sameText]="false">
          <label for="newProviderEmail">New Provider Email</label>
        </div>

        <div class="col-sm-6 form-floating  mb-3 required">
          <input class="form-control" type="text" maxlength="200" id="confirmEmailId" name="confirmEmailId"
                 [errorMessages]="{ textMatch : 'New Provider Email and Confirm Email are not matching.' }"
                 [(ngModel)]="confirmEmail" dnDisplayErrors  required
                 [textMatch]="newEmail">
          <label for="confirmEmailId">Confirm Email</label>
        </div>
      </div>
    </div>

    <div class="modal-footer">

      <button type="button" class="btn btn-secondary" (click)="cancelEdits($event, provEmailEditForm)" [disabled]="isRunning"
        >Cancel</button>
      <button type="submit" class="btn btn-primary" (click)="saveEdits($event, provEmailEditForm)" [disabled]="isRunning || readOnly"
        >Update <i class="fa fa-spinner" aria-hidden="true" *ngIf="isRunning"></i></button>
    </div>

  </form>
</div>
