<div style="padding-left: 10px;">        
    <div title="Office Location Name" class="fw-bold" highlighter>
            <span id="divOfficeName" *ngIf="locationInfo.officeName">{{locationInfo.officeName | uppercase}}</span>
            <span class="text-danger required-field" *ngIf="!locationInfo.officeName">Office Name:</span>
        <br>
    </div>
    <div class="row mt-2">
        <div class="col-sm-1" title="Office Location Address"
            [ngClass]="{'text-danger': !locationInfo.address1 || !locationInfo.city || !locationInfo.state || !locationInfo.zip}">
                <i class="fa-solid fa-location-dot"></i>
        </div>
        <div class="col-sm-auto location-address-pad" title="Office Location Address" highlighter>
            <span *ngIf="locationInfo.address1" highlighter>{{locationInfo.address1 | uppercase}}<br></span>
            <span *ngIf="!locationInfo.address1"><span class="text-danger required-field">Address 1:</span><br></span>
            <span id="address2" *ngIf="locationInfo.address2"> {{locationInfo.address2 | uppercase}}<br></span>
            <span *ngIf="!locationInfo.city"><span class="text-danger required-field">City:</span><br></span>
            <span *ngIf="!locationInfo.state"><span class="text-danger required-field">State:</span><br></span>            
            <span *ngIf="!locationInfo.zip"><span class="text-danger required-field">Zip:</span><br></span>                        
            <span *ngIf="locationInfo.city || locationInfo.state || locationInfo.zip"  highlighter>{{locationInfo.city | cityStateZip: locationInfo.state : locationInfo.zip | uppercase}}<br></span>            
        </div>
    </div>
    <div class="row mt-1" highlighter>
        <div id="phone" title="Office Phone" *ngIf="locationInfo.officePhone"><i class="fa-solid fa-phone"></i>
            {{locationInfo.officePhone | phoneFormat}}</div>
        <div id="fax" title="Office Fax" *ngIf="locationInfo.officeFax"><i class="fa-solid fa-fax"></i>
            {{locationInfo.officeFax
            | phoneFormat}}
        </div>
        <div id="email" *ngIf="locationInfo.officeEmail" title="Office Email"><i class="fa-solid fa-envelope"></i>
            {{locationInfo.officeEmail}}</div>
        <!-- <div id="email" *ngIf="!locationInfo.officePhone && !locationInfo.officeFax && !locationInfo.officeEmail">
            <span class="text-danger required-field" title="Required at least one of: Phone Number, Fax Number, Office Email Address">
                <i class="fa-solid fa-phone"></i>&nbsp;
                <i class="fa-solid fa-fax"></i>&nbsp;
                <i class="fa-solid fa-envelope"></i>
            </span>
        </div> -->
        <div id="website" *ngIf="locationInfo.website" title="Website"><i class="fa-solid fa-globe" title="Website"></i>
            {{locationInfo.website}}</div>
        <div class="mt-2" id="taxId" title="Tax ID" [ngClass]="{'text-danger required-field': !locationInfo.taxId}">             
             Tax ID: {{
            locationInfo.taxId | taxIdMask}}
        </div>
        <div id="locNpi" title="Type 2 NPI">
            NPI: {{locationInfo.groupNpi | npi}}
        </div>
        <div id="handicap" title="Handicap Accessible" class="mt-2">
            Handicap Accessible: {{locationInfo.handicapAccessible !== 'null' ? locationInfo.handicapAccessible : ''}}
        </div>
        <div id="locLangs" title="Languages spoken at this location"
            [ngClass]="{'mt-2':!locationInfo.handicapAccessible}" *ngIf="getLanguages().length >0 ">Additional
            Languages:<br> {{getLanguages()| titlecase}}

        </div>
        <div class="mt-2" id="officeHours" *ngIf="locationInfo.officeHours && showWorkHoursText" title="Office Hours">
            Office Hours:
            <br>
            {{locationInfo.officeHours }}
        </div>
    </div>
    <div class="row mt-2">
        <div id="officeHours" title="Office Hours" *ngIf="locationInfo.workHours && !showWorkHoursText">Office Hours:
           <!-- <div *ngIf="locationInfo.workHours.length<=0">Not Listed</div>-->
            <ul class="p-0" style="list-style: none;">
                <li *ngFor="let wh of (locationInfo.workHours | workHours) | keyvalue : asIsOrder">
                    <span>{{wh.value}}</span>
                    <span *ngIf="wh.value.length>0"> : </span>
                    <span>{{wh.key}}</span>
                </li>
            </ul>
        </div>
    </div>

    <div class="row" *ngIf="isProviderOnly">
        <span class="col align-top text-muted">            
            <i [ngClass]="{'fa-solid fa-circle-exclamation':true, 'fa-spin': initializingText}"></i>&nbsp;                                     
            <em>
                <span *ngIf="allowOfficeManagerAdd">You may not have access to view all the providers at this location. Please assign a designated <button class="btn btn-link align-top border-0 p-0 text-primary text-decoration-none" pTooltip="Add Office Manager" tooltipPosition="bottom"  (click)="openLocationManagerModal()">office manager<i class="fa-solid fa-people-roof fa-sm text-info"></i></button> to manage this location’s information.</span>      
                <span *ngIf="!allowOfficeManagerAdd">You may not have access to view all the providers at this location. The assigned office manager for this location is 
                    <strong>{{managerName}}</strong>. They have access to manage the information for all the providers at this location.</span>   
           </em> 
        </span>
    </div>
</div>
