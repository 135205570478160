<div class="card border-0">
    <div class="card-body">
        <h5 class="card-title">Test Page</h5>
        <dn-confirmation #dnConfirm></dn-confirmation>
        <ul>
            <!-- <li class="mt-4">
                <h6>File Upload</h6>
                <button type="button" class="m-3 btn btn-primary" (click)="openUploadModal()">Upload</button>
            </li> -->
            <li class="mt-4">
                <h6>Directory </h6>
                <input (keyup)="0" type="email" required size="40" name="email" pattern=".+@.+\..+$"
                    placeholder="Please enter a provider or practice email address" #emailRef />
                <button type="submit" class="m-3 btn btn-primary" (click)="routeToDirectory(emailRef)"
                    [disabled]="!emailRef.validity.valid">Directory</button>
            </li>
            <li>
                <h6>Clear Storage </h6>
                <button type="button" class="m-3 btn btn-primary" (click)="clearStorage()">Clear Storage</button>
            </li>
        </ul>
        <br>
    </div>
</div>