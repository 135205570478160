import { Injectable, TemplateRef } from "@angular/core";
import { ValidationErrors } from "@angular/forms";

export interface IHelperClasses {
    hasError : string[]
    errorText : string[]
    alerts : string[] 
}
 
@Injectable({
    providedIn: 'root'
})
export class DnErrorMessageService {
    private _errors:ValidationErrors = {};
    private defaultClasses: IHelperClasses = {    
        hasError : ["is-invalid"],    
        errorText : ["invalid-feedback", "dn-error-display"],
        alerts: ["alert", "alert-danger"]
    };
    private _helperClasses: IHelperClasses = {...this.defaultClasses};
    private _fieldTemplate !: TemplateRef<any>
    private _formTemplate !: TemplateRef<any>

    constructor() {}

    public errors(_errors ?:ValidationErrors):ValidationErrors {
        if (_errors) {
            this._errors = _errors;
        }
        return this._errors;
    }

    public errorMessage(error: string):string {
        return this._errors[error];
    }

    public fieldTemplate(_fieldTemplate ?: TemplateRef<any>) {
        if (_fieldTemplate) {
            this._fieldTemplate = _fieldTemplate;
        }
        return this._fieldTemplate;
    }

    public formTemplate(_formTemplate ?: TemplateRef<any>) {
        if (_formTemplate) {
            this._formTemplate = _formTemplate;
        }
        return this._formTemplate;
    }   

    public helperClasses() {
        return this._helperClasses;
    }

    public setHelperClasses(classes?:IHelperClasses) {
        this._helperClasses = classes ? {...this._helperClasses, ...classes} : {...this.defaultClasses}
    }
}

