import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'dnp-footer',
  templateUrl: './dnp-footer.component.html',
  styles: [
  ]
})
export class DnpFooterComponent implements OnInit {

  constructor() { }

  surveyUrl: String =""
    
  ngOnInit(): void {
    this.surveyUrl = environment.surveyUrl;
  }

}
