<div class="card border-0" style="margin-left: auto;margin-right: auto;">
    <dn-confirmation #dnConfirm></dn-confirmation>
    <div class="card-body pb-5 mt-4 border-0" style="max-width: 85%;">
        <div class="row pb-3">
            <div class="col-3">&nbsp;</div>
            <div class="col-6 lh-base fs-5 mt-5 text-left pb-5"><p>Thank you for participating in the pilot program of the ProviderDNA directory management tool. The directory management tool has now been sunset.<br></p>
                <p>
                If you have any questions, please contact <a href="mailto:{{'headers.main.email' | translate}}" class="link" style="padding-left: 3px; "> {{'headers.main.email' | translate}}</a></div>
            <!-- <div class="col-md-6 form-floating" style="align-self:center;">
                <p style="text-align: left;" [innerHTML]="'home.content' | translate"></p>
            </div>
            <div class="col-md-6">
                <request-directory-link [dnConfirmation]="dnConfirm"></request-directory-link>
            </div> -->
        </div>
    </div>
</div>
