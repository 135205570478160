import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { DnErrorControlDirective } from "src/app/dn-error-display/directives/dn-error-control.directive";
import { Affiliation, EditedLocation, LocationManager, Location, Directory, Provider, IOfficeManager } from "../directory.interface";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DirectoryService } from "../services/directory.service";
import isEqual from "lodash-es/isEqual";
import cloneDeep from "lodash-es/cloneDeep";
import { map, of, Subscription } from "rxjs";
import filter from "lodash-es/filter";
import findIndex from "lodash-es/findIndex";
import find from 'lodash-es/find';
import { CACHE_NAME, DirectoryCacheService } from "../services/directory-cache.service";
import isEmpty from "lodash-es/isEmpty";
import { DnConfirmationComponent } from "src/app/shared/components/dn-confirmation/dn-confirmation.component";


@Component({
    selector: 'location-manager',
    styles: [
        '.modal-header { border-bottom: 0 none;  }',
        '.modal-footer { border-top: 0 none; }'
        
    ],
    styleUrls: ['./location-manager-modal.component.css'],
    templateUrl: "location-manager-modal.component.html"
})

export class LocationManagerModalComponent implements OnInit {    
    @Input() affilation !: Affiliation;
    @Input() location !: EditedLocation;
    @Input() affiliations !: Affiliation[];
    @Input() providers !: Provider[];
    @Input() readOnly: boolean = false;
    @ViewChild('errorControl', { static: false }) _errorControl !: DnErrorControlDirective;
    @ViewChild('form',{ read: ElementRef }) formElement !: ElementRef;
    @ViewChild('officeManagerName',{ read: ElementRef }) officeManagerName !: ElementRef<HTMLElement>;
    @ViewChild(DnConfirmationComponent, {static: false, read: DnConfirmationComponent}) dnConfirmation !: DnConfirmationComponent;
  

    locManager : LocationManager = {};
    oldLocManager !: LocationManager;
    duplicateLocManager !: LocationManager;
    
    private provAffiliations !: Affiliation[]
    private associatedProviders !: Provider[]
    isLoading = false;
    isSubmitting = false;
    isEditing = false;
    showDuplicateWarning = false;
    existingOfficeManagers : IOfficeManager[] = [];
    selectedOfficeManager : IOfficeManager | null= null;
    selectedOption : string | null = null; 
    selectLimit : number = 1;
    _focus: boolean = true;

    constructor(public activeModal: NgbActiveModal, 
                private directoryService: DirectoryService,                 
                private cacheService: DirectoryCacheService) { };

    ngOnInit(): void { 
        this.duplicateLocManager =  {} as LocationManager;
        this.isLoading = true; 
        this.existingOfficeManagers = this.cacheService.officeManagers;  
        //this.selectedOfficeManager = null;        
        this.hasOfficeManagerAssigned(this.location.locationId).subscribe(data => {
            this.isLoading = false;             
            this.locManager  = data;
            if(this.locManager.primaryEmail){
                this.isEditing = true;           
            }   
            if (!isEmpty(data)) {
                this.refreshCache(data);
                this.selectedOfficeManager = {name: this.getOfficeManagerKey(data), value: data} ;
                this.selectedOption =    this.getOfficeManagerKey(data);
            }     
            this.oldLocManager = cloneDeep(data);         
        });

        
        this.provAffiliations = filter(this.affiliations, (aff) => aff.locationId === this.location.locationId);
        
        this.associatedProviders = filter(this.providers, (prov)  => {
          return findIndex( this.provAffiliations, (aff) => { return (aff.providerId === prov.providerId ) }) >= 0;
        }); 
    }

    cancelEdits(e: any, form: NgForm) {
        this._errorControl.hideErrors();
        this.activeModal.close(false);
        return;
    }

    saveEdits(e: any, form: NgForm) {
        this._errorControl.showErrors();
        if (form.valid) {
            let isSame = isEqual(this.oldLocManager, this.locManager);
            if (!isSame) { 
                this.isSubmitting = true;                    
                this.hasOfficeManagerAssigned(this.location.locationId,this.isEditing).subscribe(locManager =>{
                    this.isSubmitting = false;
                    this.duplicateLocManager = locManager; 
                   if (this.duplicateLocManager.primaryEmail) {
                        this.showDuplicateWarning = true;
                   }else{
                        this.isSubmitting = true;  
                        this.saveOfficeManagerDetails().subscribe((s) => {
                            this.isSubmitting = false;      
                            if (s && s.status) {
                                this.refreshCache(this.locManager);
                                this.formElement.nativeElement.scrollIntoView({behavior: 'smooth'});
                                this.dnConfirmation.show('Successfully saved Office Manager details.', 'success', 5000).subscribe(()=>{
                                    this.activeModal.close(true);
                                });                         
                            }else{
                                this.activeModal.close(true);
                            }                        
                    }); 
                   }              
                });
            } else {
                this.activeModal.close(false);
                return;
            }
        }
        return;
    }
    
    refreshForm() {
        this.locManager = this.duplicateLocManager;
        this.duplicateLocManager = {} as LocationManager;
        this.isEditing = true;
        this.showDuplicateWarning = false;
    }

    private closeSubscription(subscription: Subscription) {
        if (subscription) {
            subscription.unsubscribe();
        }
    }  

    private saveOfficeManagerDetails() { 

        this.location.contactType = "Local Admin";
        this.location.officePhone = this.locManager.primaryPhone;
        this.location.officeEmail = this.locManager.primaryEmail;
        this.location.contactPersonName = this.locManager.lastName;
         
        let directory = {} as Directory;    
        directory.affiliations = this.provAffiliations;
        directory.providers = [];
        directory.providers.push(this.associatedProviders[0])
        directory.locations = [];
        directory.locations[0] = this.location;
 
        return this.directoryService.saveLocationManagerChanges(directory).pipe(
            map(data => {
                return data;
            })
        );
    }
      
    private hasOfficeManagerAssigned(locationId: string, isEditing: boolean=false) { 
        let locMgr = {} as LocationManager;
        if (isEditing) {                      
           return of(locMgr)
        }else{    
            return this.directoryService.getLocationManagerDetails(locationId).pipe(
                map((locManager) => { 
                    return locManager ? locManager  as LocationManager : locMgr;
                })
            ); 
        }
    }

    onSelectedLocationManager(e: any, form: NgForm) { 
        let selected =  e.value?find(this.existingOfficeManagers, (o) => {
            return o.name.toLowerCase() === e.value.toLowerCase();
        }) : {} as IOfficeManager;     
        if (!isEmpty(selected) && !(isEqual(selected.value,this.locManager))) {      
            this.locManager = selected.value ? selected.value : {} as LocationManager;              
        }        
        setTimeout(() => {
            this.officeManagerName.nativeElement.focus();            
        });
    }

    private getOfficeManagerKey(data:LocationManager) {        
        let name = data.lastName ? data.lastName + ' - ' : "";
        return name + data.primaryEmail;
    }
    private refreshCache(data:LocationManager) {
        if (!(isEmpty(data))) {
            this.cacheService.addToCache(CACHE_NAME.OFFICE_MANAGERS,data);
            this.existingOfficeManagers = this.cacheService.officeManagers;
        }
    }
}