<div class="modal-header border-bottom">
  <div class="align-top">
    <h3 class="modal-title">{{'headers.locationEdit' | translate}}</h3>
  </div>
</div>

<div class="modal-body">
  <max-edit-warn (maxEditReachedEvent)="isMaxEditReached($event)" [directoryStateKey]="directoryStateKey"></max-edit-warn>
  <form name="locationEditForm" #form="ngForm" dnErrorControl="locationErrorControl" #errorControl="dnErrorControl"
    scrollToErrorForm id="collapseUpload">
    <div class="row" style="padding-bottom: 15px" [ngbCollapse]="!isCollapsed || !editOfficeHours" id="instructions">
      <div class="col-sm-12">
        <span style="margin-bottom: 5px" [innerHTML]="'content.locationEdit.docUploadIns' | translate"> </span>
      </div>
    </div>
    <div class="card" [ngbCollapse]="!isCollapsed || !editOfficeHours" id="editLocation">
      <div class="card-body">
        <div class="col-sm-6 form-floating  mb-3 required">
          <input class="form-control " type="text" maxlength="200" id="OfficeName" name="OfficeName"
            [(ngModel)]="location.officeName" dnDisplayErrors autofocus="!isCollapsed" required>
          <label for="OfficeName">Office Name</label>
        </div>
        <div class="col-sm-8 form-floating  mb-3 required">
          <input class="form-control" type="text" maxlength="200" id="Address1" name="Address1"
            [(ngModel)]="location.address1" dnDisplayErrors required>
          <label for="Address1">Address 1</label>
        </div>
        <div class="col-sm-7 form-floating  mb-3">
          <input class="form-control" type="text" maxlength="100" id="Address2" name="Address2"
            [(ngModel)]="location.address2">
          <label for="Address2">Address 2</label>
        </div>
        <div class="row ">
          <div class="col-sm-4 form-floating  mb-3 required">
            <input class="form-control" type="text" maxlength="100" id="City" name="City" [(ngModel)]="location.city"
              dnDisplayErrors required>
            <label for="City" style="padding-left: 22px;">City</label>
          </div>
          <div class="col-sm-4 form-floating  mb-3 required">
            <select class="form-select" id="State" [(ngModel)]="location.state" name="State" dnDisplayErrors required>
              <option *ngFor="let st of allStates" [value]="st.abbreviation">{{st.name}}</option>
            </select>
            <label for="State" style="padding-left: 22px;">State</label>
          </div>
          <div class="col-sm-2 form-floating  mb-3 required">
            <input class="form-control" type="text" id="Zip" name="Zip" [(ngModel)]="location.zip" zipcode
              dnDisplayErrors required>
            <label for="Zip" style="padding-left: 22px;">Zip</label>
          </div>
        </div>

        <fieldset class="row" #contactInfoForm="ngModelGroup" ngModelGroup="contactInfo">
          <div class="col-sm-3 form-floating  mb-3">
            <input class="form-control " type="text" id="PhoneNumber" name="PhoneNumber"
              [(ngModel)]="location.officePhone" formatPhone  dnDisplayErrors >
            <label for="PhoneNumber" style="padding-left: 22px;">Phone Number</label>
          </div>
          <div class="col-sm-3 form-floating  mb-3">
            <input class="form-control" type="text" id="FaxNumber" name="FaxNumber" [(ngModel)]="location.officeFax"
              formatPhone dnDisplayErrors
              [errorMessages]="{ phoneFormat : 'Please enter a valid Fax Number (e.g. 123-456-7890)'}">
            <label for="FaxNumber" style="padding-left: 22px;">Fax Number</label>
          </div>
          <div class="col-sm-4 form-floating  mb-3">
            <input class="form-control" type="email" email maxlength="100" id="OfficeEmail" name="OfficeEmail"
                   [(ngModel)]="location.officeEmail" pattern=".+@.+\..+$" dnDisplayErrors
                   [errorMessages]="{ pattern : 'Please enter a valid Email ID.' }">
            <label for="OfficeEmail" style="padding-left: 22px;">Office Email Address</label>
          </div>
          <div class="form-floating mt-n3 mb-3">
            <input class="form-control" type="hidden" name="Err" ngModel dnDisplayErrors #ErrTarget="ngModel" [oneRequired]="ErrTarget">
          </div>
        </fieldset>

        <div class="row ">

          <div class="col-sm-6 form-floating  mb-3">
            <input class="form-control" type="text" maxlength="400" id="Website" name="Website"
              [(ngModel)]="location.website" dnDisplayErrors [errorMessages]="{ pattern : 'Please enter a valid website url'}"
                   pattern="(https?:\/\/(www\.)?|(www\.))?[-a-zA-Z0-9]+\.[\S]+">
            <label for="Website" style="padding-left: 22px;">Website</label>
          </div>
        </div>
        <div class="row ">
          <div class="col-sm-3 form-floating  mb-3 required">
            <input class="form-control " type="text" id="TaxID" name="TaxID" [(ngModel)]="location.taxId"
              dnDisplayErrors required taxId>
            <label for="TaxID" style="padding-left: 22px;">Tax ID</label>
          </div>
          <div class="col-sm-3 form-floating  mb-3">
            <input class="form-control" type="text" maxlength="10" id="Type2NPI" name="Type2NPI"
              [(ngModel)]="location.groupNpi" npi dnDisplayErrors>
            <label for="Type2NPI" style="padding-left: 22px;">Type 2 NPI</label>
          </div>
        </div>
        <div class="row ">
          <div class="col-sm-4  mb-3 form-check form-check-inline " style="margin-left:10.5px;">
            <input type="checkbox" id="HandicapAccessible" name="HandicapAccessible" [(ngModel)]="checkHandicap"
              [checked]="checkHandicap" class="form-check-input">
            <label for="HandicapAccessible" class="form-check-label">Handicap Accessible</label>
          </div>
        </div>
        <div class="row mb-3">
          <languages-update #locationLanguages [currentLanguages]="getLanguages()">
          </languages-update>
        </div>
        <div class="row ">
          <div class="col-sm-8 form-floating  mb-3">
            <textarea readonly class="form-control" (click)="editOfficeHours = !editOfficeHours;" id="workHours"
              name="workHours" [ngModel]="officeHoursStr && officeHoursStr.length > 0 ? officeHoursStr : 'Not Listed'"
              (ngModelChange)="setOfficeHours($event)" [ngModelOptions]="{updateOn: 'blur'}"
              title="Please click to edit office hours" style="background-color: white; height: 120px;">
                    {{officeHoursStr}}
                  </textarea>
            <label for="lan" style="margin-left: 10.5px">Office Hours</label>
          </div>
        </div>
        <div class="row " *ngIf="uploadedFiles.length">
          <div class="col-sm-4  mb-3 form-check form-check-inline " style="margin-left:-10.5px;">
            <label for="" class="form-check-label">Count of uploaded documents: {{uploadedFiles.length}}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: 1em;">
      <div [ngbCollapse]="editOfficeHours">
        <office-hours [locationOfficeHours]="officeHours"></office-hours>
      </div>
    </div>
    <div class="row" style="margin-top: 1em;">
      <div [ngbCollapse]="isCollapsed">
        <file-upload #fileUpload [savedDocuments]="uploadedFiles" [token]="token" fileUploadListener>
        </file-upload>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-info text-white" title="Click to Upload Files"
        (click)="isCollapsed = !isCollapsed;" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseUpload"
        *ngIf="isCollapsed && editOfficeHours">Upload File</button>
      <button type="button" class="btn btn-secondary" (click)="cancelEdits($event, form)"
        *ngIf="isCollapsed && editOfficeHours">Cancel</button>
      <button type="submit" class="btn btn-primary" (click)="saveEdits($event, form)"
        *ngIf="isCollapsed && editOfficeHours" [disabled]="disableContinue()">Continue</button>

      <button type="button" class="btn btn-info text-white" title="Return to Edit Location'"
        (click)="isCollapsed = !isCollapsed;" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseUpload"
        *ngIf="!isCollapsed"><i class="fa-solid fa-arrow-left"></i> Return to Edit</button>
    </div>
  </form>
</div>
