import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { DnErrorControlDirective } from "src/app/dn-error-display/directives/dn-error-control.directive";
import { Location, PracticeEmailChangeRequest } from "../directory.interface";
import { NgbActiveModal, NgbAlert } from "@ng-bootstrap/ng-bootstrap";
import { DirectoryService } from "../services/directory.service";

import { environment } from "../../../environments/environment";
import { DnConfirmationComponent } from "src/app/shared/components/dn-confirmation/dn-confirmation.component";


@Component({
  selector: 'practice-email-edit',
  styles: [
    '.modal-header { border-bottom: 0 none;  }',
    '.modal-footer { border-top: 0 none; }'
  ],
  templateUrl: "practice-email-edit-modal.component.html"
})

export class PracticeEmailEditModal implements OnInit {

  @ViewChild('errorControl', { static: true }) _errorControl!: DnErrorControlDirective;
  @ViewChild('_practiceEmailEditForm', { read: ElementRef }) practiceEmailEditForm!: ElementRef;
  @ViewChild('dnConfirm', {static: false, read: DnConfirmationComponent}) dnConfirmation !: DnConfirmationComponent;
  
  @Input()  practiceId!: string ;
  @Input()  providerId!: string ;
  @Input()  location!: Location;
  @Input() readOnly: boolean = false;
  emailChangeRequest!: PracticeEmailChangeRequest;
  confirmEmail: string = '';
  taxId: string = '';
  showCompletionError: boolean = false;
  completionErrorMessage = environment.common.errorMessages.changeCompletion;
  isRunning = false;
  constructor(public activeModal: NgbActiveModal, 
              private directoryService: DirectoryService) { };

  ngOnInit(): void {
    this.emailChangeRequest = {} as PracticeEmailChangeRequest;
    this.emailChangeRequest.entityId = this.practiceId;
    this.emailChangeRequest.providerId = this.providerId;
  }

  ngAfterViewInit(): void {
    //this._errorControl.showErrors(['confirmEmailError']);
  }


  cancelEdits(e: any, form: NgForm) {
    this._errorControl.hideErrors();
    this.showCompletionError = false;
    this.activeModal.close(false);    
    return;
  }

  saveEdits(e: any, form: NgForm) {
    this._errorControl.showErrors();

    if (form.valid) { 
        this.isRunning = true;
        this.directoryService.savePracticeEmailChangeRequest(this.emailChangeRequest).subscribe((resp) => {
          this.isRunning = false;
          if (resp?.status && resp?.status !== "ERROR") {
            this.dnConfirmation.show("Email Changed Successfully", 'success', 8000).subscribe(() =>{
            this.activeModal.close(true);
          });
           
          }else{
             this.showCompletionError = true;
             this.practiceEmailEditForm.nativeElement.scrollIntoView({behavior: 'smooth'});
             let sub = form.valueChanges?.subscribe(()=>{              
               this.showCompletionError = false;
               sub?.unsubscribe();
             });
          }
        });  
    }
  }

}
