<footer class="bd-footer bg-light py-0 border-0">
   <div class="mt-5" id="footer">
    <div class="container" style="max-width: 95%;">
        <div class="row">
            <!-- <div class="col-md-2 mb-2">
                <span class="d-inline-flex align-items-center mb-2 link-dark text-decoration-none" >
                    <img src="assets/images/ProviderDNA-Logo.png" height="20px" />
                </span>

                <p class="text-muted fw-lighter small">{{'footer.whatis' | translate}}</p>
            </div> -->
            <div class="col-md-11 mb-2 ms-3">
              <!-- <div class="text-muted fw-lighter small" style="padding-bottom:20px;">
                <a routerLink="/legal-and-privacy" class="link"  routerLinkActive="active" target="_providerDNALegalAndPrivasy">Legal and Privacy</a> | 
                <a href= "{{surveyUrl}}" class="link"   target="_blank" *ngIf="surveyUrl && surveyUrl.length > 0" >Feedback</a>               
              </div> -->
              <div class="text-muted fw-lighter small">
                <p [innerHTML]="'footer.disclaimers' | translate"></p>
              </div>
            </div>          
        </div>
    </div>
   </div>
</footer>
