import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Timer } from '../../shared.interface';

@Component({
  selector: 'time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.css']
})
export class TimePickerComponent implements OnInit {

  @Input() timeSlot: any;
  @Output() timeSlotChange: EventEmitter<string> = new EventEmitter<string>();
  time: Timer = {} as Timer;
  meridian = true;

  constructor() { }

  ngOnInit(): void {
    if (!this.timeSlot || this.timeSlot === '') {
      this.timeSlot = '';
      this.time = {} as Timer;
    } else {
      this.convertTime();
    }
  }

  timeChange(time: Timer) {
    if (time == null) {
      this.timeSlot = null;
    } else {
      let hour: number = 0;
      let minute: number = 0;
      let ampm: string = 'AM';
      if (time != null) {
        hour = time.hour > 12 ? time.hour - 12 : time.hour;
        hour = hour == 0 ? 12 : hour;
        ampm = time.hour >= 12 ? 'PM' : 'AM';
        minute = time.minute;
      }
      this.timeSlot = ("0" + hour).slice(-2) + ":" + ("0" + minute).slice(-2) + " " + ampm;
    }
    this.timeSlotChange.emit(this.timeSlot);
  }

  convertTime() {
    if (this.timeSlot !== "") {
      let myHour: number = 0;
      let myMin: number = 0;
      const timeArray = this.timeSlot.split(":");
      myHour = Number(timeArray[0]);
      myMin = Number(timeArray[1].split(" ")[0]);
      let myAP = timeArray[1].split(" ")[1];
      if (myAP == "PM")
        myHour = +myHour + 12;
      else
        myHour = myHour == 12 ? 0 : myHour;
      this.time = { hour: myHour, minute: myMin };
    }
    else {
      this.timeSlot = '';
      this.time = {} as Timer;
    }
  }
}