import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'link-expiry',
  templateUrl: './link-expiry.component.html',
  styles: [
  ]
})
export class LinkExpiryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
