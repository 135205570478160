<form name="officeHoursEditForm" #officeHoursEditForm="ngForm" dnErrorControl="officeHoursErrorControl"
    #testerrorControl="dnErrorControl" scrollToErrorForm id="officeHoursEditForm">
    <div [ngbCollapse]="false" id="editOfficeHours">
        <div *ngFor="let wh of myList; let i = index" class="timeSlotBorder">
            <div class="row">                
                <div class="col-md-7 pt-3 pb-3">
                    <div class="row">
                        <div class="col-md-2 mt-2 mb-1 widthAuto  text-muted fw-lighter small  marginSlot">Slot 1:</div>
                        <div class="col-md-4 mt-1 mb-1 widthAuto">
                            <time-picker [(timeSlot)]="wh.openSlot1" (timeSlotChange)="timeSlotChanged(wh)">
                            </time-picker>
                            <div *ngIf="!wh.openSlot1 && officeHoursEditForm.submitted">
                                <label class="errorLabel">{{requiredText}}</label>
                            </div>
                            <div *ngIf="wh.invalidSlot1 == true && invalidOpenSlotsText == '' && officeHoursEditForm.submitted">
                                <label class="errorLabel">{{invalidSlotTimeText}}</label>
                            </div>
                        </div>
                        <div class="col-md-2 mt-2 mb-1 widthAuto"><i class="fa fa-minus" aria-hidden="true"
                                style="color: #b3b9b9;"></i></div>
                        <div class="col-md-4 mt-1 mb-1 widthAuto">
                            <time-picker [(timeSlot)]="wh.closedSlot1" (timeSlotChange)="timeSlotChanged(wh)">
                            </time-picker>
                            <div *ngIf="!wh.closedSlot1 && officeHoursEditForm.submitted">
                                <label class="errorLabel">{{requiredText}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-2">
                        <div class="col-md-2 mt-2 mb-1 widthAuto  text-muted fw-lighter small  marginSlot">Slot 2:</div>
                        <div class="col-md-4 mt-1 mb-1 widthAuto">
                            <time-picker [(timeSlot)]="wh.openSlot2" (timeSlotChange)="timeSlotChanged(wh)">
                            </time-picker>
                            <div
                                *ngIf="(wh.closedSlot2 && (wh.openSlot2 == '' || wh.openSlot2 == null)) && officeHoursEditForm.submitted">
                                <label class="errorLabel">{{requiredText}}</label>
                            </div>
                            <div *ngIf="wh.invalidSlot2 == true  && officeHoursEditForm.submitted">
                                <label class="errorLabel">{{invalidSlotTimeText}}</label>
                            </div> 
                        </div>
                        <div class="col-md-2 mt-2 mb-1 widthAuto"><i class="fa fa-minus" aria-hidden="true"
                                style="color: #b3b9b9;"></i></div>
                        <div class="col-md-4 mt-1 mb-1 widthAuto">
                            <time-picker [(timeSlot)]="wh.closedSlot2" (timeSlotChange)="timeSlotChanged(wh)">
                            </time-picker>
                            <div
                                *ngIf="(wh.openSlot2 && (wh.closedSlot2 == '' || wh.closedSlot2 == null)) && officeHoursEditForm.submitted">
                                <label class="errorLabel">{{requiredText}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 marginSlot">
                            <div *ngIf="wh.overlapSlot == true && officeHoursEditForm.submitted">
                                <label class="errorLabel">{{overlapTimingText}}</label>
                            </div>
                            <div *ngIf="invalidOpenSlotsText != ''  && officeHoursEditForm.submitted">
                                <label class="errorLabel">{{invalidOpenSlotsText}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 pt-3 text-muted fw-lighter small">
                    <fieldset>
                        <legend>Applies to:</legend>
                        <div class="form-check form-check-inline daysMargin" *ngFor="let day of allDays; let j = index">
                            <input class="form-check-input" type="checkbox" id="appliesTo{{i}}{{j}}"
                                [checked]="wh.appliesTo && wh.appliesTo.indexOf(day.value) !== -1" [value]="day.value"
                                name="days{{i}}" (change)="onDayChecked($event, wh)">
                            <label class="form-check-label" for="appliesTo{{i}}{{j}}">{{day.name.substring(0,3)}}</label>
                        </div>
                        <div
                            *ngIf="(wh.appliesTo == null || wh.appliesTo?.length == 0) && officeHoursEditForm.submitted">
                            <label class="errorLabel marginAppliesto">{{requiredText}}</label>
                        </div>
                    </fieldset>
                </div>
                <div class="col-md-1 pt-3" style="text-align: center">
                    <i class="fa fa-trash-can myColor" aria-hidden="true" pTooltip="Delete slot" tooltipPosition="left"
                        (click)="deleteSlot(i)" *ngIf="myList.length > 1"></i>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 p-0">
                    <button type="button" class="myDiv btn btn-info btn-sm text-white" style="font-size: 12px;"
                        pTooltip="Add new slot" tooltipPosition="right"
                        (click)="resetFormAndcreateSlot(officeHoursEditForm)"
                        *ngIf="i == myList.length-1 && myList.length <= 6">Add Slot</button>
                </div>               
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary"
            (click)="cancelEdits($event, officeHoursEditForm)">Cancel</button>
        <button type="submit" class="btn btn-primary"
            (click)="saveOfficeHours($event, officeHoursEditForm)">Save</button>
    </div>
</form>