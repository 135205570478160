import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { DnErrorControlDirective } from "src/app/dn-error-display/directives/dn-error-control.directive";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Output } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import each from "lodash-es/each";
@Component({
    selector: 'office-hours',
    templateUrl: './office-hours.component.html',
    styleUrls: ['./office-hours.component.css']
})

export class OfficeHoursComponent implements OnInit {
    allDays: any[] = [{ name: 'Monday', value: 'Mo' }, { name: 'Tuesday', value: 'Tu' }, { name: 'Wednesday', value: 'We' }, { name: 'Thursday', value: 'Th' }, { name: 'Friday', value: 'Fr' }, { name: 'Saturday', value: 'Sa' }, { name: 'Sunday', value: 'Su' }];
    timeOptions: any[] = [];
    workHours !: WorkHoursObject[];
    @Input() locationOfficeHours !: any;
    @ViewChild('testerrorControl', { static: false }) _errorControl !: DnErrorControlDirective;
    @Output() onSaveOfficeHours: BehaviorSubject<WorkHoursObject[]> = new BehaviorSubject(this.workHours);
    @Output() onCancelOfficeHours: BehaviorSubject<string> = new BehaviorSubject("");
 
    myList: any = [];
    requiredText='This is a required field.';
    invalidSlotTimeText="Invalid slot timing";
    overlapTimingText="Overlap slot timing";
    invalidOpenSlotsText='';    

    constructor(public activeModal: NgbActiveModal) { };

    ngOnInit() {
        this.loadOfficeHours();
    };

    saveOfficeHours(e: any, form: NgForm) {
        if (form.valid && !this.isInvalidOfficeHours()) {
            this.workHours = [];
            each(this.myList, (d: any) => {
                each(d.appliesTo, (sD: any) => {
                    let workHour = {} as WorkHoursObject;
                    workHour.WeekDayName = this.allDays.find((t) => t.value == sD)?.name;
                    workHour.Slot1OpenHour = d.openSlot1;
                    workHour.Slot1CloseHour = d.closedSlot1;
                    workHour.Slot2OpenHour = d.openSlot2;
                    workHour.Slot2CloseHour = d.closedSlot2;
                    this.workHours.push(workHour);
                });
            });
            this.notifyParent('save', this.workHours);
        }
        return;
    };

    loadOfficeHours() {
        if (this.locationOfficeHours) {
            this.locationOfficeHours.forEach((value: any, key: any) => {
                if (key == "CLOSED") {
                    this.locationOfficeHours.delete(key);
                } else {
                    let officeHrsObj = {} as DefaultWorkHoursObject;
                    const keyArray = key.split("-");
                    officeHrsObj.openSlot1 = keyArray[0];
                    const subKeyArray = keyArray[1]?.split(", ");
                    officeHrsObj.closedSlot1 = subKeyArray[0];
                    officeHrsObj.openSlot2 = subKeyArray[1] ? subKeyArray[1] : '';
                    officeHrsObj.closedSlot2 = keyArray[2] ? keyArray[2] : '';
                    officeHrsObj.appliesTo = value;
                    this.myList.push(officeHrsObj);
                }
            });
        }
        if (this.myList?.length <= 0) {
            this.createSlot();
        }
    }

    private isInvalidOfficeHours(): boolean {
        let isInvalid = this.myList.some((x: { appliesTo: string | any[]; }) => !x.appliesTo || x.appliesTo.length == 0) ||
            this.myList.some((x: { openSlot1: any; closedSlot1: any; }) => !x.openSlot1 || !x.closedSlot1) ||
            this.myList.some((x: { openSlot2: any; closedSlot2: any; }) => (x.openSlot2 && !x.closedSlot2) || (!x.openSlot2 && x.closedSlot2));
        if (!isInvalid) {
            const today = new Date()
            each(this.myList, (d: any) => {
                d.invalidSlot2 = undefined;
                d.invalidSlot1 = undefined;
                d.overlapSlot = undefined;
                let time = this.get24hour(d.openSlot1);
                let openSlot1 = new Date(today.getFullYear(), today.getMonth(), today.getDate(), time.hour, time.minute);
                time = this.get24hour(d.closedSlot1);
                let closedSlot1 = new Date(today.getFullYear(), today.getMonth(), today.getDate(), time.hour, time.minute);

                if (openSlot1 >= closedSlot1) {
                    isInvalid = true;
                    d.invalidSlot1 = true;
                }

                if (d.openSlot2 && d.closedSlot2) {                    
                    time = this.get24hour(d.openSlot2);
                    let openSlot2 = new Date(today.getFullYear(), today.getMonth(), today.getDate(), time.hour, time.minute);
                    time = this.get24hour(d.closedSlot2);
                    let closedSlot2 = new Date(today.getFullYear(), today.getMonth(), today.getDate(), time.hour, time.minute);
                    
                    if(openSlot2 >= closedSlot2) {
                        isInvalid = true;
                        d.invalidSlot2 = true;
                    }
                    else if ((openSlot2 >= openSlot1 && openSlot2 <= closedSlot1) || (closedSlot2 >= openSlot1 && closedSlot2 <= closedSlot1)) {
                        d.overlapSlot = true;
                        isInvalid = true;
                    }
                    else if((openSlot1 >= openSlot2 && openSlot1 <= closedSlot2) && (closedSlot1 >= openSlot2 && closedSlot1 <= closedSlot2)){
                        d.overlapSlot = true;
                        isInvalid = true;
                    }
                    else if(openSlot1 >= openSlot2){
                        isInvalid = true;                        
                        this.invalidOpenSlotsText='Slot 1 Hours must be before Slot 2 Hours';
                                                    
                    }
                }
            });
        }
        return isInvalid;
    }

    private get24hour(timeStr: any): any {
        const [time, modifier] = timeStr.split(' ');
        let [hours, minutes] = time.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }
        return { hour: Number(hours), minute: Number(minutes) };
    }

    cancelEdits(e: any, form: NgForm) {
        form.resetForm();
        this.notifyParent('cancel', []);
    };

    private notifyParent(type: string, data: WorkHoursObject[]) {
        switch (type) {
            case 'save':
                this.onSaveOfficeHours.next(data);
                break;
            case 'cancel':
                this.onCancelOfficeHours.next("cancel");
                break;
        }
    }

    createSlot(): void {
        let obj = {} as DefaultWorkHoursObject;
        this.myList.push(obj);
    }

    resetFormAndcreateSlot(form: NgForm): void {
        form.resetForm();
        this.createSlot();
    }

    deleteSlot(e: any) {
        if (e !== -1) {
            this.myList.splice(e, 1);
        }
    }

    onDayChecked(event: any, data: any) {
        const value = event.target.value;
        this.myList.forEach((e: { appliesTo: any[]; }) => {
            if (e.appliesTo && e.appliesTo.indexOf(value) > -1) {
                e.appliesTo.splice(e.appliesTo.indexOf(value), 1);
            }
        });
        if (!data.appliesTo) {
            data.appliesTo = [];
        }
        if (event.target.checked) {
            data.appliesTo.push(value);
        }
    }

    timeSlotChanged(slot: any) {
        slot.invalidSlot2 = undefined;
        slot.invalidSlot1 = undefined;
        slot.overlapSlot = undefined;
        this.invalidOpenSlotsText = '';
    }
}
export interface WorkHoursObject {
    WeekDayName: string,
    Slot1OpenHour: string,
    Slot1CloseHour: string
    Slot2OpenHour: string,
    Slot2CloseHour: string
}
export interface DefaultWorkHoursObject {
    openSlot1: string[],
    closedSlot1: string[],
    openSlot2: string[],
    closedSlot2: string[],
    appliesTo: []
}