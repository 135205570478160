<div class="modal-header">
  <div class="align-top">
    <h3 class="modal-title">
      <i class="fa-solid fa-triangle-exclamation fa-lg text-warning"></i>{{'headers.attestation-warning' | translate}}
    </h3>
  </div>
</div>
<div class="modal-body align-middle">
  <div class="card">
    <div class="card-body">
      <div class="form-group">
        <span [innerHTML]="contentText"></span>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
  <button type="submit" [autofocus]="true" class="btn btn-primary" (click)="continue()">Continue
  </button>
</div>